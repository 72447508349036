import Api from '@/services/Api'

export default {
  // todas as marcações funcionario
  index () {
    return Api().get('marcacoesfuncionario')
  },
  // criar marcação funcionario
  post (id) {
    return Api().post('marcacoesfuncionario', id)
  },
  // marcacao funcionario com este id
  show (id) {
    return Api().get(`marcacaofuncionario/${id}`)
  },
  // update marcacao funcionario
  put (id) {
    return Api().put(`marcacaofuncionario/${id.Marcacoes_funcionario_id}`, id)
  },
  // apagar marcacao funcionario
  delete (id) {
    return Api().delete(`marcacaofuncionario/${id}`, id)
  },
  // marcações funcionario por esta data
  datamarcacoesfuncionariotarefas (dataselecionada) {
    return Api().get('datamarcacoesfuncionariotarefas',{ 
      params: {
        dataselecionada
      }
    })
  },
  // marcações funcionario por esta data e este funcionarioid
  userdatamarcacoesfuncionariotarefas (elemprece) {
    return Api().get('userdatamarcacoesfuncionariotarefas',{ 
      params: {
        funcionarioId: elemprece.idfunc,
        dataselecionada: elemprece.dataselc
      }
    })
  },
  // ultima marcacao funcionario
  ultimamarcacaofuncionario () {
    return Api().get('ultimamarcacaofuncionario')
  },
}
