<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Editar Tempo</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-icon v-if="editarTempoAgora.Tempos_Agora_Deslocacao == 1" color="green" class="pr-2">mdi-car</v-icon>
                    <v-icon v-if="editarTempoAgora.Tempos_Agora_Remoto == 1" color="green" class="pr-2">mdi-laptop</v-icon>
                    <v-icon v-if="editarTempoAgora.Tempos_Agora_Telefone == 1" color="green" class="pr-2">mdi-phone</v-icon>
                  </v-col>
                  <v-col cols="12 text-center">
                    <label>Adicionar Tempo a <b>Tarefa</b> do cliente <b>{{ this.cliente.nome }}</b>, Funcionario <b>{{ $store.state.user.Usuario }}</b></label><br>
                  </v-col>
                  <v-col cols="12" v-if="editarTempoAgora.Tempos_Agora_Terminado == 0" class="botoestemposeditar text-center" id="botaostop">
                    <v-icon @click="finalizarTempo">mdi-stop-circle</v-icon>
                  </v-col>
                  <v-col cols="12" v-if="editarTempoAgora.Tempos_Agora_Terminado == 1" class="text-center">
                    <div class="retangulotempofim">
                      <span>{{ formatDate4(editarTempoAgora.Tempos_Agora_Tempo_Efetivo) }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12 text-center">
                    <v-btn v-if="funcionario.funcionario_Deslocacao == 1" class="ma-2" color="blue" dark @click="editarDeslocacaoDesactivar"><v-icon dark>mdi-car</v-icon></v-btn>
                    <v-btn v-else class="ma-2" color="grey" dark @click="editarDeslocacaoActivar" id="icondeslocacao"><v-icon dark>mdi-car</v-icon></v-btn>
                    <v-btn v-if="funcionario.funcionario_Remoto == 1" class="ma-2" color="blue" dark @click="editarRemotoDesactivar"><v-icon dark>mdi-laptop</v-icon></v-btn>
                    <v-btn v-else class="ma-2" color="grey" dark @click="editarRemotoActivar" id="iconremoto"><v-icon dark>mdi-laptop</v-icon></v-btn>
                    <v-btn v-if="funcionario.funcionario_telefone == 1" class="ma-2" color="blue" dark @click="editarTelefoneDesactivar"><v-icon dark>mdi-phone</v-icon></v-btn>      
                    <v-btn v-else class="ma-2" color="grey" dark @click="editarTelefoneActivar" id="icontelefone"><v-icon dark>mdi-phone</v-icon></v-btn>
                  </v-col>
                  <v-col cols="12">
                    <label>Descrição</label>
                    <wysiwyg v-model="editarTempoAgora.Tempos_Agora_Descricao"/>
                  </v-col>
                  <v-col cols="12">
                    <label>Trabalho Realizado</label>
                    <wysiwyg v-model="editarTempoAgora.Tempos_Agora_Trabalho_Realizado"/>
                  </v-col>
                  <v-col cols="12 text-right">
                    <v-btn color="primary" @click="guardarTempo">Guardar</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import FuncionarioService from '@/services/FuncionarioService'
import ClientesService from '@/services/ClientesService'
import TemposAgoraService from '@/services/TemposAgoraService'
import TemposService from '@/services/TemposService'
import store from '@/store/store'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
      this.editarTempoAgora = (await TemposAgoraService.show(this.$store.state.teste.idtempoagora)).data
      this.editarTempo = (await TemposService.show(this.$store.state.teste.idtempo)).data
      this.funcionario = (await FuncionarioService.show(this.$store.state.user.funcionario)).data
      var elemfunc = {nomefunc: this.funcionario.funcionario_nome}
      this.tempoAgoraFuncionario = (await TemposAgoraService.tempoagorafunc(elemfunc)).data
      this.cliente = (await ClientesService.show(this.$store.state.teste.idcliente)).data    
      
  },
  data () {
    return {
      tempoAgoraFuncionario: [],
      funcionario: [],
      cliente: {},
      ultimotempo: [],
      editarTempoAgora: {
        Tempos_Agora_Inicio: '',
        Tempos_Agora_fim: '',
        Tempos_Agora_gasto: '',
        Tempos_Agora_Descricao: '',
        Tempos_Agora_Terminado: 0,
        Tempos_Agora_Trabalho_Realizado: '',
        Tempos_Agora_Telefone: 0,
        Tempos_Agora_Remoto: 0,
        Tempos_Agora_Deslocacao: 0,
        Tempos_Agora_Tempo_Efetivo: null,
        Tempos_Agora_Tempo_Descontar: null,
        Tempos_Agora_Tempo_Desl: null
      },
      editarTempo: {
        Tempos_Inicio: '',
        Tempos_fim: '',
        Tempos_gasto: '',
        Tempos_Descricao: '',
        Tempos_Telefone: 0,
        Tempos_Remoto: 0,
        Tempos_Deslocacao: 0,
        Tempos_Cliente_numero: null,
        Tempos_Efetivo: null,
        Tempos_Descontar: null,
        Tempos_Desl: null
      },
    }
  },
  methods: {
    // formatar data4
    formatDate4 (date) {
      return new Date(date).toJSON().slice(11, 19)
    },
    async guardarTempo () {
      //  tempos agora tempos agora tempos agora tempos agora empos agora tempos agora tempos agora tempos agora empos agora tempos agora
      //  tempos agora tempos agora tempos agora tempos agora empos agora tempos agora tempos agora tempos agora empos agora tempos agora
      //  tempos agora tempos agora tempos agora tempos agora empos agora tempos agora tempos agora tempos agora empos agora tempos agora
      await TemposAgoraService.put(this.editarTempoAgora)
    },


    async finalizarTempo () {
      //  funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario
      //  funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario
      //  funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario
      //  funcionario update
      if (this.tempoAgoraFuncionario.length == 1) {
        this.funcionario.funcionario_estado = 'Livre'
        this.funcionario.funcionario_Deslocacao = 0
        this.funcionario.funcionario_Remoto = 0
        this.funcionario.funcionario_telefone = 0
      }
      await FuncionarioService.put(this.funcionario)
      //  tempos agora tempos agora tempos agora tempos agora empos agora tempos agora tempos agora tempos agora empos agora tempos agora
      //  tempos agora tempos agora tempos agora tempos agora empos agora tempos agora tempos agora tempos agora empos agora tempos agora
      //  tempos agora tempos agora tempos agora tempos agora empos agora tempos agora tempos agora tempos agora empos agora tempos agora
      this.editarTempoAgora.Tempos_Agora_Terminado = 1
      this.editarTempoAgora.Tempos_Agora_fim = new Date(Date.now())
      var temponumericoTAF = new Date(this.editarTempoAgora.Tempos_Agora_fim).getTime()
      var temponumericoTAI = new Date(this.editarTempoAgora.Tempos_Agora_Inicio).getTime()
      var temponumericoTAG = (temponumericoTAF - temponumericoTAI)
      var temponumericoTAGConvertido = new Date(temponumericoTAG).toISOString()
      var temposlice = new Date(temponumericoTAGConvertido).toJSON().slice(11, 19)
      this.editarTempoAgora.Tempos_Agora_gasto = ('1899-12-30T' + temposlice + '.000Z')
      this.editarTempoAgora.Tempos_Agora_Tempo_Efetivo = ('1899-12-30T' + temposlice + '.000Z')
      this.editarTempoAgora.Tempos_Agora_Tempo_Descontar = ('1899-12-30T00:00:00.000Z')
      if (this.editarTempoAgora.Tempos_Agora_Deslocacao == 1) {
        this.editarTempoAgora.Tempos_Agora_Tempo_Desl = ('1899-12-30T01:00:00.000Z')
      } else {
        this.editarTempoAgora.Tempos_Agora_Tempo_Desl = ('1899-12-30T00:00:00.000Z')
      }
      await TemposAgoraService.put(this.editarTempoAgora)
      //  tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos
      //  tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos
      //  tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos
      this.editarTempo.Tempos_Inicio = this.editarTempoAgora.Tempos_Agora_Inicio
      this.editarTempo.Tempos_fim = this.editarTempoAgora.Tempos_Agora_fim
      this.editarTempo.Tempos_gasto = this.editarTempoAgora.Tempos_Agora_gasto
      this.editarTempo.Tempos_Descricao = this.editarTempoAgora.Tempos_Agora_Descricao
      this.editarTempo.Tempos_Telefone = this.editarTempoAgora.Tempos_Agora_Telefone
      this.editarTempo.Tempos_Remoto = this.editarTempoAgora.Tempos_Agora_Remoto
      this.editarTempo.Tempos_Deslocacao = this.editarTempoAgora.Tempos_Agora_Deslocacao
      this.editarTempo.Tempos_Efetivo = this.editarTempoAgora.Tempos_Agora_Tempo_Efetivo
      this.editarTempo.Tempos_Descontar = this.editarTempoAgora.Tempos_Agora_Tempo_Descontar
      this.editarTempo.Tempos_Desl = this.editarTempoAgora.Tempos_Agora_Tempo_Desl
      await TemposService.put(this.editarTempo)
    },


    async editarDeslocacaoActivar () {
      //  update funcionario Deslocação para 1
      this.funcionario.funcionario_Deslocacao = 1
      await FuncionarioService.put(this.funcionario)
      //  update tempos_agora Deslocação para 1
      this.editarTempoAgora.Tempos_Agora_Deslocacao = 1
      await TemposAgoraService.put(this.editarTempoAgora)
    },
    async editarDeslocacaoDesactivar () {
      //  update funcionario Deslocação para 0
      this.funcionario.funcionario_Deslocacao = 0
      await FuncionarioService.put(this.funcionario)
    },


    async editarRemotoActivar () {
      //  update funcionario Remoto para 1
      this.funcionario.funcionario_Remoto = 1
      await FuncionarioService.put(this.funcionario)
      //  update tempos_agora Remoto para 1
      this.editarTempoAgora.Tempos_Agora_Remoto = 1
      await TemposAgoraService.put(this.editarTempoAgora)
    },
    async editarRemotoDesactivar () {
      //  update funcionario Remoto para 0
      this.funcionario.funcionario_Remoto = 0
      await FuncionarioService.put(this.funcionario)
    },


    async editarTelefoneActivar () {
      //  update funcionario Telefone para 1
      this.funcionario.funcionario_telefone = 1
      await FuncionarioService.put(this.funcionario)
      //  update tempos_agora Telefone para 1
      this.editarTempoAgora.Tempos_Agora_Telefone = 1
      await TemposAgoraService.put(this.editarTempoAgora)
    },
    async editarTelefoneDesactivar () {
      //  update funcionario Telefone para 0
      this.funcionario.funcionario_telefone = 0
      await FuncionarioService.put(this.funcionario)
    },
  }
}
</script>
<style>
</style>