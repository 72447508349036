import Api from '@/services/Api'

export default {
  // todas as tarefas
  index () {
    return Api().get('usuarios')
  },
  // usuario com este id funcionario
  usuariofunc (id) {
    return Api().get(`usuariofunc/${id}`)
  }
}
