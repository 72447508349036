import Api from '@/services/Api'

export default {
  // todos os logss
  index () {
    return Api().get('log')
  },
  // criar log
  post (elementos) {
    return Api().post('log', elementos)
  }
}
