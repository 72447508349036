<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn">
        <v-flex>
            <v-card>
                <v-card>
                    <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title>Criar Projetos</v-toolbar-title>
                    </v-toolbar>
                </v-card>
                <v-card-text>
                    <v-form>
                        <v-container>
                            <v-row>
                            <v-col cols="12">
                                <label>Clientes</label>
                                <v-autocomplete v-model="createProjetos.Projetos_cliente" :items="clientes" item-text="nome" item-value="no" clearable filled outlined></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <label>Data de Registo</label>
                                <MYdatetime type="datetime" v-model="createProjetos.Projetos_data_registo" disabled="disabled"></MYdatetime>
                            </v-col>
                            <v-col cols="12">
                                <label>Quem Registou</label>
                                <v-text-field v-model="createProjetos.Projetos_quem_registou" disabled filled outlined></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <label>Descrição</label>
                                <wysiwyg v-model="createProjetos.Projetos_descricao"/>
                            </v-col>
                            <v-col cols="12">
                                <label>Data de Inicio</label>
                                <MYdatetime type="date" v-model="createProjetos.Projetos_data_inicio"></MYdatetime>
                            </v-col>
                            <v-col cols="12">
                                <label>Data de fim</label>
                                <MYdatetime type="date" v-model="createProjetos.Projetos_data_fim"></MYdatetime>
                            </v-col>
                            <v-col cols="12">
                                <label>Tópico</label>
                                <v-select v-model="createProjetos.Projetos_Topico" :items="['Atualização aplicação', 'Atualização contrato', 'Em desenvolvimento', 'Formação', 'Instalação', 'Orçamentos']" filled outlined></v-select>
                            </v-col>
                            <v-col cols="12">
                                <label>Estado</label>
                                <v-select v-model="createProjetos.Projetos_estado" :items="estados" item-text="Estado_descricao" item-value="Estado_id" filled outlined></v-select>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <label>Intervenientes</label>
                                <v-icon large color="black" @click="intervModal">mdi-plus</v-icon>
                            </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <!-- Dialog Adicionar Interveniente -->
                <v-dialog v-model="dialogAdicionarInterveniente">
                    <v-card>
                    <v-toolbar class="mb-2 primary" dark flat>
                        <v-toolbar-title>Adicionar Interveniente</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogAdicionarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row>
                        <v-col cols="12">
                            <v-list>
                            <v-list-item-group color="primary">
                                <div v-for="func in funcionarios" :key="func.funcionario_id">
                                <v-divider></v-divider>
                                <v-list-item>
                                    <template>
                                    <v-list-item-action>
                                        <v-checkbox v-model="checkedInterv" :value="func"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{func.funcionario_nome}}</v-list-item-title>
                                    </v-list-item-content>
                                    </template>
                                </v-list-item>
                                </div>
                            </v-list-item-group>
                            </v-list>
                        </v-col>
                        <v-col cols="12">
                            <v-alert v-model="alertDataFimMaior" dismissible type="error">Data de Fim menor que a de Inicio</v-alert>
                            <v-alert v-model="alertCliente" dismissible type="error">Cliente não selecionado</v-alert>
                            <v-alert v-model="alertCriada" dismissible type="success">Projeto criado com sucesso</v-alert>
                        </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="CriarProjeto({name: 'editarprojetos'})">Guardar</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
//  Importar o service necessário
import ProjetosService from '@/services/ProjetosService'
import ClientesService from '@/services/ClientesService'
import EstadosService from '@/services/EstadosService'
import FuncionarioService from '@/services/FuncionarioService'
import ProjetosFuncionarioService from '@/services/ProjetosFuncionarioService'
import LogService from '@/services/LogService'
import store from '@/store/store'
export default {
    async mounted () {
        if (!store.state.isUserLoggedIn) {
            this.$router.push({
                name: 'mylogin'
            })
        }
        this.clientes = (await ClientesService.index()).data
        this.estados = (await EstadosService.index()).data
        this.funcionarios = (await FuncionarioService.index()).data
        this.createProjetos.Projetos_data_inicio = null
        this.createProjetos.Projetos_data_fim = null
    },
    data () {
        return {
            createLog: {
                Log_Tabela: null,
                Log_Tipo: null,
                Log_Numero: null,
                Log_Data: new Date(Date.now()).toISOString(),
                Log_Funcionario: this.$store.state.user.funcionario,
                Log_Descricao: null,
            },
            createProjetos: {
                Projetos_cliente: null,
                Projetos_data_registo: new Date(Date.now()).toISOString(),
                Projetos_quem_registou: this.$store.state.user.Usuario,
                Projetos_descricao: '',
                Projetos_concluida: 0,
                Projetos_Topico: null,
                Projetos_estado: 1,
                Projetos_ano: null,
                Projetos_numero: null
            },
            createProjetosFuncionario: {
                Projetos_funcionario_Projeto: null,
                Projetos_funcionario_funcionario: null,
                Projetos_funcionario_atribuido_data: new Date(Date.now()),
                Projetos_funcionario_atribuido_por: this.$store.state.user.funcionario,
            },
            clientes: [],
            estados: [],
            funcionarios: [],
            checkedInterv: [],
            alertCriada: false,
            alertDataFimMaior: false,
            alertCliente: false,
            dialogAdicionarInterveniente: false
        }
    },
    methods: {
        //abrir modal adicionar interveniente
        async intervModal () {
            this.dialogAdicionarInterveniente = true
        },
        async CriarProjeto (route) {
            // se o cliente estiver vazio
            if (this.createProjetos.Projetos_cliente == null) {
                this.alertCliente = true
            // se ambas as datas tiverem preenchidas vai verificar se a data de fim é maior que a data de inicio
            } else if (this.createProjetos.Projetos_data_inicio != null && this.createProjetos.Projetos_data_fim != null) {
                const datainiciopc = this.createProjetos.Projetos_data_inicio
                const datafimpc = this.createProjetos.Projetos_data_fim
                const datainiciocc = new Date(datainiciopc)
                const datafimcc = new Date(datafimpc)
                if (datainiciocc.getTime() > datafimcc.getTime()) {
                    this.alertDataFimMaior = true
                } else {
                this.createProjetos.Projetos_quem_registou = this.$store.state.user.funcionario
                //ir bucar o projeto com Projetos_numero maior do ano que estamos
                this.maiornumeroprojetoano = (await ProjetosService.maiornumeroprojetoano()).data
                if (this.maiornumeroprojetoano.Projetos_numero != undefined) {
                    this.createProjetos.Projetos_numero = this.maiornumeroprojetoano.Projetos_numero + 1;
                } else {
                    this.createProjetos.Projetos_numero = 100
                }
                this.createProjetos.Projetos_ano = new Date().getFullYear();
                await ProjetosService.post(this.createProjetos)
                //ir bucar o ultimo projeto adicionado
                this.ultimoprojeto = (await ProjetosService.ultimoprojeto()).data
                //adicionar log
                this.createLog.Log_Tabela = 'Projetos',
                this.createLog.Log_Tipo = 'Adicionar',
                this.createLog.Log_Numero = this.ultimoprojeto.Projetos_id
                this.createLog.Log_Descricao = 'Projeto adicionado por ' + this.$store.state.user.Usuario
                await LogService.post(this.createLog)
                //adicionar projeto funcionario
                this.createProjetosFuncionario.Projetos_funcionario_Projeto = this.ultimoprojeto.Projetos_id
                for (var i = 0; i < this.checkedInterv.length; i++) {
                    this.createProjetosFuncionario.Projetos_funcionario_funcionario = this.checkedInterv[i].funcionario_id
                    await ProjetosFuncionarioService.post(this.createProjetosFuncionario)
                    //adicionar log
                    this.createLog.Log_Tabela = 'Projetos',
                    this.createLog.Log_Tipo = 'Adicionar',
                    this.createLog.Log_Numero = this.ultimoprojeto.Projetos_id
                    this.createLog.Log_Descricao = 'Funcionário ' + this.checkedInterv[i].funcionario_nome + ' adicionado por ' + this.$store.state.user.Usuario
                    await LogService.post(this.createLog)
                }
                this.alertCriada = true
                this.$store.dispatch('setProjeto', {
                    idprojeto: this.ultimoprojeto.Projetos_id
                })
                this.$router.push(route)
                }
            } else {
                this.createProjetos.Projetos_quem_registou = this.$store.state.user.funcionario
                //ir bucar o projeto com Projetos_numero maior do ano que estamos
                this.maiornumeroprojetoano = (await ProjetosService.maiornumeroprojetoano()).data
                if (this.maiornumeroprojetoano.Projetos_numero != undefined) {
                    this.createProjetos.Projetos_numero = this.maiornumeroprojetoano.Projetos_numero + 1;
                } else {
                    this.createProjetos.Projetos_numero = 100
                }
                this.createProjetos.Projetos_ano = new Date().getFullYear();
                await ProjetosService.post(this.createProjetos)
                //ir bucar o ultimo projeto adicionado
                this.ultimoprojeto = (await ProjetosService.ultimoprojeto()).data
                //adicionar log
                this.createLog.Log_Tabela = 'Projetos',
                this.createLog.Log_Tipo = 'Adicionar',
                this.createLog.Log_Numero = this.ultimoprojeto.Projetos_id
                this.createLog.Log_Descricao = 'Projeto adicionado por ' + this.$store.state.user.Usuario
                await LogService.post(this.createLog)
                //adicionar projeto funcionario
                this.createProjetosFuncionario.Projetos_funcionario_Projeto = this.ultimoprojeto.Projetos_id
                for (var ii = 0; ii < this.checkedInterv.length; ii++) {
                    this.createProjetosFuncionario.Projetos_funcionario_funcionario = this.checkedInterv[ii].funcionario_id
                    await ProjetosFuncionarioService.post(this.createProjetosFuncionario)
                    //adicionar log
                    this.createLog.Log_Tabela = 'Projetos',
                    this.createLog.Log_Tipo = 'Adicionar',
                    this.createLog.Log_Numero = this.ultimoprojeto.Projetos_id
                    this.createLog.Log_Descricao = 'Funcionário ' + this.checkedInterv[ii].funcionario_nome + ' adicionado por ' + this.$store.state.user.Usuario
                    await LogService.post(this.createLog)
                }
                this.alertCriada = true
                this.$store.dispatch('setProjeto', {
                    idprojeto: this.ultimoprojeto.Projetos_id
                })
                this.$router.push(route)
            }
        },
    }
}
</script>