<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Criar Marcações</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <label>Clientes</label>
                    <v-autocomplete v-model="createMarcacoes.Marcacoes_cliente" :items="clientes" item-text="nome" item-value="no" clearable filled outlined></v-autocomplete>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Quem Registou</label>
                    <v-text-field v-model="createMarcacoes.Marcacoes_quem_registou" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Data de Registo</label>
                    <MYdatetime type="datetime" v-model="createMarcacoes.Marcacoes_data_registo" disabled="disabled"></MYdatetime>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Quem Pediu</label>
                    <v-text-field type="text" v-model="createMarcacoes.Marcacoes_Quem_Pediu" clearable filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Pedido Como</label>
                    <v-select v-model="createMarcacoes.Marcacoes_Como_Pediu" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']" filled outlined></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8">
                    <v-row>
                      <v-col cols="12">
                        <label>Descrição</label>
                        <wysiwyg v-model="createMarcacoes.Marcacoes_descricao"/>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Localização</label>
                        <v-text-field type="text" v-model="createMarcacoes.Marcacoes_Localizacao" clearable filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Tópico</label>
                        <v-select v-model="createMarcacoes.Marcacoes_Topico" :items="['Atualização aplicação', 'Atualização contrato', 'Em desenvolvimento', 'Formação', 'Instalação', 'Orçamentos']" filled outlined></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Estado</label>
                        <v-text-field type="text" v-model="createMarcacoes.Marcacoes_estado" clearable filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <span @click="datafimigual">
                          <label>Data de Inicio</label>
                          <MYdatetime type="date" v-model="createMarcacoes.Marcacoes_data_inicio"></MYdatetime>
                        </span>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Data de fim</label>
                        <MYdatetime type="date" v-model="createMarcacoes.Marcacoes_data_fim" disabled="disabled"></MYdatetime>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <label>Intervenientes</label>
                        <v-icon large color="black" @click="intervModal">mdi-plus</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
            </v-form>
          </v-card-text>
          <!-- Dialog Adicionar Interveniente -->
          <v-dialog v-model="dialogAdicionarInterveniente">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Adicionar Interveniente</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <span @click="gettareffuncdata">
                      <label>Data Início</label>
                      <MYdatetime type="date" v-model="createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio"></MYdatetime>
                    </span>
                  </v-col> 
                  <v-col cols="6">
                    <label>Hora Início</label>
                    <MYdatetime type="time" v-model="createMarcacoesFuncionario.Marcacoes_funcionario_hora_inicio" :hour-step="1" :minute-step="15" :min-datetime="'08'" :max-datetime="'20'"></MYdatetime>
                  </v-col>
                  <v-col cols="6">
                    <label>Data Fim</label>
                    <MYdatetime type="date" v-model="createMarcacoesFuncionario.Marcacoes_funcionario_data_fim" disabled="disabled"></MYdatetime>
                  </v-col>
                  <v-col cols="6">
                    <label>Hora Fim</label>
                    <MYdatetime type="time" v-model="createMarcacoesFuncionario.Marcacoes_funcionario_hora_fim" :hour-step="1" :minute-step="15" :min-datetime="'08'" :max-datetime="'20'"></MYdatetime>
                  </v-col>
                  <v-col cols="12">
                    <v-list>
                      <v-list-item-group color="primary">
                        <div v-for="func in funcionarios" :key="func.funcionario_id">
                          <v-divider></v-divider>
                          <v-list-item>
                            <template>
                              <v-list-item-action>
                                <v-checkbox v-model="checkedInterv" :value="func"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{func.funcionario_nome}}</v-list-item-title>
                              </v-list-item-content>
                              <div v-for="num in numerosSemRepeticao" :key="num">
                                <v-list-item-content v-if="func.funcionario_id == num">
                                  <v-list-item-title><v-btn icon><v-icon color="red" @click="marcacoesDiaModal(func)">mdi-eye</v-icon></v-btn></v-list-item-title>
                                </v-list-item-content>
                              </div>
                            </template>
                          </v-list-item>
                        </div>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                  <v-col cols="12">
                    <v-alert v-model="alertCliente" dismissible type="error">Cliente não selecionado</v-alert>
                    <v-alert v-model="alertIntervenienteSelecionado" dismissible type="error">Interveniente não selecionado</v-alert>
                    <v-alert v-model="alertHoraInicioMaior" dismissible type="error">A hora de inicio é maior que a de fim</v-alert>
                    <v-alert v-model="alertCriada" dismissible type="success">Marcação criada com sucesso</v-alert>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="CriarMarcacao({name: 'editarmarcacoes'})">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog marcações do dia -->
          <v-dialog v-model="dialogMarcacoesDia">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Marcações do dia selecionado</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogMarcacoesDia = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row>
                  <v-col cols="12" v-for="marctarefas in userdatamarcacoesfuncionariotarefas" :key="marctarefas.Marcacoes_funcionario_id">
                    <div class="myTabela">
                      <div>
                        <span>Marcação das tarefas</span>
                      </div>
                      <div>
                        <span class="titulo">Funcionario</span><br>
                        <span>{{ marctarefas.Funcionario.funcionario_nome }}</span>
                      </div>
                      <div v-for="cln in clientesn" :key="cln.no">
                        <span class="titulo">Cliente</span><br>
                        <span v-if="cln.no == marctarefas.Marcaco.Marcacoes_cliente">{{ cln.nome }}</span>
                      </div>
                      <div>
                        <span class="titulo">Marcação</span><br>
                        <span v-html="marctarefas.Marcaco.Marcacoes_descricao.substring(0,60)"></span>
                      </div>
                      <div>
                        <span class="titulo">Início</span><br>
                        <span>{{ formatDate2(marctarefas.Marcacoes_funcionario_hora_inicio) }}</span>
                      </div>
                      <div>
                        <span class="titulo">Fim</span><br>
                        <span>{{ formatDate2(marctarefas.Marcacoes_funcionario_hora_fim) }}</span>
                      </div>
                    </div>
                  </v-col>  
                  <v-col cols="12" v-for="marcphc in userdatamarcacoesfuncionariophc" :key="marcphc.mxid">
                    <div class="myTabela">
                      <div>
                        <span>Marcação do phc</span>
                      </div>
                      <div>
                        <span class="titulo">Funcionario</span><br>
                        <span>{{ marcphc.tecnnm }}</span>
                      </div>
                      <div>
                        <span class="titulo">Cliente</span><br>
                        <span>{{ marcphc.clnome }}</span>
                      </div>
                      <div>
                        <span class="titulo">Marcação</span><br>
                        <span v-html="marcphc.texto.substring(0,60)"></span>
                      </div>
                      <div>
                        <span class="titulo">Início</span><br>
                        <span>{{ marcphc.hinicio }}</span>
                      </div>
                      <div>
                        <span class="titulo">Fim</span><br>
                        <span>{{ marcphc.hfim }}</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import MarcacoesFuncionarioService from '@/services/MarcacoesFuncionarioService'
import MarcacoesService from '@/services/MarcacoesService'
import ClientesService from '@/services/ClientesService'
import FuncionarioService from '@/services/FuncionarioService'
import TblUsuariosService from '@/services/TblUsuariosService'
import UtilizadoresPhcService from '@/services/UtilizadoresPhcService'
import MarcacoesPhcService from '@/services/MarcacoesPhcService'
import TarefasService from '@/services/TarefasService'
import LogService from '@/services/LogService'
import store from '@/store/store'
function addZero (i) {
  if (i < 10) { i = '0' + i }
  return i
}
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.clientes = (await ClientesService.index()).data
    this.funcionarios = (await FuncionarioService.index()).data
    if (this.$store.state.teste.idtarefamarcacao != undefined) {
      this.tarefamarcacao = (await TarefasService.show(this.$store.state.teste.idtarefamarcacao)).data
      this.createMarcacoes.Marcacoes_cliente = this.tarefamarcacao.tarefa_cliente,
      this.createMarcacoes.Marcacoes_data_registo = new Date(Date.now()).toISOString(),
      this.createMarcacoes.Marcacoes_quem_registou = this.$store.state.user.Usuario,
      this.createMarcacoes.Marcacoes_descricao = this.tarefamarcacao.tarefa_descricao,
      this.createMarcacoes.Marcacoes_Topico = this.tarefamarcacao.tarefa_Topico,
      this.createMarcacoes.Marcacoes_concluida = this.tarefamarcacao.tarefa_concluida,
      this.createMarcacoes.Marcacoes_tarefa = this.$store.state.teste.idtarefamarcacao
      this.createMarcacoes.Marcacoes_Quem_Pediu = this.tarefamarcacao.Tarefa_Quem_Pediu,
      this.createMarcacoes.Marcacoes_Como_Pediu = this.tarefamarcacao.Tarefa_Como_Pediu
      this.$store.dispatch('setTeste', {
        idtarefamarcacao: undefined
      })
    }
    this.createMarcacoes.Marcacoes_data_inicio = null
  },
  data () {
    return {
      clientesid: [],
      clientesn: [],
      tarefamarcacao: [],
      createLog: {
        Log_Tabela: null,
        Log_Tipo: null,
        Log_Numero: null,
        Log_Data: new Date(Date.now()).toISOString(),
        Log_Funcionario: this.$store.state.user.funcionario,
        Log_Descricao: null,
      },
      createMarcacoes: {
        Marcacoes_cliente: null,
        Marcacoes_data_registo: new Date(Date.now()).toISOString(),
        Marcacoes_quem_registou: this.$store.state.user.Usuario,
        Marcacoes_descricao: '',
        Marcacoes_data_inicio: null,
        Marcacoes_data_fim: null,
        Marcacoes_Topico: null,
        Marcacoes_estado: null,
        Marcacoes_concluida: 0,
        Marcacoes_Localizacao: null,
        Marcacoes_Quem_Pediu: null,
        Marcacoes_Como_Pediu: null
      },
      createMarcacoesFuncionario: {
        Marcacoes_funcionario_funcionario: null,
        Marcacoes_funcionario_tarefa: null,
        Marcacoes_funcionario_data_inicio: new Date(Date.now()).toISOString(),
        Marcacoes_funcionario_data_fim: new Date(Date.now()).toISOString(),
        Marcacoes_funcionario_hora_inicio: '09',
        Marcacoes_funcionario_hora_fim: '11',
        Marcacoes_funcionario_data_inicio_total: null,
        Marcacoes_funcionario_data_fim_total: null,
        Marcacoes_PHC: null,
        Marcacoes_funcionario_atribuido_data: new Date(Date.now()),
        Marcacoes_funcionario_atribuido_por: this.$store.state.user.funcionario,
      },
      createMarcacoesPhc: {
        mxstamp: null,
        data: null,
        hinicio: null,
        clnome: null,
        inicio: null,
        fim: null,
        hfim: null,
        texto: null,
        nkeyid: null,
        ckeyid: 'EXT',
        cor: 16711680,
        coddiv: 0,
        div: null,
        tecnico: null,
        clno: null,
        vendedor: null,
        tecnnm: null,
        username: null,
        userno: null,
        clestab: 0,
        morada: null,
        local: null,
        codpost: null,
        origem: 'CL',
        ind: 0,
        vendnm: null,   
        eorigem: 'InfordioTarefas',
        tusername: '',
        tuserno: 0,
        vusername: '',
        vuserno: 0,
        ousrinis: 'EXT',
        ousrhora: null,
        usrinis: 'EXT',
        usrhora: null,
        marcada: 0,
        iscliente: 0,
        descorigem: '',
        userinis: '',
        apriv: 0,
        gcaleventid: '',
        mseriesregstamp: '',
        u_telefone: null,
        agaberta: 0,
        agocupad: 0
      },
      clientes: [],
      funcionarios: [],
      checkedInterv: [],
      datafuncselecionadatarefas: [],
      datafuncselecionadaphc: [],
      numerosSemRepeticao: [],
      usuario: [],
      usuariophc: [],
      utilizadorphc: [],
      ultimaMarcacaoFuncionario: {
        Marcacoes_PHC: null
      },
      ultimaMarcacaoPhc: [],
      marcacaophcmaiornkeyid: {},
      marcacaophcmaiormxid: {},
      alertCriada: false,
      alertCliente: false,
      alertIntervenienteSelecionado: false,
      alertHoraInicioMaior: false,
      dialogAdicionarInterveniente: false,
      dialogMarcacoesDia: false,
      userdatamarcacoesfuncionariotarefas: [],
      userdatamarcacoesfuncionariophc: [],
      usuarios: [],
      cliente: {}
    }
  },
  methods: {
    // formatar data2
    formatDate2 (date) {
      const options = {hour: 'numeric', minute: 'numeric'}
      return new Date(date).toLocaleTimeString('pt-PT', options)
    },
    //se colocarmos data na marcacao inicio a de fim fica igual
    async datafimigual () {
      this.createMarcacoes.Marcacoes_data_fim = this.createMarcacoes.Marcacoes_data_inicio
    },
    //abrir modal adicionar interveniente
    async intervModal () {
      this.createMarcacoes.Marcacoes_data_fim = this.createMarcacoes.Marcacoes_data_inicio
      var data = this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio
      var sodata = new Date(data).toJSON().slice(0, 10)
      var datatempotarefas = (sodata+'T00:00:00.000Z')
      var datatempophc = (sodata+' 00:00:00.000')
      if (this.createMarcacoes.Marcacoes_data_inicio != null) {
        this.datafuncselecionadatarefas = (await MarcacoesFuncionarioService.datamarcacoesfuncionariotarefas(this.createMarcacoes.Marcacoes_data_inicio)).data
        this.datafuncselecionadaphc = (await MarcacoesPhcService.datamarcacoesfuncionariophc(this.createMarcacoes.Marcacoes_data_inicio)).data
        this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio = this.createMarcacoes.Marcacoes_data_inicio
        this.createMarcacoesFuncionario.Marcacoes_funcionario_data_fim = this.createMarcacoes.Marcacoes_data_inicio
      }else {
        this.datafuncselecionadatarefas = (await MarcacoesFuncionarioService.datamarcacoesfuncionariotarefas(datatempotarefas)).data
        this.datafuncselecionadaphc = (await MarcacoesPhcService.datamarcacoesfuncionariophc(datatempophc)).data
      }
      var ola = []
      this.usuarios = (await TblUsuariosService.index()).data
      for (var oo = 0; oo < this.datafuncselecionadatarefas.length; oo++) { 
        ola.push(this.datafuncselecionadatarefas[oo].Marcacoes_funcionario_funcionario)
      }
      for (var of = 0; of < this.datafuncselecionadaphc.length; of++) { 
        for (var off = 0; off < this.usuarios.length; off++) {
          if (this.usuarios[off].tecnico_PHC == this.datafuncselecionadaphc[of].tecnico) {
            ola.push(this.usuarios[off].funcionario)
          }
        }
      } 
      this.numerosSemRepeticao = [...new Set(ola)]
      this.dialogAdicionarInterveniente = true
    },
    //se selecionarmos uma data inicio faz isto
    async gettareffuncdata () {
      this.datafuncselecionadatarefas = (await MarcacoesFuncionarioService.datamarcacoesfuncionariotarefas(this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio)).data
      this.datafuncselecionadaphc = (await MarcacoesPhcService.datamarcacoesfuncionariophc(this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio)).data
      this.createMarcacoesFuncionario.Marcacoes_funcionario_data_fim = this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio
      var ola = []
      this.usuarios = (await TblUsuariosService.index()).data
      for (var o = 0; o < this.datafuncselecionadatarefas.length; o++) { 
        ola.push(this.datafuncselecionadatarefas[o].Marcacoes_funcionario_funcionario)
      }
      for (var offf = 0; offf < this.datafuncselecionadaphc.length; offf++) { 
        for (var offp = 0; offp < this.usuarios.length; offp++) { 
          if (this.usuarios[offp].tecnico_PHC == this.datafuncselecionadaphc[offf].tecnico) {
            ola.push(this.usuarios[offp].funcionario)
          }
        }
      }
      this.numerosSemRepeticao = [...new Set(ola)]
    },
    //criar marcação
    async CriarMarcacao (route) {
      var datainicioparse = Date.parse(this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_inicio)
      var datafimparse = Date.parse(this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_fim)
      // se o cliente estiver vazio
      if (this.createMarcacoes.Marcacoes_cliente == null) {
        this.alertCliente = true
      // se não houver intervenientes selecionados
      } else if (this.checkedInterv == '') {
        this.alertIntervenienteSelecionado = true
      // se a data de inicio for maior que a de fim
      } else if (datainicioparse > datafimparse) {
        this.alertHoraInicioMaior = true
      } else {
        //criação marcação //
        //criação marcação //
        //criação marcação //
        this.createMarcacoes.Marcacoes_quem_registou = this.$store.state.user.funcionario
        await MarcacoesService.post(this.createMarcacoes)
        // ultima marcação id
        this.ultimamarcacao = (await MarcacoesService.ultimamarcacao()).data
        //adicionar log
        this.createLog.Log_Tabela = 'Marcacoes',
        this.createLog.Log_Tipo = 'Adicionar',
        this.createLog.Log_Numero = this.ultimamarcacao.Marcacoes_id
        this.createLog.Log_Descricao = 'Marcação adicionada por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
        // criação marcação funcionario //
        // criação marcação funcionario //
        // criação marcação funcionario //
        // preencher data fim e data inicio total
        var datainicio = this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio
        var tempoinicio = this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_inicio
        var sodatainicio = new Date(datainicio).toJSON().slice(0, 10)
        var sotempoinicio = new Date(tempoinicio).toJSON().slice(11, 23)
        var datatempoinicio = (sodatainicio+'T'+sotempoinicio+'Z')
        //-----//
        var datafim = this.createMarcacoesFuncionario.Marcacoes_funcionario_data_fim
        var tempofim = this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_fim
        var sodatafim = new Date(datafim).toJSON().slice(0, 10)
        var sotempofim = new Date(tempofim).toJSON().slice(11, 23)
        var datatempofim = (sodatafim+'T'+sotempofim+'Z')

        // datainicio total
        this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio_total = datatempoinicio
        // datafim total
        this.createMarcacoesFuncionario.Marcacoes_funcionario_data_fim_total = datatempofim
        //marcação id
        this.createMarcacoesFuncionario.Marcacoes_funcionario_tarefa = this.ultimamarcacao.Marcacoes_id
        
        // criação marcação no phc //
        // criação marcação no phc //
        // criação marcação no phc //
        // data
        var datafunini = this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio
        var sodatafimm = new Date(datafunini).toJSON().slice(0, 10)
        this.createMarcacoesPhc.data = sodatafimm + ' ' + '00:00:00.000'
        // hinicio
        var horahinicio = this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_inicio
        var horainicio = new Date(horahinicio).toJSON().slice(11, 16)
        this.createMarcacoesPhc.hinicio = horainicio
        // hfim
        var horahfim = this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_fim
        var horafim = new Date(horahfim).toJSON().slice(11, 16)
        this.createMarcacoesPhc.hfim = horafim
        // inicio
        var sohorainicio = new Date(horahinicio).toJSON().slice(11, 13)
        var sominutosinicio = new Date(horahinicio).toJSON().slice(14, 16)
        this.createMarcacoesPhc.inicio = (sohorainicio * 60) + (sominutosinicio - 0)
        // fim
        var sohorafim = new Date(horahfim).toJSON().slice(11, 13)
        var sominutosfim = new Date(horahfim).toJSON().slice(14, 16)
        this.createMarcacoesPhc.fim = (sohorafim * 60) + (sominutosfim - 0)
        // no-clnome-texto-morada-local-codpost-vendnm
        this.cliente = (await ClientesService.show(this.createMarcacoes.Marcacoes_cliente)).data
        this.createMarcacoesPhc.clno = this.cliente.no
        this.createMarcacoesPhc.clnome = this.cliente.nome
        this.createMarcacoesPhc.texto = this.cliente.nome + ' - ' + this.createMarcacoes.Marcacoes_descricao
        this.createMarcacoesPhc.morada = this.cliente.morada
        this.createMarcacoesPhc.local = this.cliente.local
        this.createMarcacoesPhc.codpost = this.cliente.codpost
        this.createMarcacoesPhc.u_telefone = this.cliente.telefone
        // usrhora-ousrhora
        var usrousr = new Date().toJSON().slice(11, 19)
        this.createMarcacoesPhc.usrhora = usrousr
        this.createMarcacoesPhc.ousrhora = usrousr
        for (var t = 0; t < this.checkedInterv.length; t++) {
          // marcacoesfuncionario Marcacoes_funcionario_funcionario
          this.createMarcacoesFuncionario.Marcacoes_funcionario_funcionario = this.checkedInterv[t].funcionario_id
          // mxstamp
          var datehoje = new Date()
          var dataext = 'EXT' + addZero(datehoje.getDate()) +''+ addZero((datehoje.getMonth() + 1)) +''+ datehoje.getFullYear() +''+ addZero(datehoje.getHours()) +''+ addZero(datehoje.getMinutes()) +''+ addZero(datehoje.getSeconds())
          this.createMarcacoesPhc.mxstamp = dataext + this.checkedInterv[t].funcionario_id
          // nkeyid
          //ir buscar o nkeyid maior
          this.marcacaophcmaiornkeyid = (await MarcacoesPhcService.marcacaophcmaiornkeyid()).data
          this.createMarcacoesPhc.nkeyid = this.marcacaophcmaiornkeyid.nkeyid + 1
          // ckeyid
          //ir buscar o mxid maior
          this.marcacaophcmaiormxid = (await MarcacoesPhcService.marcacaophcmaiormxid()).data
          this.createMarcacoesPhc.ckeyid = ('EXT' + (this.marcacaophcmaiormxid.mxid + 1))
          this.usuario = (await TblUsuariosService.usuariofunc(this.checkedInterv[t].funcionario_id)).data
          this.utilizadorphc = (await UtilizadoresPhcService.usuariophc(this.usuario.tecnico_PHC)).data
          // vendnm-div-tecnico-vendedor-tecnnm-username-userno
          this.createMarcacoesPhc.vendnm = this.utilizadorphc.vendedor + '-' + this.utilizadorphc.iniciais
          this.createMarcacoesPhc.div = this.utilizadorphc.dpt
          this.createMarcacoesPhc.tecnico = this.utilizadorphc.tecnico
          this.createMarcacoesPhc.vendedor = this.utilizadorphc.vendedor
          this.createMarcacoesPhc.tecnnm = this.utilizadorphc.tecnnm
          this.createMarcacoesPhc.username = this.utilizadorphc.username
          this.createMarcacoesPhc.userno = this.utilizadorphc.userno
          await MarcacoesFuncionarioService.post(this.createMarcacoesFuncionario)
          await MarcacoesPhcService.post(this.createMarcacoesPhc)
          // ir bucar a ultima marcacao phc e ultima marcacao funcionario
          this.ultimaMarcacaoPhc = (await MarcacoesPhcService.ultimamarcacaophc()).data
          this.ultimaMarcacaoFuncionario = (await MarcacoesFuncionarioService.ultimamarcacaofuncionario()).data
          // marcacoes funcionario marcacao_phc
          this.ultimaMarcacaoFuncionario.Marcacoes_PHC = (this.ultimaMarcacaoPhc.mxid)
          await MarcacoesFuncionarioService.put(this.ultimaMarcacaoFuncionario)
          //adicionar log
          this.createLog.Log_Tabela = 'Marcacoes',
          this.createLog.Log_Tipo = 'Adicionar',
          this.createLog.Log_Numero = this.ultimamarcacao.Marcacoes_id
          this.createLog.Log_Descricao = 'Funcionário ' + this.checkedInterv[t].funcionario_nome + ' adicionado por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)
        }
        this.alertCriada = true
        this.$store.dispatch('setMarcacao', {
          idmarcacao: this.ultimamarcacao.Marcacoes_id
        })
        this.$router.push(route)
      }
    },
    //abrir modal marcações do dia
    async marcacoesDiaModal (func) {
      var elemprece = {idfunc: func.funcionario_id, dataselc: this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio}
      this.userdatamarcacoesfuncionariotarefas = (await MarcacoesFuncionarioService.userdatamarcacoesfuncionariotarefas(elemprece)).data
      this.usuarios = (await TblUsuariosService.index()).data
      for (var off = 0; off < this.usuarios.length; off++) { 
        if (this.usuarios[off].funcionario == func.funcionario_id) {
          var tecnicophcselecionado = this.usuarios[off].tecnico_PHC
        }
      }
      var elemprecephc = {tecnicophcslec: tecnicophcselecionado, dataselc: this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio}
      this.userdatamarcacoesfuncionariophc = (await MarcacoesPhcService.userdatamarcacoesfuncionariophc(elemprecephc)).data
      //nomes clientes
      var ola = []
      //nome clientes marcações
      for (var m = 0; m < this.userdatamarcacoesfuncionariotarefas.length; m++) {
        ola.push(this.userdatamarcacoesfuncionariotarefas[m].Marcaco.Marcacoes_cliente)
      }
      this.clientesid = [...new Set(ola)]
      this.clientesn = (await ClientesService.clientesids(this.clientesid)).data
      this.dialogMarcacoesDia = true
    }
  }
}
</script>
<style>
.vdatetime-time-picker__item--disabled {
  display: none;
}
</style>