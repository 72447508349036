<template>
    <v-form ref="form" v-model="valid">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" >
            <v-text-field :value="ticket.name" counter maxlength="100" label="Nome" readonly required 
              :rules="[rules.required]"></v-text-field>
          </v-col>
  
        </v-row>
        <v-row justify="center">
          <v-col cols="12" >
            <v-text-field :value="ticket.company" counter maxlength="100" label="Empresa" required readonly
              :rules="[rules.required]"></v-text-field>
          </v-col>
        </v-row>
  
        <v-row justify="center">
          <v-col cols="12" >
            <v-text-field :value="ticket.nif" label="NIF" counter maxlength="100" required readonly
              :rules="[rules.required]"></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" >
            <v-text-field :value="ticket.email" counter maxlength="100" label="Email" required readonly
              :rules="[rules.required]"></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" >
            <v-text-field :value="ticket.contact" counter maxlength="100" label="Contacto" required readonly
              :rules="[rules.required]"></v-text-field>
          </v-col>
        </v-row>
        
        <v-row justify="center">
          <v-col cols="12" >
            <v-textarea  :value="ticket.description" counter maxlength="500" label="Descrição do Problema" readonly
              :rules="[rules.required]"></v-textarea>
          </v-col>
  
        </v-row>

        <v-row justify="center">
          <v-col cols="12" >
            <v-text-field :value="ticket.preferedEmployee" counter maxlength="100" label="Técnico preferência" required readonly
              :rules="[rules.required]"></v-text-field>
          </v-col>
        </v-row>
        
        <v-row justify="center">
          <v-col cols="12" v-for="file in this.ticket.TicketFiles" :key="file.path">
            <span>{{ file.path.replace('files/', '') }}</span><v-btn :href="linkimagem+file.path" class="tirarfundobotao"><v-icon class="m-2" color="green">mdi-download-box</v-icon></v-btn>
          </v-col>
        </v-row>
       
      </v-container>
    </v-form>
  </template>
  
  <script>
  export default {
    name: "TicketFormConvert",
    props: ['ticket'],
    data() {
      return {
        rules: {
          required: value => !!value || 'Campo obrigatório',
          fileSize: value => !value || !value.length || value[0].size < 2000000 || 'Tamanho máximo do ficheiro são 2 MB!'
        },
        files:[],
        valid: false,
        imageUrl: "",
        index: null,
        linkimagem: 'https://tickets.infordio.pt/'
      };
    },
    methods: {
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h1,
  h2 {
    font-weight: normal;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    display: inline-block;
    margin: 0 10px;
  }
  
  a {
    color: #42b983;
  }
  </style>
  