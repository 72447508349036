<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Tarefas Por Concluir</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-toolbar-title><v-list-item link to="criartarefas" class="v-list-item--link"><v-icon x-large >mdi-plus</v-icon></v-list-item></v-toolbar-title>
        </v-toolbar>
        <v-col cols="12 text-right" id="botaoup">
          <v-btn color="blue" class="ma-2 white--text" @click="tarefasparadown">
            Data<v-icon right dark>mdi-chevron-up</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12 text-right d-none" id="botaodown">
          <v-btn color="blue" class="ma-2 white--text" @click="tarefasparaup">
            Data<v-icon right dark>mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-select @change="getUserTarefas" v-model="select" :items="funcionarios" item-text="funcionario_nome" item-value="funcionario_id" filled outlined label="Interveniente">
            <template v-slot:prepend-item><v-list-item ripple @click="getTarefas" class="v-list-item--link">
              <v-list-item-content><v-list-item-title>Todas</v-list-item-title></v-list-item-content></v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersTarefas" :items="usertarefas">
            <template v-slot:[`item.cliente`]="{ item }">
              <div v-for="cln in clientes" :key="cln.no">
                <span v-if="cln.no == item.tarefa_cliente">{{ cln.nome }}</span>
              </div>
            </template>
            <template v-slot:[`item.descricao`]="{ item }">
              <span v-if="item.tarefa_descricao != null" v-html="item.tarefa_descricao.substring(0,60)"></span>
            </template>
            <template v-slot:[`item.dataregisto`]="{ item }">
              {{ formatDate(item.tarefa_data_registo) }}
            </template>
            <template v-slot:[`item.intervenientes`]="{ item }">
              <div v-for="func in item.Tarefas_Funcionarios" :key="func.tarefas_funcionario_id">
                <template v-if="func.tarefas_funcionario_terminado == 0">
                  {{ func.Funcionario.funcionario_nome }}
                </template>
              </div>
            </template>
            <template v-slot:[`item.urgencia`]="{ item }">
              <v-chip :color="getColor(item.Tarefa_Grau_Urgencia)" dark>{{ item.Tarefa_Grau_Urgencia }}</v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="green" class="m-2" @click="navigateTo({name: 'editartarefas', params:{idEnviado: item.tarefa_id}})">mdi-pencil</v-icon>
              <v-icon color="red" class="m-2" @click="apagarTarefa(item.tarefa_id)">mdi-delete</v-icon>
              <!--
              <v-icon v-if="item.tarefa_concluida != 1" color="red">mdi-close</v-icon>
              <v-icon v-else color="green">mdi-check</v-icon>
              -->
            </template>
          </v-data-table>
        </v-col>
      </v-card>
      <!-- Dialog apagar tarefa -->
      <v-dialog v-model="dialogApagarTarefa">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogApagarTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <span>Quer apagar a tarefa?</span>
          </v-col>
          <v-col cols="12">
            <v-alert v-model="alertTarefaApagada" dismissible type="success">Tarefa apagada com sucesso</v-alert>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="confirmarApagarTarefa">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
  
<script>
//  Importar o service necessário
import TarefasService from '@/services/TarefasService'
import FuncionarioService from '@/services/FuncionarioService'
import ClientesService from '@/services/ClientesService'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.usertarefas = (await TarefasService.tarefasporconcluirasc()).data
    this.funcionarios = (await FuncionarioService.index()).data
    if (this.usertarefas.tarefa_descricao == null) {
      this.usertarefas.tarefa_descricao = ''
    }
    //nomes clientes
    var ola = []
    //nome clientes tarefas
    for (var t = 0; t < this.usertarefas.length; t++) {
        ola.push(this.usertarefas[t].tarefa_cliente)
    }
    this.clientesid = [...new Set(ola)]
    this.clientes = (await ClientesService.clientesids(this.clientesid)).data
  },
  data () {
    return {
      clientesid: [],
      clientes: [],
      funcionarios: [],
      usertarefas: [],
      headersTarefas: [
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Data Registo", value: "dataregisto", sortable: false },
        { text: "Intervenientes", value: "intervenientes", sortable: false },
        { text: "Urgência", value: "urgencia", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      select:'',
      dialogApagarTarefa: false,
      idtarefaglobal: '',
      alertTarefaApagada: false
    }
  },
  methods: {
    // formatar data
    formatDate (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    //cores da urgência
    getColor (urgencia) {
        if (urgencia === 'Média') return 'yellow'
        else if (urgencia === 'Baixa') return 'green'
        else if (urgencia === 'Alta') return 'red'
        else return 'white'
    },
    navigateTo (route) {
      this.$store.dispatch('setTarefa', {  
        idtarefa: route.params.idEnviado,
      })
      this.$router.push(route)
    },
    // ir buscar todas as tarefas
    async getTarefas () {
      this.usertarefas = (await TarefasService.tarefasporconcluirasc()).data
      this.select = ''
    },
    // ir buscar as tarefas por este id funcionario
    async getUserTarefas (idfunc) {
      this.usertarefas = (await TarefasService.usertarefas(idfunc)).data
    },
    // ir buscar Tarefas por ordem descendente
    async tarefasparadown () {
      if (this.select == '') {
        this.usertarefas = (await TarefasService.tarefasporconcluirdesc()).data
      } else {
        this.usertarefas = (await TarefasService.usertarefasdesc(this.select)).data
      }
      var element = document.getElementById("botaoup")
      element.classList.add("d-none")
      var element2 = document.getElementById("botaodown")
      element2.classList.remove("d-none")
    },
    // ir buscar Tarefas por ordem crecente
    async tarefasparaup () {
      if (this.select == '') {
        this.usertarefas = (await TarefasService.tarefasporconcluirasc()).data
      } else {
        this.usertarefas = (await TarefasService.usertarefasasc(this.select)).data
      }
      var element3 = document.getElementById("botaoup")
      element3.classList.remove("d-none")
      var element4 = document.getElementById("botaodown")
      element4.classList.add("d-none")
    },
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    //apagar tarefa
    async apagarTarefa (id) {
      this.dialogApagarTarefa = true
      this.idtarefaglobal = id
    },
    //confirmar apagar tarefa
    async confirmarApagarTarefa () {
      this.alertTarefaApagada = true
      document.location.reload(true)
      await TarefasService.delete(this.idtarefaglobal)
    }
  }
}
</script>
<style>
.v-text-field{
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}
</style>