import Api from '@/services/Api'

export default {
  // todas as projetos
  index () {
    return Api().get('projetos')
  },
  // projetos por concluir crecente
  projetosporconcluirasc () {
    return Api().get('projetosporconcluirasc')
  },
  // projetos por concluir descendente
  projetosporconcluirdesc () {
    return Api().get('projetosporconcluirdesc')
  },
  // ultimo projeto da bd
  ultimoprojeto () {
    return Api().get('ultimoprojeto')
  },
  // projeto com maior numero do ano que estamos
  maiornumeroprojetoano () {
    return Api().get('maiornumeroprojetoano')
  },
  // projetos deste utilizador
  userprojetos (idutilizador) {
    return Api().get('userprojetos',{ 
      params: {
        idutilizador
      }
    })
  },
  // projetos asc deste utilizador
  userprojetosasc (idutilizador) {
    return Api().get('userprojetosasc',{ 
      params: {
        idutilizador
      }
    })
  },
  // projetos desc deste utilizador
  userprojetosdesc (idutilizador) {
    return Api().get('userprojetosdesc',{ 
      params: {
        idutilizador
      }
    })
  },
  // projetos todos deste cliente
  projetoscliente (idcliente) {
    return Api().get('projetoscliente',{ 
      params: {
        idcliente
      }
    })
  },
  // projetos por concluir deste cliente
  projetosporconcluircliente (idcliente) {
    return Api().get('projetosporconcluircliente',{ 
      params: {
        idcliente
      }
    })
  },
  // update projeto
  put (id) {
    return Api().put(`projeto/${id.Projetos_id}`, id)
  },
  // criar projeto
  post (elementos) {
    return Api().post('projeto', elementos)
  },
  // projeto com este id
  show (id) {
    return Api().get(`projeto/${id}`)
  },
  // apagar projeto
  delete (id) {
    return Api().delete(`projeto/${id}`, id)
  }
}
