<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Marcações Por Concluir</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-toolbar-title><v-list-item link to="criarmarcacoes" class="v-list-item--link"><v-icon x-large >mdi-plus</v-icon></v-list-item></v-toolbar-title>
        </v-toolbar>
        <v-col cols="12 text-right" id="botaoup">
          <v-btn color="blue" class="ma-2 white--text" @click="marcacoesparadown">
            Data<v-icon right dark>mdi-chevron-up</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12 text-right d-none" id="botaodown">
          <v-btn color="blue" class="ma-2 white--text" @click="marcacoesparaup">
            Data<v-icon right dark>mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-select @change="getUserMarcacoes" v-model="select" :items="funcionarios" item-text="funcionario_nome" item-value="funcionario_id" filled outlined label="Interveniente">
            <template v-slot:prepend-item><v-list-item ripple @click="getMarcacoes" class="v-list-item--link">
              <v-list-item-content><v-list-item-title>Todas</v-list-item-title></v-list-item-content></v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersMarcacoes" :items="usermarcacoes">
            <template v-slot:[`item.cliente`]="{ item }">
              <div v-for="cln in clientes" :key="cln.no">
                <span v-if="cln.no == item.Marcacoes_cliente">{{ cln.nome }}</span>
              </div>
            </template>
            <template v-slot:[`item.marcacao`]="{ item }">
              <span v-if="item.Marcacoes_descricao != null" v-html="item.Marcacoes_descricao.substring(0,60)"></span>
            </template>
            <template v-slot:[`item.intervenientes`]="{ item }">
              <div v-for="func in item.Marcacoes_Funcionarios" :key="func.Marcacoes_funcionario_id">
                <template v-if="func.Marcacoes_funcionario_terminado == 0">
                  {{ func.Funcionario.funcionario_nome }}
                </template>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="green" class="m-2" @click="navigateTo({name: 'editarmarcacoes', params:{idEnviado2: item.Marcacoes_id}})">mdi-pencil</v-icon>
              <v-icon color="red" class="m-2" @click="apagarMarcacao(item.Marcacoes_id)">mdi-delete</v-icon>
              <!--
              <v-icon v-if="item.Marcacoes_concluida != 1" color="red">mdi-close</v-icon>
              <v-icon v-else color="green">mdi-check</v-icon>
              -->
            </template>
          </v-data-table>
        </v-col>
      </v-card>
      <!-- Dialog apagar marcacao -->
      <v-dialog v-model="dialogApagarMarcacao">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogApagarMarcacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer apagar a marcação?</span>
          </v-col>
          <v-col cols="12">
            <v-alert v-model="alertMarcacaoApagada" dismissible type="success">Marcação apagada com sucesso</v-alert>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="confirmarApagarMarcacao">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
  
<script>
//  Importar o service necessário
import MarcacoesService from '@/services/MarcacoesService'
import FuncionarioService from '@/services/FuncionarioService'
import ClientesService from '@/services/ClientesService'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.usermarcacoes = (await MarcacoesService.marcacoesporconcluirasc()).data
    this.funcionarios = (await FuncionarioService.index()).data
    //nomes clientes
    var ola = []
    //nome clientes marcações
    for (var m = 0; m < this.usermarcacoes.length; m++) {
        ola.push(this.usermarcacoes[m].Marcacoes_cliente)
    }
    this.clientesid = [...new Set(ola)]
    this.clientes = (await ClientesService.clientesids(this.clientesid)).data
  },
  data () {
    return {
      clientesid: [],
      clientes: [],
      funcionarios: [],
      usermarcacoes: [],
      headersMarcacoes: [
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Marcação", value: "marcacao", sortable: false },
        { text: "Intervenientes", value: "intervenientes", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      select:'',
      dialogApagarMarcacao: false,
      idmarcacaoglobal: '',
      alertMarcacaoApagada: false
    }
  },
  methods: {
    navigateTo (route) {
      this.$store.dispatch('setMarcacao', {  
        idmarcacao: route.params.idEnviado2,
      })
      this.$router.push(route)
    },
    // ir buscar todas as marcações
    async getMarcacoes () {
      this.usermarcacoes = (await MarcacoesService.marcacoesporconcluirasc()).data
      this.select = ''
    },
    // ir buscar as marcações por este id
    async getUserMarcacoes (idfunc) {
      this.usermarcacoes = (await MarcacoesService.usermarcacoes(idfunc)).data
    },
    // ir buscar marcações por ordem descendente
    async marcacoesparadown () {
      if (this.select == '') {
        this.usermarcacoes = (await MarcacoesService.marcacoesporconcluirdesc()).data
      } else {
        this.usermarcacoes = (await MarcacoesService.usermarcacoesdesc(this.select)).data
      }
      var element = document.getElementById("botaoup")
      element.classList.add("d-none")
      var element2 = document.getElementById("botaodown")
      element2.classList.remove("d-none")
    },
    // ir buscar marcações por ordem crecente
    async marcacoesparaup () {
      if (this.select == '') {
        this.usermarcacoes = (await MarcacoesService.marcacoesporconcluirasc()).data
      } else {
        this.usermarcacoes = (await MarcacoesService.usermarcacoesasc(this.select)).data
      }
      var element3 = document.getElementById("botaoup")
      element3.classList.remove("d-none")
      var element4 = document.getElementById("botaodown")
      element4.classList.add("d-none")
    },
    //-------------------------------------------marcações------------------------------//
    //-------------------------------------------marcações------------------------------//
    //-------------------------------------------marcações------------------------------//
    //apagar marcação
    async apagarMarcacao (id) {
      this.dialogApagarMarcacao = true
      this.idmarcacaoglobal = id
    },
    //confirmar apagar marcação
    async confirmarApagarMarcacao () {
      this.alertMarcacaoApagada = true
      document.location.reload(true)
      await MarcacoesService.delete(this.idmarcacaoglobal)
    }
  }
}
</script>
<style>
.v-text-field{
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}
</style>