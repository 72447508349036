<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Editar Tarefa</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <label>Cliente</label>
                    <v-text-field v-model="cliente.nome" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Quem Registou</label>
                    <v-text-field v-model="editarTarefa.Funcionario.funcionario_nome" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Data de Registo</label>
                    <MYdatetime type="date" :value="editarTarefa.tarefa_data_registo" disabled="disabled"></MYdatetime>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Quem Pediu</label>
                    <v-text-field type="text" v-model="editarTarefa.Tarefa_Quem_Pediu" clearable filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Pedido Como</label>
                    <v-select v-model="editarTarefa.Tarefa_Como_Pediu" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']" filled outlined></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8">
                    <v-row>
                      <v-col cols="12">
                        <label>Descrição</label>
                        <wysiwyg v-model="editarTarefa.tarefa_descricao"/>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Urgência</label>
                        <v-select v-model="editarTarefa.Tarefa_Grau_Urgencia" :items="['Alta', 'Média', 'Baixa']" filled outlined></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Tópico</label>
                        <v-select v-model="editarTarefa.tarefa_Topico" :items="['Atualização aplicação', 'Atualização contrato', 'Em desenvolvimento', 'Formação', 'Instalação', 'Orçamentos']" filled outlined></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Estado</label>
                        <v-select v-model="editarTarefa.tarefas_estado" :items="estados" item-text="Estado_descricao" item-value="Estado_id" filled outlined></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Data de Inicio</label>
                        <MYdatetime hidden-name type="date" v-model="editarTarefa.tarefa_data_inicio"></MYdatetime>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Data de fim</label>
                        <MYdatetime hidden-name type="date" v-model="editarTarefa.tarefa_data_fim"></MYdatetime>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <label>Intervenientes</label>
                        <v-icon large color="black" @click="intervModal">mdi-plus</v-icon>
                      </v-col>
                      <v-col cols="12" v-for="(tf) in editarTarefa.Tarefas_Funcionarios" :key="tf.tarefas_funcionario_id">
                        <v-list class="interve">
                          <v-list-item>
                            <v-list-item-action class="pr-3 mr-0">
                              <v-icon color="blue" @click="obsModal(tf.tarefas_funcionario_id)">mdi-note</v-icon>
                            </v-list-item-action>
                            <v-list-item-content v-if="tf.Tarefas_Funcionario_Obs != 0" class="back-obs">
                              <v-list-item-title>{{tf.Funcionario.funcionario_nome}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content v-else>
                              <v-list-item-title>{{tf.Funcionario.funcionario_nome}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="pl-3 ml-0">
                              <v-icon color="blue" @click="enviarEmail(tf)">mdi-email-arrow-right</v-icon>
                            </v-list-item-action>
                            <span class="d-none intElementos">{{ tf.tarefas_funcionario_terminado }}</span>
                            <template v-if="tf.tarefas_funcionario_terminado == false">
                              <v-list-item-action class="pl-3">
                                <v-icon color="red" @click="falseparatrue(tf.tarefas_funcionario_id)">mdi-close</v-icon>
                              </v-list-item-action>
                            </template>
                            <template v-else>
                              <v-list-item-action class="pl-3">
                                <v-icon color="green" @click="trueparafalse(tf.tarefas_funcionario_id)">mdi-check</v-icon>
                              </v-list-item-action>
                            </template>
                            <template v-if="tf.tarefas_funcionario_enviado_mail == true">
                              <v-list-item-action class="pl-3">
                                <v-icon color="green" @click="QuemEnviamosEmail(tf.tarefas_funcionario_id)">mdi-email</v-icon>
                              </v-list-item-action>
                            </template>
                            <template v-else>
                              <v-list-item-action class="pl-3">
                                <v-icon color="white">mdi-email</v-icon>
                              </v-list-item-action>
                            </template>
                            <v-list-item-action class="pl-3">
                              <v-icon color="red" @click="apagarInterv(tf)">mdi-delete</v-icon>
                            </v-list-item-action>
                            <v-list-item-action class="pl-3 ml-0">
                              <v-icon color="purple" @click="navigateTo({name: 'criartempos', params:{idEnviado7: tf.tarefas_funcionario_id}})">mdi-timer</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <v-btn class="w-100" id="botaocriareditarmarcação" @click="CriarTarefaMarcacao">{{ textobotaomarcacao }}</v-btn>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">       
                    <v-btn v-if="editarTarefa.Tarefa_Projeto == 0 || editarTarefa.Tarefa_Projeto == null" class="classbotaoadicionarprojeto w-100" @click="AdicionarProjeto">Adicionar Projeto</v-btn>
                    <v-btn v-else class="classbotaoeditarprojeto w-100" @click="navigateTo({name: 'editarprojetos', params:{idEnviado10: editarTarefa.Tarefa_Projeto}})">Projeto - {{ editarTarefa.Tarefa_Projeto_numero }}</v-btn>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">       
                    <v-btn class="w-100" color="primary" @click="EditarTarefa">Guardar Tarefa</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-alert v-model="alertDataFimMaior" dismissible type="error">Data de Fim menor que a de Inicio</v-alert>
                    <v-alert v-model="alertEditada" dismissible type="success">Tarefa editada com sucesso</v-alert>
                  </v-col>
                </v-row>
            </v-form>
          </v-card-text>
          <!-- Dialog Adicionar Interveniente -->
          <v-dialog v-model="dialogAdicionarInterveniente">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Adicionar Interveniente</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-list>
                      <v-list-item-group color="primary">
                        <div v-for="func in funcionarios" :key="func.funcionario_id">
                          <v-divider></v-divider>
                          <v-list-item>
                            <template>
                              <v-list-item-action>
                                <v-checkbox v-model="checkedInterv" :value="func"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{func.funcionario_nome}}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </div>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                  <v-col cols="12">
                    <v-alert v-model="alertIntervAdd" dismissible type="success">Interveninete adicionado com sucesso</v-alert>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="addInterveniente">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog observações -->
          <v-dialog v-model="dialogObs">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Notas</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>          
                <v-toolbar-title><v-list-item @click="dialogObs = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-container>
                    <v-row class="mb" v-for=" obs in tarefasfuncionarioobs" :key="obs.tarefas_Funcionario_Obs_ID">
                      <v-col cols="12">
                        <span>{{ obs.Funcionario.funcionario_nome }} - {{ formatDate(obs.tarefas_Funcionario_Obs_data) }}</span>
                        <wysiwyg v-model="obs.tarefas_Funcionario_Obs_Descricao"/>
                        <v-list-item-action class="ml-0">
                          <v-icon color="green" @click="editarObs(obs)">mdi-arrow-up-bold-box-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-action class="ml-0 pl-3">
                          <v-icon color="red" @click="apagarObs(obs.tarefas_Funcionario_Obs_ID)">mdi-delete</v-icon>
                        </v-list-item-action>
                      </v-col>
                      <v-col cols="12">
                        <v-alert v-model="alertObsEditada" dismissible type="success">Nota editada com sucesso</v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="criarObsModal">Criar Nota</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog criar observações -->
          <v-dialog v-model="dialogCriarObs">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Criar Nota</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogCriarObs = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-container>
                    <v-row class="mb">
                      <v-col cols="12">
                        <label>Interveniente</label>
                        <v-autocomplete v-model="createObservacoes.tarefas_Funcionario_Obs_Quem_Enviou" :items="funcionarios" item-text="funcionario_nome" item-value="funcionario_id" clearable filled outlined></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <label>Data de Registo</label>
                        <MYdatetime type="date" :value="new Date(Date.now()).toISOString()" disabled="disabled"></MYdatetime>
                      </v-col>
                      <v-col cols="12">
                        <label>Descrição</label>
                        <wysiwyg v-model="createObservacoes.tarefas_Funcionario_Obs_Descricao"/>
                      </v-col>
                      <v-col cols="12">
                        <v-alert v-model="alertObsCriada" dismissible type="success">Nota criada com sucesso</v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="criarObs">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog apagar obs -->
          <v-dialog v-model="dialogApagarObs">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarObs = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <span>Quer apagar a Nota?</span>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertObsApagada" dismissible type="success">Nota apagada com sucesso</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarApagarObs">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog apagar interv -->
          <v-dialog v-model="dialogApagarInterv">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarInterv = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <span>Quer apagar o Interveniente?</span>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertintervApagado" dismissible type="success">Interveniente apagado com sucesso</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarApagarInterv">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog Todos Interv Terminados -->
          <v-dialog v-model="dialogTodosIntervTerminados">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogTodosIntervTerminados = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <span>Tarefa concluida por todos os intervenientes. Quer dá-la como terminada?</span>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertTarefaTerminada" dismissible type="success">Tarefa terminada com sucesso</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarTarefaTerminada">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog Projetos deste cliente abertos -->
          <v-dialog v-model="dialogProjetosClienteAbertos">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Projetos</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogProjetosClienteAbertos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersProjetos" :items="projetosporconcluircliente">
                    <template v-slot:[`item.projeto`]="{ item }">
                      <span>{{ item.Projetos_ano }}/{{ item.Projetos_numero }}</span>
                    </template>
                    <template v-slot:[`item.datainicio`]="{ item }">
                      <span v-if="item.Projetos_data_inicio != null">{{ formatDate(item.Projetos_data_inicio) }}</span>
                    </template>
                    <template v-slot:[`item.datafim`]="{ item }">
                      <span v-if="item.Projetos_data_fim != null">{{ formatDate(item.Projetos_data_fim) }}</span>
                    </template>
                    <template v-slot:[`item.estado`]="{ item }">
                      <div v-for="estados in estados" :key="estados.Estado_id">
                        <span v-if="estados.Estado_id == item.Projetos_estado">{{ estados.Estado_descricao }}</span>
                      </div>
                    </template>
                    <template v-slot:[`item.descricao`]="{ item }">
                      <span v-if="item.Projetos_descricao != null" v-html="item.Projetos_descricao.substring(0,100)"></span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon color="green" @click="abriarmodalconfirmaradicionarprojeto(item)">mdi-arrow-right-box</v-icon>
                    </template>
                  </v-data-table>
              </v-col>
            </v-card>
          </v-dialog>
          <!-- Dialog confirmar que é este projeto -->
          <v-dialog v-model="dialogConfirmarProjetoTarefa">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogConfirmarProjetoTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                <span>Que mesmo adicionar a tarefa a este projeto?</span>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertTarefaProjetoAdicionada" dismissible type="success">Tarefa adicionada com sucesso</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarTarefaProjetoAdicionada">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog confirmar enviar email -->
          <v-dialog v-model="dialogConfirmarEnviarEmail">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogConfirmarEnviarEmail = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                <span>Que mesmo enviar email?</span>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="dadosEmail.checkedEmailFuncionario" :label="tfglobal.Funcionario?.funcionario_email"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="dadosEmail.checkedEmailCliente" :label="cliente.email"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertEmailEnviado" dismissible type="success">Email enviado com sucesso</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarEmailEnviado">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog Ver para quem enviamos email -->
          <v-dialog v-model="dialogQuemEnviamosEmail">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogQuemEnviamosEmail = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12" v-if="editarTarefaFuncionarioEmail.tarefas_funcionario_enviado_mail != null">
                <span>Email enviado em {{ formatDate(editarTarefaFuncionarioEmail.tarefas_funcionario_enviado_mail_data) }} para o funcionário {{ editarTarefaFuncionarioEmail.tarefas_funcionario_enviado_mail_quem }}</span>
              </v-col>
              <v-col cols="12" v-if="editarTarefaFuncionarioEmailCliente.tarefas_funcionario_enviado_mail_cliente != null">
                <span>Email enviado em {{ formatDate(editarTarefaFuncionarioEmailCliente.tarefas_funcionario_enviado_mail_cliente_data) }} para o cliente {{ editarTarefaFuncionarioEmailCliente.tarefas_funcionario_enviado_mail_cliente_quem }}</span>
              </v-col>
            </v-card>
          </v-dialog>
        </v-card>
    </v-flex>
  </v-layout>
</template> 
<script>
//  Importar o service necessário
import TarefasService from '@/services/TarefasService'
import EstadosService from '@/services/EstadosService'
import FuncionarioService from '@/services/FuncionarioService'
import ClientesService from '@/services/ClientesService'
import TarefasFuncionarioService from '@/services/TarefasFuncionarioService'
import TarefasFuncionarioObsService from '@/services/TarefasFuncionarioObsService'
import LogService from '@/services/LogService'
import MarcacoesService from '@/services/MarcacoesService'
import ProjetosService from '@/services/ProjetosService'
import ServiceEmails from '@/services/ServiceEmails'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.editarTarefa = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
    this.cliente = (await ClientesService.show(this.editarTarefa.tarefa_cliente)).data
    this.estados = (await EstadosService.index()).data
    this.funcionarios = (await FuncionarioService.index()).data
    if (this.editarTarefa.tarefa_data_inicio == '') {
      this.editarTarefa.tarefa_data_inicio = null
    }
    if (this.editarTarefa.tarefa_data_fim == '') {
      this.editarTarefa.tarefa_data_fim = null
    }
    // se os intervenientes tiverem todos concluidos vai colocar a tarefa como terminada
    var elemento = document.getElementsByClassName('intElementos')
    var contador = 0
    for (var i = 0; i < elemento.length; i++) {
      if (elemento[i].innerHTML == 'false') {
        contador = contador + 1
      }
    }
    if (contador == 0 && this.editarTarefa.tarefa_concluida != true) {
      this.dialogTodosIntervTerminados = true
    }
    //se já tiver marcação criada com esta tarefa
    this.marcacao = (await MarcacoesService.marcacoestarefa(this.$store.state.tarefa.idtarefa)).data
    if (this.marcacao.Marcacoes_id == undefined) {
      var element = document.getElementById("botaocriareditarmarcação")
      element.classList.add("classbotaocriareditarmarcaçãoazul")
    } else {
      var element2 = document.getElementById("botaocriareditarmarcação")
      element2.classList.add("classbotaocriareditarmarcaçãored")
      this.textobotaomarcacao = "Abir Marcação"
      this.idmarcacaoencontrada = this.marcacao.Marcacoes_id
    }
  },
  data () {
    return {
      textobotaomarcacao: "Criar Marcação",
      createLog: {
        Log_Tabela: null,
        Log_Tipo: null,
        Log_Numero: null,
        Log_Data: new Date(Date.now()).toISOString(),
        Log_Funcionario: this.$store.state.user.funcionario,
        Log_Descricao: null,
      },
      editarTarefa: {
        tarefa_descricao: null,
        tarefa_data_inicio: null,
        tarefa_data_fim: null,
        tarefa_concluida: null,
        tarefa_concluida_data: null,
        tarefa_Topico: null,
        tarefas_estado: null,
        Tarefa_Grau_Urgencia: null,
        Tarefa_Quem_Pediu: null,
        Tarefa_Como_Pediu: null,
        Tarefa_Projeto: null,
        Tarefa_Projeto_numero: null
      },
      createTarefasFuncionario: {
        tarefas_funcionario_tarefa: this.$store.state.tarefa.idtarefa,
        tarefas_funcionario_funcionario: null,
        tarefas_funcionario_atribuido_data: new Date(Date.now()),
        tarefas_funcionario_atribuido_por: this.$store.state.user.funcionario,
      },
      editarTarefaFuncionario: {
        tarefas_funcionario_terminado: null,
        tarefas_funcionario_terminado_data: null
      },
      editarTarefaFuncionarioEmail: {
        tarefas_funcionario_enviado_mail: null,
        tarefas_funcionario_enviado_mail_data: null,
        tarefas_funcionario_enviado_mail_quem: null
      },
      editarTarefaFuncionarioEmailCliente: {
        tarefas_funcionario_enviado_mail_cliente: null,
        tarefas_funcionario_enviado_mail_cliente_data: null,
        tarefas_funcionario_enviado_mail_cliente_quem: null
      },
      createObservacoes: {
        Tarefas_Funcionario_Obs_Tarefa: null,
        tarefas_Funcionario_Obs_data: new Date(Date.now()),
        tarefas_Funcionario_Obs_Descricao: null,
        tarefa_Id: this.$store.state.tarefa.idtarefa,
        tarefas_Funcionario_Obs_Quem_Enviou: this.$store.state.user.funcionario
      },
      editarTarefaFuncionarioobs: {
        tarefas_Funcionario_Obs_Descricao: null
      },
      editarMarcacao: {
        Marcacoes_Projeto: null,
        Marcacoes_Projeto_numero: null
      },
      dadosEmail: {
        cliente: null,
        clienteemail: null,
        quemenviou: null,
        texto: null,
        paraquemenviar: null,
        tipo: null,
        checkedEmailFuncionario: true,
        checkedEmailCliente: false,
      },
      estados: [],
      funcionarios: [],
      cliente: {},
      checkedInterv: [],
      tarefasfuncionarioobs: [],
      dialogObs: false,
      dialogCriarObs: false,
      idtareffunc: '',
      alertObsCriada: false,
      alertObsEditada: false,
      alertObsApagada: false,
      dialogApagarObs: false,
      idobsglobal: '',
      alertDataFimMaior: false,
      alertEditada: false,
      alertEmailEnviado: false,
      alertintervApagado: false,
      dialogApagarInterv: false,
      idintervglobal: '',
      dialogTodosIntervTerminados: false,
      alertTarefaTerminada: false,
      alertIntervAdd: false,
      dialogAdicionarInterveniente: false,
      dialogProjetosClienteAbertos: false,
      dialogConfirmarProjetoTarefa: false,
      dialogConfirmarEnviarEmail: false,
      dialogQuemEnviamosEmail: false,
      alertTarefaProjetoAdicionada: false,
      projetosporconcluircliente: [],
      headersProjetos: [
        { text: "Projeto", value: "projeto", sortable: false },
        { text: "Data Inicio", value: "datainicio", sortable: false },
        { text: "Data Fim", value: "datafim", sortable: false },
        { text: "Estado Projeto", value: "estado", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      idprojetoescolhido: '',
      numeroprojetoescolhido: '',
      idmarcacaoencontrada: '',
      tfglobal: '',
    }
  },
  methods: {
    // formatar data
    formatDate (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    navigateTo (route) {
      this.$store.dispatch('setTeste', {
        idtarefafuncionario: route.params.idEnviado7,
      })
      this.$store.dispatch('setProjeto', {  
        idprojeto: route.params.idEnviado10
      })
      this.$router.push(route)
    },
    //-------------------------------------------tarefa------------------------------//
    //-------------------------------------------tarefa------------------------------//
    //-------------------------------------------tarefa------------------------------//
    async EditarTarefa () {
      // se ambas as datas tiverem preenchidas vai verificar se a data de fim é maior que a data de inicio
      if (this.editarTarefa.tarefa_data_inicio != null && this.editarTarefa.tarefa_data_fim != null) {
        const datainiciopc = this.editarTarefa.tarefa_data_inicio
        const datafimpc = this.editarTarefa.tarefa_data_fim
        const datainiciocc = new Date(datainiciopc)
        const datafimcc = new Date(datafimpc)
        if (datainiciocc.getTime() > datafimcc.getTime()) {
          this.alertDataFimMaior = true
        } else {
          await TarefasService.put(this.editarTarefa)
          //adicionar log
          this.createLog.Log_Tabela = 'Tarefas',
          this.createLog.Log_Tipo = 'Editar',
          this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
          this.createLog.Log_Descricao = 'Tarefa alterada por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)
          this.alertEditada = true
          document.location.reload(true)
        }
      } else {
        await TarefasService.put(this.editarTarefa)
        //adicionar log
        this.createLog.Log_Tabela = 'Tarefas',
        this.createLog.Log_Tipo = 'Editar',
        this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
        this.createLog.Log_Descricao = 'Tarefa alterada por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
        this.alertEditada = true
        document.location.reload(true)
      }
    },
    // criar marcação a partir deste tarefa
    async CriarTarefaMarcacao () {
      // se ambas as datas tiverem preenchidas vai verificar se a data de fim é maior que a data de inicio
      if (this.editarTarefa.tarefa_data_inicio != null && this.editarTarefa.tarefa_data_fim != null) {
        const datainiciopc = this.editarTarefa.tarefa_data_inicio
        const datafimpc = this.editarTarefa.tarefa_data_fim
        const datainiciocc = new Date(datainiciopc)
        const datafimcc = new Date(datafimpc)
        if (datainiciocc.getTime() > datafimcc.getTime()) {
          this.alertDataFimMaior = true
        } else {
          await TarefasService.put(this.editarTarefa)
          //adicionar log
          this.createLog.Log_Tabela = 'Tarefas',
          this.createLog.Log_Tipo = 'Editar',
          this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
          this.createLog.Log_Descricao = 'Tarefa alterada por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)
          this.alertEditada = true
          //se já tiver marcação criada com esta tarefa
          if (this.marcacao.Marcacoes_id == undefined) {
            this.$store.dispatch('setTeste', {
              idtarefamarcacao: this.$store.state.tarefa.idtarefa
            })
            this.$router.push({
              path: `/criarmarcacoes`
            })
          } else {
            this.$store.dispatch('setMarcacao', {
              idmarcacao: this.idmarcacaoencontrada
            })
            this.$router.push({
              path: `/editarmarcacoes`
            })
          }
        }
      } else {
        await TarefasService.put(this.editarTarefa)
        //adicionar log
        this.createLog.Log_Tabela = 'Tarefas',
        this.createLog.Log_Tipo = 'Editar',
        this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
        this.createLog.Log_Descricao = 'Tarefa alterada por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
        this.alertEditada = true
        //se já tiver marcação criada com esta tarefa
        if (this.marcacao.Marcacoes_id == undefined) {
          this.$store.dispatch('setTeste', {
            idtarefamarcacao: this.$store.state.tarefa.idtarefa
          })
          this.$router.push({
            path: `/criarmarcacoes`
          })
        } else {
          this.$store.dispatch('setMarcacao', {
            idmarcacao: this.idmarcacaoencontrada
          })
          this.$router.push({
            path: `/editarmarcacoes`
          })
        }
      }
    },
    //confirmar tarefa terminada
    async confirmarTarefaTerminada () {
      // se ambas tiverem preenchidas vai verificar se a de fim é maior que a de inicio
      if (this.editarTarefa.tarefa_data_inicio != null && this.editarTarefa.tarefa_data_fim != null) {
        const datainiciopc = this.editarTarefa.tarefa_data_inicio
        const datafimpc = this.editarTarefa.tarefa_data_fim
        const datainiciocc = new Date(datainiciopc)
        const datafimcc = new Date(datafimpc)
        if (datainiciocc.getTime() > datafimcc.getTime()) {
          this.alertDataFimMaior = true
        } else {
          this.editarTarefa.tarefa_concluida_data = new Date(Date.now())
          this.editarTarefa.tarefa_concluida = true
          await TarefasService.put(this.editarTarefa)
          this.alertTarefaTerminada = true
          document.location.reload(true)
        }
      } else {
        this.editarTarefa.tarefa_concluida_data = new Date(Date.now())
        this.editarTarefa.tarefa_concluida = true
        await TarefasService.put(this.editarTarefa)
        this.alertTarefaTerminada = true
        document.location.reload(true)
      }
    },
    //-------------------------------------------tarefa funcionario------------------------------//
    //-------------------------------------------tarefa funcionario------------------------------//
    //-------------------------------------------tarefa funcionario------------------------------//
    //abrir modal adicionar interveniente
    async intervModal () {
      this.dialogAdicionarInterveniente = true
    },
    // adicionar interveniente
    async addInterveniente () {
      for (var iiiii = 0; iiiii < this.checkedInterv.length; iiiii++) {
        this.createTarefasFuncionario.tarefas_funcionario_funcionario = this.checkedInterv[iiiii].funcionario_id
        await TarefasFuncionarioService.post(this.createTarefasFuncionario)
        //adicionar log
        this.createLog.Log_Tabela = 'Tarefas',
        this.createLog.Log_Tipo = 'Adicionar',
        this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
        this.createLog.Log_Descricao = 'Funcionário ' + this.checkedInterv[iiiii].funcionario_nome + ' adicionado por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
      }
      this.alertIntervAdd = true
      document.location.reload(true)
    },
    //apagar tarefa funcionario
    async apagarInterv (id) {
      this.dialogApagarInterv = true
      this.idintervglobal = id
    },
    //confirmar tarefa funcionario
    async confirmarApagarInterv () {
      this.funcionarionome = (await FuncionarioService.show(this.idintervglobal.tarefas_funcionario_funcionario)).data
      //adicionar log
      this.createLog.Log_Tabela = 'Tarefas',
      this.createLog.Log_Tipo = 'Eliminar',
      this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
      this.createLog.Log_Descricao = 'Funcionário ' + this.funcionarionome.funcionario_nome + ' eliminado por ' + this.$store.state.user.Usuario
      await LogService.post(this.createLog)
      this.alertintervApagado = true
      document.location.reload(true)
      await TarefasFuncionarioService.delete(this.idintervglobal.tarefas_funcionario_id)
    },
    //passar tarefa funcionario de false para true
    async falseparatrue (id) {
      this.editarTarefaFuncionario = (await TarefasFuncionarioService.show(id)).data
      this.editarTarefaFuncionario.tarefas_funcionario_terminado = true
      this.editarTarefaFuncionario.tarefas_funcionario_terminado_data = new Date(Date.now())
      await TarefasFuncionarioService.put(this.editarTarefaFuncionario)
      document.location.reload(true)
    },
    //passar tarefa funcionario de true para false
    async trueparafalse (id) {
      this.editarTarefaFuncionario = (await TarefasFuncionarioService.show(id)).data
      this.editarTarefaFuncionario.tarefas_funcionario_terminado = false
      this.editarTarefaFuncionario.tarefas_funcionario_terminado_data = null
      await TarefasFuncionarioService.put(this.editarTarefaFuncionario)
      document.location.reload(true)
    },
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    //abrir modal dialogObs
    async obsModal (tarefafuncid) {
      this.idtareffunc = tarefafuncid
      this.dialogObs = true
      this.tarefasfuncionarioobs = (await TarefasFuncionarioObsService.tarefafuncobs(tarefafuncid)).data
    },
    //abrir modal dialogCriarObs
    async criarObsModal () {
      this.dialogCriarObs = true
    },
    //criar obs
    async criarObs () {
      this.createObservacoes.Tarefas_Funcionario_Obs_Tarefa = this.idtareffunc
      await TarefasFuncionarioObsService.post(this.createObservacoes)
      //adicionar log
      this.createLog.Log_Tabela = 'Tarefas',
      this.createLog.Log_Tipo = 'Adicionar',
      this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
      this.createLog.Log_Descricao = 'Nova nota adicionada por ' + this.$store.state.user.Usuario
      await LogService.post(this.createLog)
      this.alertObsCriada = true
      document.location.reload(true)
    },
    //editar obs
    async editarObs (obs) {
      this.editarTarefaFuncionarioobs = (await TarefasFuncionarioObsService.show(obs.tarefas_Funcionario_Obs_ID)).data
      this.editarTarefaFuncionarioobs.tarefas_Funcionario_Obs_Descricao = obs.tarefas_Funcionario_Obs_Descricao
      await TarefasFuncionarioObsService.put(this.editarTarefaFuncionarioobs)
      //adicionar log
      this.createLog.Log_Tabela = 'Tarefas',
      this.createLog.Log_Tipo = 'Editar',
      this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
      this.createLog.Log_Descricao = 'Nota editada por ' + this.$store.state.user.Usuario
      await LogService.post(this.createLog)
      this.alertObsEditada = true
      document.location.reload(true)
    },
    //apagar obs
    async apagarObs (id) {
      this.dialogApagarObs = true
      this.idobsglobal = id
    },
    //confirmar apagar obs
    async confirmarApagarObs () {
      //adicionar log
      this.createLog.Log_Tabela = 'Tarefas',
      this.createLog.Log_Tipo = 'Eliminar',
      this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
      this.createLog.Log_Descricao = 'Nota eliminada por ' + this.$store.state.user.Usuario
      await LogService.post(this.createLog)
      this.alertObsApagada = true
      document.location.reload(true)
      await TarefasFuncionarioObsService.delete(this.idobsglobal)
    },
    //-------------------------------------------Adicionar a projeto------------------------------//
    //-------------------------------------------Adicionar a projeto------------------------------//
    //-------------------------------------------Adicionar a projeto------------------------------//
    async AdicionarProjeto () {
      // se ambas as datas tiverem preenchidas vai verificar se a data de fim é maior que a data de inicio
      if (this.editarTarefa.tarefa_data_inicio != null && this.editarTarefa.tarefa_data_fim != null) {
        const datainiciopc = this.editarTarefa.tarefa_data_inicio
        const datafimpc = this.editarTarefa.tarefa_data_fim
        const datainiciocc = new Date(datainiciopc)
        const datafimcc = new Date(datafimpc)
        if (datainiciocc.getTime() > datafimcc.getTime()) {
          this.alertDataFimMaior = true
        } else {
          await TarefasService.put(this.editarTarefa)
          //adicionar log
          this.createLog.Log_Tabela = 'Tarefas',
          this.createLog.Log_Tipo = 'Editar',
          this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
          this.createLog.Log_Descricao = 'Tarefa alterada por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)
          this.alertEditada = true
          this.projetosporconcluircliente = (await ProjetosService.projetosporconcluircliente(this.cliente.no)).data
          this.dialogProjetosClienteAbertos = true
        }
      } else {
        await TarefasService.put(this.editarTarefa)
        //adicionar log
        this.createLog.Log_Tabela = 'Tarefas',
        this.createLog.Log_Tipo = 'Editar',
        this.createLog.Log_Numero = this.$store.state.tarefa.idtarefa
        this.createLog.Log_Descricao = 'Tarefa alterada por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
        this.alertEditada = true
        this.projetosporconcluircliente = (await ProjetosService.projetosporconcluircliente(this.cliente.no)).data
        this.dialogProjetosClienteAbertos = true
      }
      
    },
    async abriarmodalconfirmaradicionarprojeto (item) {
      this.dialogConfirmarProjetoTarefa = true
      this.idprojetoescolhido = item.Projetos_id
      this.numeroprojetoescolhido = item.Projetos_ano + '/' + item.Projetos_numero
    },
    async confirmarTarefaProjetoAdicionada () {
      this.editarTarefa.Tarefa_Projeto = this.idprojetoescolhido
      this.editarTarefa.Tarefa_Projeto_numero = this.numeroprojetoescolhido
      await TarefasService.put(this.editarTarefa)
      if (this.marcacao.Marcacoes_id != undefined) {
        this.marcacao.Marcacoes_Projeto = this.idprojetoescolhido,
        this.marcacao.Marcacoes_Projeto_numero = this.numeroprojetoescolhido
        await MarcacoesService.put(this.marcacao)
      }
      this.alertTarefaProjetoAdicionada = true
      document.location.reload(true)
    },
    //-------------------------------------------Enviar Email------------------------------//
    //-------------------------------------------Enviar Email------------------------------//
    //-------------------------------------------Enviar Email------------------------------//
    async enviarEmail(tf) {
      this.dialogConfirmarEnviarEmail = true
      this.tfglobal = tf
    },
    async confirmarEmailEnviado() {
        try {
          this.dadosEmail.cliente = this.cliente.nome
          this.dadosEmail.clienteemail = this.cliente.email      
          this.dadosEmail.texto = this.editarTarefa.tarefa_descricao
          this.dadosEmail.tecnicoescolhidoemail = this.tfglobal.Funcionario.funcionario_email
          this.dadosEmail.tecnicoescolhidonome = this.tfglobal.Funcionario.funcionario_nome
          this.dadosEmail.quemenviou = this.$store.state.user.Usuario
          this.dadosEmail.tipo = 'Tarefa'
          
          if (this.dadosEmail.checkedEmailFuncionario == true) {
            this.editarTarefaFuncionarioEmail = (await TarefasFuncionarioService.show(this.tfglobal.tarefas_funcionario_id)).data
            this.editarTarefaFuncionarioEmail.tarefas_funcionario_enviado_mail = true
            this.editarTarefaFuncionarioEmail.tarefas_funcionario_enviado_mail_data = new Date(Date.now())
            this.editarTarefaFuncionarioEmail.tarefas_funcionario_enviado_mail_quem = this.tfglobal.Funcionario.funcionario_email
            await TarefasFuncionarioService.put(this.editarTarefaFuncionarioEmail)
          }
          

          if (this.dadosEmail.checkedEmailCliente == true) {
            this.editarTarefaFuncionarioEmailCliente = (await TarefasFuncionarioService.show(this.tfglobal.tarefas_funcionario_id)).data
            this.editarTarefaFuncionarioEmailCliente.tarefas_funcionario_enviado_mail_cliente = true
            this.editarTarefaFuncionarioEmailCliente.tarefas_funcionario_enviado_mail_cliente_data = new Date(Date.now())
            this.editarTarefaFuncionarioEmailCliente.tarefas_funcionario_enviado_mail_cliente_quem = this.cliente.email
            await TarefasFuncionarioService.put(this.editarTarefaFuncionarioEmailCliente)
          }

          this.alertEmailEnviado = true
          document.location.reload(true)
          await ServiceEmails.sendEmail(this.dadosEmail)

        } catch (error) {
            this.alertEmailEnviado = false
        }
    },
    async QuemEnviamosEmail(id) {
      this.editarTarefaFuncionarioEmail = (await TarefasFuncionarioService.show(id)).data
      this.editarTarefaFuncionarioEmailCliente = (await TarefasFuncionarioService.show(id)).data
      this.dialogQuemEnviamosEmail = true
    },
  }
}
</script>
<style>
.interve .v-list-item {
  padding: 0 !important;
}
.editr--toolbar{
  display: none !important;
}
.classbotaocriareditarmarcaçãored {
  background-color: red !important;
  border-color: red !important;
  color: white !important;
}
.classbotaocriareditarmarcaçãoazul {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
  color: white !important;
}
.classbotaoadicionarprojeto {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
  color: white !important;
}
.classbotaoeditarprojeto {
  background-color: green !important;
  border-color: green !important;
  color: white !important;
}

</style>