import Api from '@/services/Api'

export default {
  // todas os projetos funcionario
  index () {
    return Api().get('projetosfuncionario')
  },
  // criar projeto funcionario
  post (id) {
    return Api().post('projetosfuncionario', id)
  },
  // projeto funcionario com este id
  show (id) {
    return Api().get(`projetofuncionario/${id}`)
  },
  // update projeto funcionario
  put (id) {
    return Api().put(`projetofuncionario/${id.Projetos_funcionario_id}`, id)
  },
  // apagar projeto funcionario
  delete (id) {
    return Api().delete(`projetofuncionario/${id}`, id)
  }
}
