import Api from '@/services/Api'

export default {
  // todas as tarefas funcionario
  index () {
    return Api().get('tarefasfuncionario')
  },
  // criar tarefa funcionario
  post (id) {
    return Api().post('tarefasfuncionario', id)
  },
  // tarefa funcionario com este id
  show (id) {
    return Api().get(`tarefafuncionario/${id}`)
  },
  // update tarefa funcionario
  put (id) {
    return Api().put(`tarefafuncionario/${id.tarefas_funcionario_id}`, id)
  },
  // apagar tarefa funcionario
  delete (id) {
    return Api().delete(`tarefafuncionario/${id}`, id)
  }
}
