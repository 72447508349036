import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import { sync } from 'vuex-router-sync'
import router from './router'
import store from '@/store/store'
import './registerServiceWorker'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'

Vue.use(wysiwyg, {})
Vue.use(Datetime)
Vue.component('MYdatetime', Datetime)

Vue.config.productionTip = false
sync(store, router)

// eslint-disable-next-line
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

module.exports = {
  head: [
    ['link', {
      rel: 'stylesheet',
      href: 'https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css'
    }]
  ]
}