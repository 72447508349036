import Api from '@/services/Api'

export default {
  index () {
    return Api().get('projetosfuncionarioobs')
  },
  // criar obs
  post (elementos) {
    return Api().post('projetosfuncionarioobs', elementos)
  },
  // apagar obs
  delete (id) {
    return Api().delete(`projetosfuncionarioobs/${id}`, id)
  },
  // guardar obs por este id
  put (id) {
    return Api().put(`projetosfuncionarioobs/${id.Projetos_Funcionario_Obs_ID}`, id)
  },
  // obs com este id
  show (id) {
    return Api().get(`projetosfuncionarioobs/${id}`)
  },
  // projeto funcionario obs com este id
  projetofuncobs (id) {
    return Api().get(`projetosfuncionarioobsp/${id}`)
  }
}
