<template>
  <v-app id="inspire">
    <v-app-bar v-if="$store.state.isUserLoggedIn" :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title style="width: 300px;">{{$store.state.user.Usuario}}</v-app-bar-title>
    </v-app-bar>
    <v-app-bar v-else :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" app dark>
      <v-app-bar-title style="width: 300px;">Aplicação Tarefas</v-app-bar-title>
    </v-app-bar>
    <v-navigation-drawer v-if="$store.state.isUserLoggedIn" :clipped="$vuetify.breakpoint.lgAndUp" app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">Aplicação Tarefas</v-list-item-title>
          <v-list-item-subtitle>Infordio</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item link to="funcionariosestado">
          <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Funcionarios Estado</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="clientes">
          <v-list-item-icon><v-icon>mdi-format-list-numbered</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Clientes</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="contascorrente">
          <v-list-item-icon><v-icon>mdi-list-box-outline</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Contas Corrente</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="calendario">
          <v-list-item-icon><v-icon>mdi-calendar-month</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Calendario</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="tarefasporconcluir">
          <v-list-item-icon><v-icon>mdi-note</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Tarefas por concluir</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="marcacoesporconcluir">
          <v-list-item-icon><v-icon>mdi-post</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Marcações por concluir</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="projetosporconcluir">
          <v-list-item-icon><v-icon>mdi-file-star</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Projetos por concluir</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="tickets">
          <v-list-item-icon><v-icon>mdi-ticket</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Tickets</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title @click="logout">Logout</v-list-item-title></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
    <v-footer color="primary lighten-1" padless fixed>
      <v-row v-if="$store.state.isUserLoggedIn" justify="center" class="text-center" no-gutters>
        <v-btn color="white" text rounded class="my-2" to="home">
          <v-icon color="white darken-2">mdi-home</v-icon>
          <div>Home</div>
        </v-btn>
        <v-btn color="white" text rounded class="my-2" to="criartarefas">
          <v-icon color="white darken-2">mdi-note</v-icon>
          <div>Tarefa</div>
        </v-btn>
        <v-btn color="white" text rounded class="my-2" to="criarmarcacoes">
          <v-icon color="white darken-2">mdi-post</v-icon>
          <div>Marcação</div>
        </v-btn>
        <v-btn color="white" text rounded class="my-2" to="criarprojetos">
          <v-icon color="white darken-2">mdi-file-star</v-icon>
          <div>Projeto</div>
        </v-btn>
        <!--
        <v-btn color="white" text rounded class="my-2">
          <v-icon color="white darken-2">mdi-timer</v-icon>
          <div>Tempo</div>
        </v-btn>
        -->
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  async mounted () {
  },
  data: () => ({
    drawer: false
  }),
  methods: {
    logout () {
      this.$store.dispatch('setToken', null)
      this.$store.dispatch('setUser', null)
      this.$router.push({
        name: 'mylogin'
      })
      location.reload()
    }
  },
}
</script>
<style>
/*CSS Página*/
.v-application a{
  color: black !important;
  text-decoration: none;
}
.v-app-bar-title__content{
  width: 250px !important;
}
footer .v-btn__content{
  display: block;
}
footer .v-btn {
  font-size: 10px !important;
}
footer .v-btn:before {
  background-color: unset;
}
footer {
  height: 53px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}
/*CSS Global*/
.w-100{
  width: 100% !important;
}
main {
  padding-bottom: 55px !important;
}
.p-0{
  padding: 0px !important;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.pt-0 {
  padding-top: 0px;
}
.pl-5 {
  padding-left: 3rem !important;
}
.m-2 {
  margin: 0.25rem !important;
}
span {
  color: black;
}
.textwhite {
  color: white !important;
}
label {
  color: black;
  font-size: 17px;
}
.editr {
    background: rgba(0,0,0,.06);
    border-radius: 5px;
    border: 1px solid;
    color: rgba(0,0,0,.26);
    font-size: 16px;
}
.editr--content {
  color: black !important;
}
.theme--light.v-input--is-disabled, .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
  color: black !important;
}
.theme--light.v-label--is-disabled {
  color: black !important;
}
.theme--light.v-label {
  color: black !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 0px !important;
}
.vw-btn-image, .vw-btn-removeFormat, .vw-btn-table, .vw-btn-code, .vw-btn-link, .vw-btn-separator, .vw-btn-justifyLeft, .vw-btn-justifyCenter, .vw-btn-justifyRight, .vw-btn-headings{
  display: none !important;
}
.back-obs {
  background-color: #00e1ffab;
}
.back-pref {
  background-color: green;
  color: white;
}
.v-data-table>.v-data-table__wrapper .v-data-table__mobile-table-row{
  border: 1px solid black;
}
.myTabela{
  border: 1px solid black;
}
.myTabela div {
  padding: 5px 16px;
  height: auto;
  min-height: 48px;
}
.myTabela span {
  font-size: .875rem;
}
.myTabela .titulo {
  font-weight: 600;
}
.tirarfundobotao{
  box-shadow: unset !important;
  background-color: unset !important;
}
.tirarfundobotao:hover:before{
  opacity: 0 !important;
}
/*css tempos*/
.botoestemposcriar button, .botoestemposcriar i{
  font-size: 50px !important;
}
.botoestemposeditar button, .botoestemposeditar i{
  font-size: 50px !important;
}
.botoestemposcriar button::after{
  background-color: unset !important;
}
.botoestemposeditar button::after{
  background-color: unset !important;
}
.botoestemposcriar .mdi-play-circle {
  color: green !important;
}
.botoestemposcriar .mdi-stop-circle {
  color: grey !important;
}
.botoestemposeditar .mdi-play-circle {
  color: grey !important;
}
.botoestemposeditar .mdi-stop-circle {
  color: red !important;
}
.retangulotempofim {
  background-color: grey;
  height: 50px;
  line-height: 50px;
}
.retangulotempofim span {
  color: white;
  font-weight: bold;
  font-size: 30px;
}
.tamanho_tabelas_home_scroll{
  height: 250px;
  overflow: auto;
  display: block;
}
</style>
