import Api from '@/services/Api'

export default {
  // todos os clientes
  index () {
    return Api().get('clientes')
  },
  // cliente com este id
  show (id) {
    return Api().get(`cliente/${id}`)
  },
  // cliente com este nif
  clientenif (nif) {
    return Api().get(`clientenif/${nif}`)
  },
  // clientes com estes ids
  clientesids (clientesid) {
    return Api().get('clientesids',{
      params: {
        clientesid
      }
    })
  }
}
