import Api from '@/services/Api'

export default {
  // todas as marcações
  index () {
    return Api().get('marcacoesphc')
  },
  // ver marcação phc com este id
  show (id) {
    return Api().get(`marcacaophc/${id}`)
  },
  // criar marcação phc
  post (elementos) {
    return Api().post('marcacoesphc', elementos)
  },
  // editar marcação phc
  updateMxid (idd) {
    return Api().put(`marcacoesphcC/${idd.mxid}`, idd)
  },
  // apagar marcação phc
  delete (iddd) {
    return Api().delete(`marcacoesphc/${iddd}`, iddd)
  },
  // maior nkeyid
  marcacaophcmaiornkeyid () {
    return Api().get('marcacaophcmaiornkeyid')
  },
  // maior mxid
  marcacaophcmaiormxid () {
    return Api().get('marcacaophcmaiormxid')
  },
  // marcações phc por esta data
  datamarcacoesfuncionariophc (dataselecionada) {
    return Api().get('datamarcacoesfuncionariophc',{ 
      params: {
        dataselecionada
      }
    })
  },
  // marcações phc por esta data e este tecnico
  userdatamarcacoesfuncionariophc (elemprecephc) {
    return Api().get('userdatamarcacoesfuncionariophc',{ 
      params: {
        tecnicophcselecionado: elemprecephc.tecnicophcslec,
        dataselecionada: elemprecephc.dataselc
      }
    })
  },
  // ultima marcacao phc
  ultimamarcacaophc () {
    return Api().get('ultimamarcacaophc')
  },
}
