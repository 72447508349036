<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
        <v-card>
          <v-list-group :value="true">
            <template v-slot:activator>
              <v-list-item-title><b>As tuas tarefas por concluir</b></v-list-item-title>
            </template>
            <v-col cols="12 text-right" id="botaoup">
              <v-btn color="blue" class="ma-2 white--text" @click="tarefasparadown">
                Data<v-icon right dark>mdi-chevron-up</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12 text-right d-none" id="botaodown">
              <v-btn color="blue" class="ma-2 white--text" @click="tarefasparaup">
                Data<v-icon right dark>mdi-chevron-down</v-icon>
              </v-btn>
            </v-col>
            
            <v-data-table hide-default-footer class="elevation-1 tamanho_tabelas_home_scroll" :items-per-page="-1" :headers="headersTarefas" :items="usertarefas">
              <template v-slot:[`item.cliente`]="{ item }">
                <div v-for="cln in clientes" :key="cln.no">
                  <span v-if="cln.no == item.tarefa_cliente">{{ cln.nome }}</span>
                </div>
              </template>
              <template v-slot:[`item.descricao`]="{ item }">
                <span v-html="item.tarefa_descricao.substring(0,60)"></span>
              </template>
              <template v-slot:[`item.dataregisto`]="{ item }">
                {{ formatDate(item.tarefa_data_registo) }}
              </template>
              <template v-slot:[`item.intervenientes`]="{ item }">
                <div v-for="tareffunc in item.Tarefas_Funcionarios" :key="tareffunc.tarefas_funcionario_id">{{ tareffunc.Funcionario.funcionario_nome }}</div>
              </template>
              <template v-slot:[`item.urgencia`]="{ item }">
                <v-chip :color="getColor(item.Tarefa_Grau_Urgencia)" dark>{{ item.Tarefa_Grau_Urgencia }}</v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="green" class="m-2" title="Editar tarefa" @click="navigateTo({name: 'editartarefas', params:{idEnviado: item.tarefa_id}})">mdi-pencil</v-icon>
                <v-icon color="red" class="m-2" title="Apagar tarefa" @click="apagarTarefa(item.tarefa_id)">mdi-delete</v-icon>
                <!--
                <v-icon v-if="item.tarefa_concluida != 1" class="m-2" color="red">mdi-close</v-icon>
                <v-icon v-else class="m-2" color="green">mdi-check</v-icon>
                -->
              </template>
            </v-data-table>
          </v-list-group>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
        <v-card>
          <v-list-group :value="true">
            <template v-slot:activator>
              <v-list-item-title><b>As tuas Marcações por concluir</b></v-list-item-title>
            </template>
            <v-col cols="12 text-right" id="botaoup">
              <v-btn color="blue" class="ma-2 white--text" @click="marcacoesparadown">
                Data<v-icon right dark>mdi-chevron-up</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12 text-right d-none" id="botaodown">
              <v-btn color="blue" class="ma-2 white--text" @click="marcacoesparaup">
                Data<v-icon right dark>mdi-chevron-down</v-icon>
              </v-btn>
            </v-col>
            <v-data-table hide-default-footer class="elevation-1 tamanho_tabelas_home_scroll" :items-per-page="-1" :headers="headersMarcacoes" :items="usermarcacoes">
              <template v-slot:[`item.data`]="{ item }">
                <div v-for="marcfunc in item.Marcacoes_Funcionarios" :key="marcfunc.Marcacoes_funcionario_id">{{ formatDate(marcfunc.Marcacoes_funcionario_data_inicio) }}</div>
              </template>
              <template v-slot:[`item.inicio`]="{ item }">
                <div v-for="marcfunc in item.Marcacoes_Funcionarios" :key="marcfunc.Marcacoes_funcionario_id">{{ formatDate2(marcfunc.Marcacoes_funcionario_data_inicio_total) }}</div>
              </template>
              <template v-slot:[`item.fim`]="{ item }">
                <div v-for="marcfunc in item.Marcacoes_Funcionarios" :key="marcfunc.Marcacoes_funcionario_id">{{ formatDate2(marcfunc.Marcacoes_funcionario_data_fim_total) }}</div>
              </template>
              <template v-slot:[`item.cliente`]="{ item }">
                <div v-for="cln in clientes" :key="cln.no">
                  <span v-if="cln.no == item.Marcacoes_cliente">{{ cln.nome }}</span>
                </div>
              </template>
              <template v-slot:[`item.marcacao`]="{ item }">
                <span v-html="item.Marcacoes_descricao.substring(0,60)"></span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="green" class="m-2" title="Editar marcação" @click="navigateTo({name: 'editarmarcacoes', params:{idEnviado2: item.Marcacoes_id}})">mdi-pencil</v-icon>
                <v-icon color="red" class="m-2" title="Apagar marcação" @click="apagarMarcacao(item.Marcacoes_id)">mdi-delete</v-icon>
                <!--
                <v-icon v-if="item.Marcacoes_concluida != 1" class="m-2" color="red">mdi-close</v-icon>
                <v-icon v-else class="m-2" color="green">mdi-check</v-icon>
                -->
              </template>
            </v-data-table>
          </v-list-group>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
        <v-card>
          <v-list-group :value="true">
            <template v-slot:activator>
              <v-list-item-title><b>Os teus Projetos por concluir</b></v-list-item-title>
            </template>
            <v-col cols="12 text-right" id="botaoup">
              <v-btn color="blue" class="ma-2 white--text" @click="projetosparadown">
                Data<v-icon right dark>mdi-chevron-up</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12 text-right d-none" id="botaodown">
              <v-btn color="blue" class="ma-2 white--text" @click="projetosparaup">
                Data<v-icon right dark>mdi-chevron-down</v-icon>
              </v-btn>
            </v-col>
            
            <v-data-table hide-default-footer class="elevation-1 tamanho_tabelas_home_scroll" :items-per-page="-1" :headers="headersProjetos" :items="userprojetos">
              <template v-slot:[`item.projeto`]="{ item }">
                  <span>{{ item.Projetos_ano }}/{{ item.Projetos_numero }}</span>
                </template>
                <template v-slot:[`item.cliente`]="{ item }">
                  <div v-for="cln in clientes" :key="cln.no">
                    <span v-if="cln.no == item.Projetos_cliente">{{ cln.nome }}</span>
                  </div>
                </template>
                <template v-slot:[`item.descricao`]="{ item }">
                  <span v-html="item.Projetos_descricao.substring(0,60)"></span>
                </template>
                <template v-slot:[`item.dataregisto`]="{ item }">
                  {{ formatDate(item.Projetos_data_registo) }}
                </template>
                <template v-slot:[`item.intervenientes`]="{ item }">
                <div v-for="projectfunc in item.Projetos_Funcionarios" :key="projectfunc.Projetos_funcionario_id">{{ projectfunc.Funcionario.funcionario_nome }}</div>
              </template>
                <template v-slot:[`item.estado`]="{ item }">
                  <div v-for="estados in estados" :key="estados.Estado_id">
                    <span v-if="estados.Estado_id == item.Projetos_estado">{{ estados.Estado_descricao }}</span>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon color="blue" class="m-2" title="Resumo do projeto" @click="navigateTo({name: 'resumosprojetos', params:{idEnviado5: item.Projetos_id}})">mdi-file-find</v-icon>
                  <v-icon color="green" class="m-2" title="Editar projeto" @click="navigateTo({name: 'editarprojetos', params:{idEnviado5: item.Projetos_id}})">mdi-pencil</v-icon>
                  <v-icon color="red" class="m-2" title="Apagar projeto" @click="apagarProjeto(item.Projetos_id)">mdi-delete</v-icon>
                  <!--
                  <v-icon v-if="item.Projetos_concluida != 1" class="m-2" color="red">mdi-close</v-icon>
                  <v-icon v-else class="m-2" color="green">mdi-check</v-icon>
                  -->
                </template>
            </v-data-table>
          </v-list-group>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
        <v-card>
          <v-list-group :value="true">
            <template v-slot:activator>
              <v-list-item-title><b>Os Teus Tempos por concluir</b></v-list-item-title>
            </template>
            <v-data-table hide-default-footer class="elevation-1 tamanho_tabelas_home_scroll" :items-per-page="-1" :headers="headersTempos" :items="tempoAgoraFuncionario">
              <template v-slot:[`item.funcionario`]="{ item }">
                {{ item.Tempos_Agora_Funcionario }}
              </template>
              <template v-slot:[`item.cliente`]="{ item }">
                {{ item.Tempos_Agora_Cliente }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="green" class="m-2" title="Editar tempo" @click="navigateTo({name: 'editartempos', params:{idEnviado3: item.Tempos_Agora_ID, idEnviado4: item.Tempos_Agora_Tempos_ID, idEnviado10: item.Tempos_Agora_Cliente_numero}})">mdi-pencil</v-icon>
              <v-icon color="red" class="m-2" title="Apagar tempo" @click="apagarTempo(item.Tempos_Agora_ID)">mdi-delete</v-icon>
            </template>
            </v-data-table>
          </v-list-group>
        </v-card>
      </v-col>
      <!-- Dialog apagar tarefa -->
      <v-dialog v-model="dialogApagarTarefa">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogApagarTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer apagar a tarefa?</span>
          </v-col>
          <v-col cols="12">
            <v-alert v-model="alertTarefaApagada" dismissible type="success">Tarefa apagada com sucesso</v-alert>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="confirmarApagarTarefa">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar marcacao -->
      <v-dialog v-model="dialogApagarMarcacao">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogApagarMarcacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer apagar a marcação?</span>
          </v-col>
          <v-col cols="12">
            <v-alert v-model="alertMarcacaoApagada" dismissible type="success">Marcação apagada com sucesso</v-alert>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="confirmarApagarMarcacao">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar projeto -->
      <v-dialog v-model="dialogApagarProjeto">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogApagarProjeto = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer apagar o projeto?</span>
          </v-col>
          <v-col cols="12">
            <v-alert v-model="alertProjetoApagada" dismissible type="success">Projeto apagado com sucesso</v-alert>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="confirmarApagarProjeto">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar tempo -->
      <v-dialog v-model="dialogApagarTempo">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogApagarTempo = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer apagar o tempo?</span>
          </v-col>
          <v-col cols="12">
            <v-alert v-model="alertTempoApagada" dismissible type="success">Tempo apagado com sucesso</v-alert>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="confirmarApagarTempo">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import TarefasService from '@/services/TarefasService'
import MarcacoesService from '@/services/MarcacoesService'
import ProjetosService from '@/services/ProjetosService'
import TemposAgoraService from '@/services/TemposAgoraService'
import FuncionarioService from '@/services/FuncionarioService'
import ClientesService from '@/services/ClientesService'
import EstadosService from '@/services/EstadosService'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.usertarefas = (await TarefasService.usertarefasasc(this.$store.state.user.funcionario)).data
    this.usermarcacoes = (await MarcacoesService.usermarcacoesasc(this.$store.state.user.funcionario)).data
    this.userprojetos = (await ProjetosService.userprojetosasc(this.$store.state.user.funcionario)).data
    this.funcionario = (await FuncionarioService.show(this.$store.state.user.funcionario)).data
    this.estados = (await EstadosService.index()).data
    var elemfunc = {nomefunc: this.$store.state.user.Usuario}
    this.tempoAgoraFuncionario = (await TemposAgoraService.tempoagorafunc(elemfunc)).data
    //nomes clientes
    var ola = []
    //nome clientes tarefas
    for (var t = 0; t < this.usertarefas.length; t++) {
        ola.push(this.usertarefas[t].tarefa_cliente)
    }
    //nome clientes marcações
    for (var m = 0; m < this.usermarcacoes.length; m++) {
        ola.push(this.usermarcacoes[m].Marcacoes_cliente)
    }
    //nome clientes projetos
    for (var p = 0; p < this.userprojetos.length; p++) {
        ola.push(this.userprojetos[p].Projetos_cliente)
    }
    this.clientesid = [...new Set(ola)]
    this.clientes = (await ClientesService.clientesids(this.clientesid)).data
  },
  data () {
    return {
      usertarefas: [],
      usermarcacoes: [],
      userprojetos: [],
      tempoAgoraFuncionario: [],
      funcionario: [],
      clientesid: [],
      clientes: [],
      estados: [],
      headersTarefas: [
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Data Registo", value: "dataregisto", sortable: false },
        { text: "Intervenientes", value: "intervenientes", sortable: false },
        { text: "Urgência", value: "urgencia", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      headersMarcacoes: [
        { text: "Data", value: "data", sortable: false },
        { text: "Inicio", value: "inicio", sortable: false },
        { text: "Fim", value: "fim", sortable: false },
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Marcação", value: "marcacao", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      headersProjetos: [
        { text: "Projeto", value: "projeto", sortable: false },
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Data Registo", value: "dataregisto", sortable: false },
        { text: "Intervenientes", value: "intervenientes", sortable: false },
        { text: "Estado", value: "estado", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersTempos: [
        { text: "Funcionario", value: "funcionario", sortable: false },
        { text: "Cliente", value: "cliente", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      dialogApagarTarefa: false,
      alertTarefaApagada: false,
      dialogApagarMarcacao: false,
      alertMarcacaoApagada: false,
      dialogApagarProjeto: false,
      alertProjetoApagada: false,
      dialogApagarTempo: false,
      alertTempoApagada: false,
      idtarefaglobal: '',
      idmarcacaoglobal: '',
      idprojetoglobal: '',
      idtempoglobal: ''
    }
  },
  methods: {
    // formatar data
    formatDate (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    // formatar data2
    formatDate2 (date) {
      const options = {hour: 'numeric', minute: 'numeric'}
      return new Date(date).toLocaleTimeString('pt-PT', options)
    },
    //cores da urgência
    getColor (urgencia) {
      if (urgencia === 'Média') return 'yellow'
      else if (urgencia === 'Baixa') return 'green'
      else if (urgencia === 'Alta') return 'red'
      else return 'white'
    },
    navigateTo (route) {
      this.$store.dispatch('setTeste', {     
        idtempoagora: route.params.idEnviado3,
        idtempo: route.params.idEnviado4,
        idcliente: route.params.idEnviado10
      })
      this.$store.dispatch('setTarefa', {  
        idtarefa: route.params.idEnviado,
      })
      this.$store.dispatch('setMarcacao', {  
        idmarcacao: route.params.idEnviado2,
      })
      this.$store.dispatch('setProjeto', {  
        idprojeto: route.params.idEnviado5
      })
      this.$router.push(route)
    },
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    // ir buscar Tarefas por ordem decrecente
    async tarefasparadown () {
      this.usertarefas = (await TarefasService.usertarefasdesc(this.$store.state.user.funcionario)).data
      var element = document.getElementById("botaoup")
      element.classList.add("d-none")
      var element2 = document.getElementById("botaodown")
      element2.classList.remove("d-none")
    },
    // ir buscar Tarefas por ordem crescente
    async tarefasparaup () {
      this.usertarefas = (await TarefasService.usertarefasasc(this.$store.state.user.funcionario)).data
      var element3 = document.getElementById("botaoup")
      element3.classList.remove("d-none")
      var element4 = document.getElementById("botaodown")
      element4.classList.add("d-none")
    },
    //apagar tarefa
    async apagarTarefa (id) {
      this.dialogApagarTarefa = true
      this.idtarefaglobal = id
    },
    //confirmar apagar tarefa
    async confirmarApagarTarefa () {
      this.alertTarefaApagada = true
      document.location.reload(true)
      await TarefasService.delete(this.idtarefaglobal)
    },
    //-------------------------------------------marcações------------------------------//
    //-------------------------------------------marcações------------------------------//
    //-------------------------------------------marcações------------------------------//
    // ir buscar marcações por ordem descendente
    async marcacoesparadown () {
      this.usermarcacoes = (await MarcacoesService.usermarcacoesdesc(this.$store.state.user.funcionario)).data
      var element = document.getElementById("botaoup")
      element.classList.add("d-none")
      var element2 = document.getElementById("botaodown")
      element2.classList.remove("d-none")
    },
    // ir buscar marcações por ordem crecente
    async marcacoesparaup () {
      this.usermarcacoes = (await MarcacoesService.usermarcacoesasc(this.$store.state.user.funcionario)).data
      var element3 = document.getElementById("botaoup")
      element3.classList.remove("d-none")
      var element4 = document.getElementById("botaodown")
      element4.classList.add("d-none")
    },
    //apagar marcação
    async apagarMarcacao (id) {
      this.dialogApagarMarcacao = true
      this.idmarcacaoglobal = id
    },
    //confirmar apagar marcação
    async confirmarApagarMarcacao () {
      this.alertMarcacaoApagada = true
      document.location.reload(true)
      await MarcacoesService.delete(this.idmarcacaoglobal)
    },
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    // ir buscar Projetos por ordem decrecente
    async projetosparadown () {
      this.userprojetos = (await ProjetosService.userprojetosdesc(this.$store.state.user.funcionario)).data
      var element = document.getElementById("botaoup")
      element.classList.add("d-none")
      var element2 = document.getElementById("botaodown")
      element2.classList.remove("d-none")
    },
    // ir buscar Projetos por ordem crescente
    async projetosparaup () {
      this.userprojetos = (await ProjetosService.userprojetosasc(this.$store.state.user.funcionario)).data
      var element3 = document.getElementById("botaoup")
      element3.classList.remove("d-none")
      var element4 = document.getElementById("botaodown")
      element4.classList.add("d-none")
    },
    //apagar projeto
    async apagarProjeto (id) {
      this.dialogApagarProjeto = true
      this.idprojetoglobal = id
    },
    //confirmar apagar projeto
    async confirmarApagarProjeto () {
      this.alertProjetoApagada = true
      document.location.reload(true)
      await ProjetosService.delete(this.idprojetoglobal)
    },
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    //apagar tempo
    async apagarTempo (id) {
      this.dialogApagarTempo = true
      this.idtempoglobal = id
    },
    //confirmar apagar tempo
    async confirmarApagarTempo () {
      this.alertTempoApagada = true
      if (this.tempoAgoraFuncionario.length == 1) {
        this.funcionario.funcionario_estado = 'Livre'
        this.funcionario.funcionario_Deslocacao = 0
        this.funcionario.funcionario_Remoto = 0
        this.funcionario.funcionario_telefone = 0
      }
      await FuncionarioService.put(this.funcionario)
      setTimeout(() => {
        document.location.reload(true)
      }, 500);
      await TemposAgoraService.delete(this.idtempoglobal)
    }
  }
}
</script>
<style>
</style>