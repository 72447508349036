import Api from '@/services/Api'

export default {
  index () {
    return Api().get('marcacoesfuncionarioobs')
  },
  // criar obs
  post (elementos) {
    return Api().post('marcacoesfuncionarioobs', elementos)
  },
  // apagar obs
  delete (id) {
    return Api().delete(`marcacoesfuncionarioobs/${id}`, id)
  },
  // guardar obs por este id
  put (id) {
    return Api().put(`marcacoesfuncionarioobs/${id.Marcacoes_Funcionario_Obs_ID}`, id)
  },
  // obs com este id
  show (id) {
    return Api().get(`marcacoesfuncionarioobs/${id}`)
  },
  // marcacoes funcionario obs com este id
  marcacaofuncobs (id) {
    return Api().get(`marcacoesfuncionarioobsp/${id}`)
  },
}
