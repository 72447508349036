<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Criar Tempo</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12 text-center">
                    <label>Adicionar Tempo a <b>Tarefa</b> do cliente <b>{{ this.cliente.nome }}</b>, Funcionario <b>{{ $store.state.user.Usuario }}</b></label>
                  </v-col>
                  <v-col cols="12" class="botoestemposcriar text-center">
                    <v-icon @click="criarTempos">mdi-play-circle</v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import TarefasFuncionarioService from '@/services/TarefasFuncionarioService'
import FuncionarioService from '@/services/FuncionarioService'
import ClientesService from '@/services/ClientesService'
import TemposAgoraService from '@/services/TemposAgoraService'
import TemposService from '@/services/TemposService'
import store from '@/store/store'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
      this.tarefasfuncionario = (await TarefasFuncionarioService.show(this.$store.state.teste.idtarefafuncionario)).data
      this.cliente = (await ClientesService.show(this.tarefasfuncionario.Tarefa.tarefa_cliente)).data
      this.funcionario = (await FuncionarioService.show(this.$store.state.user.funcionario)).data
  },
  data () {
    return {
      funcionario: [],
      cliente: [],
      ultimotempo: [],
      tarefasfuncionario: [],
      createTemposAgora: {
        Tempos_Agora_Tipo: '',
        Tempos_Agora_numero: '',
        Tempos_Agora_numero_funcionario: '',
        Tempos_Agora_Inicio: new Date(Date.now()).toISOString(),
        Tempos_Agora_Descricao: null,
        Tempos_Agora_Cliente: '',
        Tempos_Agora_Funcionario: this.$store.state.user.Usuario,
        Tempos_Agora_Cliente_numero: '',
        Tempos_Agora_Tempos_ID: ''
      },
      createTempos: {
        Tempos_Tipo: '',
        Tempos_numero: '',
        Tempos_numero_funcionario: '',
        Tempos_gasto_numero: '0',
        Tempos_Cliente: '',
        Tempos_Funcionario: this.$store.state.user.Usuario,
        Tempos_Cliente_numero: '',
        Tempos_Tempos_Agora_ID: ''
      },
    }
  },
  methods: {
    async criarTempos () {
      //  funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario
      //  funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario
      //  funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario funcionario
      //  funcionario update
      this.funcionario.funcionario_estado = 'Ocupado'
      await FuncionarioService.put(this.funcionario)
      //  tempos agora tempos agora tempos agora tempos agora empos agora tempos agora tempos agora tempos agora empos agora tempos agora
      //  tempos agora tempos agora tempos agora tempos agora empos agora tempos agora tempos agora tempos agora empos agora tempos agora
      //  tempos agora tempos agora tempos agora tempos agora empos agora tempos agora tempos agora tempos agora empos agora tempos agora
      // tempos agora
      this.createTemposAgora.Tempos_Agora_Tipo = 'Tarefa'
      this.createTemposAgora.Tempos_Agora_numero = this.tarefasfuncionario.Tarefa.tarefa_id
      this.createTemposAgora.Tempos_Agora_numero_funcionario = this.tarefasfuncionario.tarefas_funcionario_id
      this.createTemposAgora.Tempos_Agora_Descricao = this.tarefasfuncionario.Tarefa.tarefa_descricao
      // tempos
      this.createTempos.Tempos_Tipo = 'Tarefa'
      this.createTempos.Tempos_numero = this.tarefasfuncionario.Tarefa.tarefa_id
      this.createTempos.Tempos_numero_funcionario = this.tarefasfuncionario.tarefas_funcionario_id
      this.createTemposAgora.Tempos_Agora_Cliente = this.cliente.nome
      this.createTemposAgora.Tempos_Agora_Cliente_numero = this.cliente.no
      this.ultimotempo = (await TemposService.ultimotempo()).data
      this.createTemposAgora.Tempos_Agora_Tempos_ID = this.ultimotempo.Tempos_ID + 1
      await TemposAgoraService.post(this.createTemposAgora)
      //  tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos
      //  tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos
      //  tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos tempos
      this.createTempos.Tempos_Cliente = this.cliente.nome
      this.createTempos.Tempos_Cliente_numero = this.cliente.no
      this.ultimotempoagora = (await TemposAgoraService.ultimotempoagora()).data
      this.createTempos.Tempos_Tempos_Agora_ID = this.ultimotempoagora.Tempos_Agora_ID
      await TemposService.post(this.createTempos)
      this.$store.dispatch('setTeste', {
        idtempoagora: this.createTempos.Tempos_Tempos_Agora_ID,
        idtempo: this.createTemposAgora.Tempos_Agora_Tempos_ID,
        idcliente: this.createTempos.Tempos_Cliente_numero
      })
      this.$router.push({
        path: `/editartempos`
      })
    }
  }
}
</script>
<style>
</style>