<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn">
      <v-flex>
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Contas Corrente</v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <v-autocomplete @change="getContasCorrente" :items="clientes" item-text="nome" item-value="no" clearable filled outlined label="Cliente"></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-toolbar-title class="pb-2 text-right">Total: <b><span id="totalcontascorrente"></span></b></v-toolbar-title>
            <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersContasCorrente" :items="contascorrente">
                <template v-slot:[`item.documento`]="{ item }">
                    {{ item.cmdesc }} {{item.nrdoc}}
                </template>
                <template v-slot:[`item.datadoc`]="{ item }">
                    {{ formatDate(item.datalc) }}
                </template>
                <template v-slot:[`item.debitovalordoc`]="{ item }">
                    {{ parseFloat(item.edeb).toFixed(2) }}€
                </template>
                <template v-slot:[`item.debitovalorliq`]="{ item }">
                    {{ parseFloat(item.edebf).toFixed(2) }}€
                </template>
                <template v-slot:[`item.creditovalordoc`]="{ item }">
                    {{ parseFloat(item.ecred).toFixed(2) }}€
                </template>
                <template v-slot:[`item.creditovalorliq`]="{ item }">
                    {{ parseFloat(item.ecredf).toFixed(2) }}€
                </template>
                <template v-slot:[`item.total`]="{ item }">
                    <span v-if="((item.edeb) - (item.edebf))-((item.ecred) - (item.ecredf)) != 0" class="corvermelho">{{ parseFloat(((item.edeb) - (item.edebf))-((item.ecred) - (item.ecredf))).toFixed(2) }}€</span>
                    <span v-else>{{ parseFloat(((item.edeb) - (item.edebf))-((item.ecred) - (item.ecredf))).toFixed(2) }}€</span>
                </template>
            </v-data-table>
          </v-col>
        </v-card>
      </v-flex>
    </v-layout>
  </template>
    
  <script>
  //  Importar o service necessário
  import ClientesService from '@/services/ClientesService'
  import ContasCorrenteService from '@/services/ContasCorrenteService'
  import store from '@/store/store'
  export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      }
      this.clientes = (await ClientesService.index()).data
    },
    data () {
      return {
        clientes: [],
        contascorrente: [],
        headersContasCorrente: [
          { text: "Documento", value: "documento", sortable: false },
          { text: "Data doc.", value: "datadoc", sortable: false },
          { text: "Débito Valor doc.", value: "debitovalordoc", sortable: false },
          { text: "Débito Valor liq.", value: "debitovalorliq", sortable: false },
          { text: "Crédito Valor doc.", value: "creditovalordoc", sortable: false },
          { text: "Crédito Valor doc.", value: "creditovalorliq", sortable: false },
          { text: 'Total', value: 'total', sortable: false },
        ]
      }
    },
    methods: {
        // formatar data
        formatDate (date) {
        const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return new Date(date).toLocaleDateString('pt-PT', options)
        },
        // ir buscar os clients por este id
        async getContasCorrente (idcliente) {
            this.contascorrente = (await ContasCorrenteService.show(idcliente)).data
            var total = 0
            for (var i = 0; i < this.contascorrente.length; i++) {
                total = total + (this.contascorrente[i].edeb - this.contascorrente[i].edebf)-(this.contascorrente[i].ecred - this.contascorrente[i].ecredf)
            }
            //  caixa de tempo feito
            var caixatexto00 = document.getElementById('totalcontascorrente')
            caixatexto00.innerText = (parseFloat(total).toFixed(2) + ' €')
        },
    }
}
</script>
<style>
.corvermelho {
    color: red;
}
</style>