<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Criar Tarefa</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                  <label>Clientes</label>
                  <template>
                    <v-autocomplete v-model="createTarefas.tarefa_cliente" :items="clientes" item-text="nome" item-value="no" clearable filled outlined></v-autocomplete>
                  </template>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                  <label>Quem Registou</label>
                  <v-text-field v-model="createTarefas.tarefa_quem_registou" disabled filled outlined></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                  <label>Data de Registo</label>
                  <MYdatetime type="datetime" v-model="createTarefas.tarefa_data_registo" disabled="disabled"></MYdatetime>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                  <label>Quem Pediu</label>
                  <v-text-field type="text" v-model="createTarefas.Tarefa_Quem_Pediu" clearable filled outlined></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                  <label>Pedido como</label>
                  <v-select v-model="createTarefas.Tarefa_Como_Pediu" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']" filled outlined></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8">
                  <v-row>
                    <v-col cols="12">
                      <label>Descrição</label>
                      <wysiwyg v-model="createTarefas.tarefa_descricao"/>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Urgência</label>
                      <v-select v-model="createTarefas.Tarefa_Grau_Urgencia" :items="['Alta', 'Média', 'Baixa']" filled outlined></v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Tópico</label>
                      <v-select v-model="createTarefas.tarefa_Topico" :items="['Atualização aplicação', 'Atualização contrato', 'Em desenvolvimento', 'Formação', 'Instalação', 'Orçamentos']" filled outlined></v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Estado</label>
                      <v-select v-model="createTarefas.tarefas_estado" :items="estados" item-text="Estado_descricao" item-value="Estado_id" filled outlined></v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Data de Inicio</label>
                      <MYdatetime type="date" v-model="createTarefas.tarefa_data_inicio"></MYdatetime>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Data de fim</label>
                      <MYdatetime type="date" v-model="createTarefas.tarefa_data_fim"></MYdatetime>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <label>Intervenientes</label>
                      <v-icon large color="black" @click="intervModal">mdi-plus</v-icon>
                    </v-col>
                    <v-col v-if="this.ticket.preferedEmployee != null" cols="12">
                      <v-list class="interve">
                        <v-list-item>
                          <v-list-item-content class="back-pref">
                            <v-list-item-title class="pl-5">Técnico preferência - {{ this.ticket.preferedEmployee }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <!-- Dialog Adicionar Interveniente -->
          <v-dialog v-model="dialogAdicionarInterveniente">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Adicionar Interveniente</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-list>
                      <v-list-item-group color="primary">
                        <div v-for="func in funcionarios" :key="func.funcionario_id">
                          <v-divider></v-divider>
                          <v-list-item>
                            <template>
                              <v-list-item-action>
                                <v-checkbox v-model="checkedInterv" :value="func"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{func.funcionario_nome}}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </div>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                  <v-col cols="12">
                    <v-alert v-model="alertDataFimMaior" dismissible type="error">Data de Fim menor que a de Inicio</v-alert>
                    <v-alert v-model="alertCliente" dismissible type="error">Cliente não selecionado</v-alert>
                    <v-alert v-model="alertCriada" dismissible type="success">Tarefa criada com sucesso</v-alert>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-btn color="primary" @click="CriarTarefaMarcacao({name: 'criarmarcacoes'})">Criar Marcação</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="CriarTarefa({name: 'editartarefas'})">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import TarefasService from '@/services/TarefasService'
import ClientesService from '@/services/ClientesService'
import EstadosService from '@/services/EstadosService'
import FuncionarioService from '@/services/FuncionarioService'
import TarefasFuncionarioService from '@/services/TarefasFuncionarioService'
import LogService from '@/services/LogService'
import AnexosOutrosService from '@/services/AnexosOutrosService'
import ServiceTickets from "@/services/TicketsService";
import TbEmpresaUsuariosService from "@/services/TbEmpresaUsuariosService";
import store from '@/store/store'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
      this.clientes = (await ClientesService.index()).data
      this.estados = (await EstadosService.index()).data
      this.tbempresausuarios = (await TbEmpresaUsuariosService.show()).data
      this.funcionarios = (await FuncionarioService.index()).data
      this.createTarefas.tarefa_data_inicio = null
      this.createTarefas.tarefa_data_fim = null
      this.ticket = this.$store.state.ticket.ticketitems
      if(this.ticket != '') {
        this.ticketid = this.$store.state.ticket.idTicket
        this.clientenif = (await ClientesService.clientenif(this.$store.state.ticket.ticketitems.nif)).data
        if(this.clientenif.no != undefined) {
          this.createTarefas.tarefa_cliente = this.clientenif.no
        } 
        this.createTarefas.tarefa_descricao = this.ticket.description + '<br><br><b>Empresa: ' + this.ticket.company + '<br>Contacto: ' + this.ticket.contact + '<b>'
        this.createTarefas.Tarefa_Como_Pediu = 'Ticket'
        this.createTarefas.Tarefa_Quem_Pediu = this.ticket.name
      }
      this.$store.dispatch('setTicket', {  
        ticketitems: '',
      })
  },
  data () {
    return {
      createAnexosOutros: {
        Anexos_Outros_Tipo: null,
        Anexos_Outros_Tipo_ID: null,
        Anexos_Outros_cliente: null,
        Anexos_Outros_data_registo: new Date().toISOString().split('T')[0] + ' 00:00:00',
        Anexos_Outros_quem_registou: this.$store.state.user.funcionario,
        Anexos_Outros_caminho: null,
        Anexos_Outros_Descricao: null,
        Anexos_Outros_nome: null,
      },
      createLog: {
        Log_Tabela: null,
        Log_Tipo: null,
        Log_Numero: null,
        Log_Data: new Date(Date.now()).toISOString(),
        Log_Funcionario: this.$store.state.user.funcionario,
        Log_Descricao: null,
      },
      createTarefas: {
        tarefa_cliente: null,
        tarefa_data_registo: new Date(Date.now()).toISOString(),
        tarefa_quem_registou: this.$store.state.user.Usuario,
        tarefa_descricao: '',
        tarefa_concluida: 0,
        tarefa_Topico: null,
        tarefas_estado: 1,
        Tarefa_Grau_Urgencia: 'Média',
        Tarefa_Quem_Pediu: null,
        Tarefa_Como_Pediu: null,
      },
      createTarefasFuncionario: {
        tarefas_funcionario_tarefa: null,
        tarefas_funcionario_funcionario: null,
        tarefas_funcionario_atribuido_data: new Date(Date.now()),
        tarefas_funcionario_atribuido_por: this.$store.state.user.funcionario,
      },
      clientes: [],
      estados: [],
      funcionarios: [],
      checkedInterv: [],
      tbempresausuarios: {},
      alertCriada: false,
      alertDataFimMaior: false,
      alertCliente: false,
      dialogAdicionarInterveniente: false,
      ticket: [],
      nif: null,
      tiketid: ''
    }
  },
  methods: {
    //abrir modal adicionar interveniente
    async intervModal () {
      this.dialogAdicionarInterveniente = true
    },
    async CriarTarefa (route) {
      // se o cliente estiver vazio
      if (this.createTarefas.tarefa_cliente == null) {
        this.alertCliente = true
      // se ambas as datas tiverem preenchidas vai verificar se a data de fim é maior que a data de inicio
      } else if (this.createTarefas.tarefa_data_inicio != null && this.createTarefas.tarefa_data_fim != null) {
        const datainiciopc = this.createTarefas.tarefa_data_inicio
        const datafimpc = this.createTarefas.tarefa_data_fim
        const datainiciocc = new Date(datainiciopc)
        const datafimcc = new Date(datafimpc)
        if (datainiciocc.getTime() > datafimcc.getTime()) {
          this.alertDataFimMaior = true
        } else {
          this.createTarefas.tarefa_quem_registou = this.$store.state.user.funcionario
          await TarefasService.post(this.createTarefas)
          //ir bucar a ultima tarefa adicionada
          this.ultimatarefa = (await TarefasService.ultimatarefa()).data
          // fechar ticket
          if (this.ticketid != undefined) {
            await ServiceTickets.convert(this.ticketid, 1);
            //adicionar Anexos Outros 
            if (this.ticket.TicketFiles != '') {
              for (var tf1 = 0; tf1 < this.ticket.TicketFiles.length; tf1++) {
              this.createAnexosOutros.Anexos_Outros_Tipo = "Tarefas"
              this.createAnexosOutros.Anexos_Outros_Tipo_ID = this.ultimatarefa.tarefa_id
              this.createAnexosOutros.Anexos_Outros_cliente = this.createTarefas.tarefa_cliente
              this.createAnexosOutros.Anexos_Outros_caminho = this.tbempresausuarios.Caminho_Anexo + "\\Tarefas\\" + this.createTarefas.tarefa_cliente + "\\" + this.ticket.TicketFiles[tf1].path.replace('files/', '')
              this.createAnexosOutros.Anexos_Outros_Descricao = this.ticket.TicketFiles[tf1].path.replace('files/', '')
              this.createAnexosOutros.Anexos_Outros_nome = this.ticket.TicketFiles[tf1].path.replace('files/', '')
              await AnexosOutrosService.post(this.createAnexosOutros)
              await AnexosOutrosService.postAnexoOutroTarefas(this.createAnexosOutros)
             }  
            } 
          }
          //adicionar log
          this.createLog.Log_Tabela = 'Tarefas',
          this.createLog.Log_Tipo = 'Adicionar',
          this.createLog.Log_Numero = this.ultimatarefa.tarefa_id
          this.createLog.Log_Descricao = 'Tarefa adicionada por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)
          
          //adicionar tarefa funcionario
          this.createTarefasFuncionario.tarefas_funcionario_tarefa = this.ultimatarefa.tarefa_id
          for (var i = 0; i < this.checkedInterv.length; i++) {
            this.createTarefasFuncionario.tarefas_funcionario_funcionario = this.checkedInterv[i].funcionario_id
            await TarefasFuncionarioService.post(this.createTarefasFuncionario)
            //adicionar log
            this.createLog.Log_Tabela = 'Tarefas',
            this.createLog.Log_Tipo = 'Adicionar',
            this.createLog.Log_Numero = this.ultimatarefa.tarefa_id
            this.createLog.Log_Descricao = 'Funcionário ' + this.checkedInterv[i].funcionario_nome + ' adicionado por ' + this.$store.state.user.Usuario
            await LogService.post(this.createLog)
          }
          this.alertCriada = true
          this.$store.dispatch('setTarefa', {
            idtarefa: this.ultimatarefa.tarefa_id
          })
          this.$router.push(route)
        }
      } else {
        this.createTarefas.tarefa_quem_registou = this.$store.state.user.funcionario
        await TarefasService.post(this.createTarefas)
        //ir bucar a ultima tarefa adicionada
        this.ultimatarefa = (await TarefasService.ultimatarefa()).data
        // fechar ticket
        if (this.ticketid != undefined) {
          await ServiceTickets.convert(this.ticketid, 1);
          //adicionar Anexos Outros 
          if (this.ticket.TicketFiles != '') {
            for (var tf2 = 0; tf2 < this.ticket.TicketFiles.length; tf2++) {
            this.createAnexosOutros.Anexos_Outros_Tipo = "Tarefas"
            this.createAnexosOutros.Anexos_Outros_Tipo_ID = this.ultimatarefa.tarefa_id
            this.createAnexosOutros.Anexos_Outros_cliente = this.createTarefas.tarefa_cliente
            this.createAnexosOutros.Anexos_Outros_caminho = this.tbempresausuarios.Caminho_Anexo + "\\Tarefas\\" + this.createTarefas.tarefa_cliente + "\\" + this.ticket.TicketFiles[tf2].path.replace('files/', '')
            this.createAnexosOutros.Anexos_Outros_Descricao = this.ticket.TicketFiles[tf2].path.replace('files/', '')
            this.createAnexosOutros.Anexos_Outros_nome = this.ticket.TicketFiles[tf2].path.replace('files/', '')
            await AnexosOutrosService.post(this.createAnexosOutros)
            await AnexosOutrosService.postAnexoOutroTarefas(this.createAnexosOutros)
            }  
          } 
        }

        //adicionar log
        this.createLog.Log_Tabela = 'Tarefas',
        this.createLog.Log_Tipo = 'Adicionar',
        this.createLog.Log_Numero = this.ultimatarefa.tarefa_id
        this.createLog.Log_Descricao = 'Tarefa adicionada por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
        
        //adicionar tarefa funcionario
        this.createTarefasFuncionario.tarefas_funcionario_tarefa = this.ultimatarefa.tarefa_id
        for (var ii = 0; ii < this.checkedInterv.length; ii++) {
          this.createTarefasFuncionario.tarefas_funcionario_funcionario = this.checkedInterv[ii].funcionario_id
          await TarefasFuncionarioService.post(this.createTarefasFuncionario)
          //adicionar log
          this.createLog.Log_Tabela = 'Tarefas',
          this.createLog.Log_Tipo = 'Adicionar',
          this.createLog.Log_Numero = this.ultimatarefa.tarefa_id
          this.createLog.Log_Descricao = 'Funcionário ' + this.checkedInterv[ii].funcionario_nome + ' adicionado por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)
        }
        this.alertCriada = true
        this.$store.dispatch('setTarefa', {
          idtarefa: this.ultimatarefa.tarefa_id
        })
        this.$router.push(route)
      }
    },
    async CriarTarefaMarcacao (route) {
      // se o cliente estiver vazio
      if (this.createTarefas.tarefa_cliente == null) {
        this.alertCliente = true
      // se ambas as datas tiverem preenchidas vai verificar se a data de fim é maior que a data de inicio
      } else if (this.createTarefas.tarefa_data_inicio != null && this.createTarefas.tarefa_data_fim != null) {
        const datainiciopc = this.createTarefas.tarefa_data_inicio
        const datafimpc = this.createTarefas.tarefa_data_fim
        const datainiciocc = new Date(datainiciopc)
        const datafimcc = new Date(datafimpc)
        if (datainiciocc.getTime() > datafimcc.getTime()) {
          this.alertDataFimMaior = true
        } else {
          this.createTarefas.tarefa_quem_registou = this.$store.state.user.funcionario
          await TarefasService.post(this.createTarefas)
          //ir bucar a ultima tarefa adicionada
          this.ultimatarefa = (await TarefasService.ultimatarefa()).data
          // fechar ticket
          if (this.ticketid != undefined) {
            await ServiceTickets.convert(this.ticketid, 1);
            //adicionar Anexos Outros 
            if (this.ticket.TicketFiles != '') {
              for (var tf3 = 0; tf3 < this.ticket.TicketFiles.length; tf3++) {
              this.createAnexosOutros.Anexos_Outros_Tipo = "Tarefas"
              this.createAnexosOutros.Anexos_Outros_Tipo_ID = this.ultimatarefa.tarefa_id
              this.createAnexosOutros.Anexos_Outros_cliente = this.createTarefas.tarefa_cliente
              this.createAnexosOutros.Anexos_Outros_caminho = this.tbempresausuarios.Caminho_Anexo + "\\Tarefas\\" + this.createTarefas.tarefa_cliente + "\\" + this.ticket.TicketFiles[tf3].path.replace('files/', '')
              this.createAnexosOutros.Anexos_Outros_Descricao = this.ticket.TicketFiles[tf3].path.replace('files/', '')
              this.createAnexosOutros.Anexos_Outros_nome = this.ticket.TicketFiles[tf3].path.replace('files/', '')
              await AnexosOutrosService.post(this.createAnexosOutros)
              await AnexosOutrosService.postAnexoOutroTarefas(this.createAnexosOutros)
             }  
            } 
          }
          //adicionar log
          this.createLog.Log_Tabela = 'Tarefas',
          this.createLog.Log_Tipo = 'Adicionar',
          this.createLog.Log_Numero = this.ultimatarefa.tarefa_id
          this.createLog.Log_Descricao = 'Tarefa adicionada por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)  
          //adicionar tarefa funcionario
          this.createTarefasFuncionario.tarefas_funcionario_tarefa = this.ultimatarefa.tarefa_id
          for (var i = 0; i < this.checkedInterv.length; i++) {
            this.createTarefasFuncionario.tarefas_funcionario_funcionario = this.checkedInterv[i].funcionario_id
            await TarefasFuncionarioService.post(this.createTarefasFuncionario)
            //adicionar log
            this.createLog.Log_Tabela = 'Tarefas',
            this.createLog.Log_Tipo = 'Adicionar',
            this.createLog.Log_Numero = this.ultimatarefa.tarefa_id
            this.createLog.Log_Descricao = 'Funcionário ' + this.checkedInterv[i].funcionario_nome + ' adicionado por ' + this.$store.state.user.Usuario
            await LogService.post(this.createLog)
          }
          this.alertCriada = true
          this.$store.dispatch('setTeste', {
            idtarefamarcacao: this.ultimatarefa.tarefa_id
          })
          this.$router.push(route)
        }
      } else {
        this.createTarefas.tarefa_quem_registou = this.$store.state.user.funcionario
        await TarefasService.post(this.createTarefas)
        //ir bucar a ultima tarefa adicionada
        this.ultimatarefa = (await TarefasService.ultimatarefa()).data
        // fechar ticket
        if (this.ticketid != undefined) {
          await ServiceTickets.convert(this.ticketid, 1);
          //adicionar Anexos Outros 
          if (this.ticket.TicketFiles != '') {
            for (var tf4 = 0; tf4 < this.ticket.TicketFiles.length; tf4++) {
            this.createAnexosOutros.Anexos_Outros_Tipo = "Tarefas"
            this.createAnexosOutros.Anexos_Outros_Tipo_ID = this.ultimatarefa.tarefa_id
            this.createAnexosOutros.Anexos_Outros_cliente = this.createTarefas.tarefa_cliente
            this.createAnexosOutros.Anexos_Outros_caminho = this.tbempresausuarios.Caminho_Anexo + "\\Tarefas\\" + this.createTarefas.tarefa_cliente + "\\" + this.ticket.TicketFiles[tf4].path.replace('files/', '')
            this.createAnexosOutros.Anexos_Outros_Descricao = this.ticket.TicketFiles[tf4].path.replace('files/', '')
            this.createAnexosOutros.Anexos_Outros_nome = this.ticket.TicketFiles[tf4].path.replace('files/', '')
            await AnexosOutrosService.post(this.createAnexosOutros)
            await AnexosOutrosService.postAnexoOutroTarefas(this.createAnexosOutros)
            }  
          } 
        }
        //adicionar log
        this.createLog.Log_Tabela = 'Tarefas',
        this.createLog.Log_Tipo = 'Adicionar',
        this.createLog.Log_Numero = this.ultimatarefa.tarefa_id
        this.createLog.Log_Descricao = 'Tarefa adicionada por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
        //adicionar tarefa funcionario
        this.createTarefasFuncionario.tarefas_funcionario_tarefa = this.ultimatarefa.tarefa_id
        for (var ii = 0; ii < this.checkedInterv.length; ii++) {
          this.createTarefasFuncionario.tarefas_funcionario_funcionario = this.checkedInterv[ii].funcionario_id
          await TarefasFuncionarioService.post(this.createTarefasFuncionario)
          //adicionar log
          this.createLog.Log_Tabela = 'Tarefas',
          this.createLog.Log_Tipo = 'Adicionar',
          this.createLog.Log_Numero = this.ultimatarefa.tarefa_id
          this.createLog.Log_Descricao = 'Funcionário ' + this.checkedInterv[ii].funcionario_nome + ' adicionado por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)
        }
        this.alertCriada = true
        this.$store.dispatch('setTeste', {
          idtarefamarcacao: this.ultimatarefa.tarefa_id
        })
        this.$router.push(route)
      }
    }
  }
}
</script>
<style>
</style>