<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Editar Marcação</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <label>Clientes</label>
                    <v-text-field v-model="cliente.nome" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Data de Registo</label>
                    <MYdatetime type="date" :value="editarMarcacao.Marcacoes_data_registo" disabled="disabled"></MYdatetime>
                  </v-col>
                  <v-col cols="12">
                    <label>Quem Registou</label>
                    <v-text-field v-model="editarMarcacao.Funcionario.funcionario_nome" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Descrição</label>
                    <wysiwyg v-model="editarMarcacao.Marcacoes_descricao"/>
                  </v-col>
                  <v-col cols="12">
                    <span @click="datafimigual">
                      <label>Data de Inicio</label>
                      <MYdatetime hidden-name type="date" v-model="editarMarcacao.Marcacoes_data_inicio"></MYdatetime>
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <label>Data de fim</label>
                    <MYdatetime hidden-name type="date" v-model="editarMarcacao.Marcacoes_data_fim" disabled="disabled"></MYdatetime>
                  </v-col>
                  <v-col cols="12">
                    <label>Tópico</label>
                    <v-select v-model="editarMarcacao.Marcacoes_Topico" :items="['Atualização aplicação', 'Atualização contrato', 'Em desenvolvimento', 'Formação', 'Instalação', 'Orçamentos']" filled outlined></v-select>
                  </v-col>
                  <v-col cols="12">
                    <label>Estado</label>
                    <v-text-field type="text" v-model="editarMarcacao.Marcacoes_estado" clearable filled outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Localização</label>
                    <v-text-field type="text" v-model="editarMarcacao.Marcacoes_Localizacao" clearable filled outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Quem Pediu</label>
                    <v-text-field type="text" v-model="editarMarcacao.Marcacoes_Quem_Pediu" clearable filled outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Pedido Como</label>
                    <v-select v-model="editarMarcacao.Marcacoes_Como_Pediu" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']" filled outlined></v-select>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <label>Intervenientes</label>
                    <v-icon large color="black" @click="intervModal">mdi-plus</v-icon>
                  </v-col>
                  <v-col cols="12" v-for="(mf) in editarMarcacao.Marcacoes_Funcionarios" :key="mf.Marcacoes_funcionario_id">
                    <v-list class="interve">
                      <v-list-item>
                        <v-list-item-action class="pr-3 mr-0">
                          <v-icon color="blue" @click="obsModal(mf.Marcacoes_funcionario_id)">mdi-note</v-icon>
                        </v-list-item-action>
                        <v-list-item-content v-if="mf.Marcacoes_Funcionario_Obs != 0" class="back-obs">
                          <v-list-item-title>{{mf.Funcionario.funcionario_nome}}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content v-else>
                          <v-list-item-title>{{mf.Funcionario.funcionario_nome}}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="pl-3 ml-0">
                          <v-icon color="blue" @click="enviarEmail(mf)">mdi-email-arrow-right</v-icon>
                        </v-list-item-action>
                        <span class="d-none intElementos">{{ mf.Marcacoes_funcionario_terminado }}</span>
                        <template v-if="mf.Marcacoes_funcionario_terminado == false">
                          <v-list-item-action class="pl-3">
                            <v-icon color="red" @click="falseparatrue(mf.Marcacoes_funcionario_id)">mdi-close</v-icon>
                          </v-list-item-action>
                        </template>
                        <template v-else>
                          <v-list-item-action class="pl-3">
                            <v-icon color="green" @click="trueparafalse(mf.Marcacoes_funcionario_id)">mdi-check</v-icon>
                          </v-list-item-action>
                        </template>
                        <template v-if="mf.Marcacoes_funcionario_enviado_mail == true">
                          <v-list-item-action class="pl-3">
                            <v-icon color="green" @click="QuemEnviamosEmail(mf.Marcacoes_funcionario_id)">mdi-email</v-icon>
                          </v-list-item-action>
                        </template>
                        <template v-else>
                          <v-list-item-action class="pl-3">
                            <v-icon color="white">mdi-email</v-icon>
                          </v-list-item-action>
                        </template>
                        <v-list-item-action class="pl-3">
                          <v-icon color="red" @click="apagarInterv(mf)">mdi-delete</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Data: {{formatDate(mf.Marcacoes_funcionario_data_inicio)}}</v-list-item-title>
                          <v-list-item-title>Início: {{formatDate2(mf.Marcacoes_funcionario_hora_inicio)}}</v-list-item-title>
                          <v-list-item-title>Fim: {{formatDate2(mf.Marcacoes_funcionario_hora_fim)}}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-alert v-model="alertEditada" dismissible type="success">Marcação editada com sucesso</v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <!-- Dialog Adicionar Interveniente -->
          <v-dialog v-model="dialogAdicionarInterveniente">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Adicionar Interveniente</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <span @click="gettareffuncdata">
                      <label>Data Início</label>
                      <MYdatetime type="date" v-model="createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio"></MYdatetime>
                    </span>
                  </v-col> 
                  <v-col cols="6">
                    <label>Hora Início</label>
                    <MYdatetime type="time" v-model="createMarcacoesFuncionario.Marcacoes_funcionario_hora_inicio" :hour-step="1" :minute-step="15" :min-datetime="'08'" :max-datetime="'20'"></MYdatetime>
                  </v-col>
                  <v-col cols="6">
                    <label>Data Fim</label>
                    <MYdatetime type="date" v-model="createMarcacoesFuncionario.Marcacoes_funcionario_data_fim" disabled="disabled"></MYdatetime>
                  </v-col>
                  <v-col cols="6">
                    <label>Hora Fim</label>
                    <MYdatetime type="time" v-model="createMarcacoesFuncionario.Marcacoes_funcionario_hora_fim" :hour-step="1" :minute-step="15" :min-datetime="'08'" :max-datetime="'20'"></MYdatetime>
                  </v-col>
                  <v-col cols="12">
                    <v-list>
                      <v-list-item-group color="primary">
                        <div v-for="func in funcionarios" :key="func.funcionario_id">
                          <v-divider></v-divider>
                          <v-list-item>
                            <template>
                              <v-list-item-action>
                                <v-checkbox v-model="checkedInterv" :value="func"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{func.funcionario_nome}}</v-list-item-title>
                              </v-list-item-content>
                              <div v-for="num in numerosSemRepeticao" :key="num">
                                <v-list-item-content v-if="func.funcionario_id == num">
                                  <v-list-item-title><v-btn icon><v-icon color="red" @click="marcacoesDiaModal(func)">mdi-eye</v-icon></v-btn></v-list-item-title>
                                </v-list-item-content>
                              </div>
                            </template>
                          </v-list-item>
                        </div>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                  <v-col cols="12">
                    <v-alert v-model="alertIntervenienteSelecionado" dismissible type="error">Interveniente não selecionado</v-alert>
                    <v-alert v-model="alertHoraInicioMaior" dismissible type="error">A hora de inicio é maior que a de fim</v-alert>
                    <v-alert v-model="alertIntervAdd" dismissible type="success">Interveninete adicionado com sucesso</v-alert>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="addInterveniente">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog marcações do dia -->
          <v-dialog v-model="dialogMarcacoesDia">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Marcações do dia selecionado</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogMarcacoesDia = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row>
                  <v-col cols="12" v-for="marctarefas in userdatamarcacoesfuncionariotarefas" :key="marctarefas.Marcacoes_funcionario_id">
                    <div class="myTabela">
                      <div>
                        <span>Marcação das tarefas</span>
                      </div>
                      <div>
                        <span class="titulo">Funcionario</span><br>
                        <span>{{ marctarefas.Funcionario.funcionario_nome }}</span>
                      </div>
                      <div v-for="cln in clientes" :key="cln.no">
                        <span class="titulo">Cliente</span><br>
                        <span v-if="cln.no == marctarefas.Marcaco.Marcacoes_cliente">{{ cln.nome }}</span>
                      </div>
                      <div>
                        <span class="titulo">Marcação</span><br>
                        <span v-html="marctarefas.Marcaco.Marcacoes_descricao.substring(0,60)"></span>
                      </div>
                      <div>
                        <span class="titulo">Início</span><br>
                        <span>{{ formatDate2(marctarefas.Marcacoes_funcionario_hora_inicio) }}</span>
                      </div>
                      <div>
                        <span class="titulo">Fim</span><br>
                        <span>{{ formatDate2(marctarefas.Marcacoes_funcionario_hora_fim) }}</span>
                      </div>
                    </div>
                  </v-col>  
                  <v-col cols="12" v-for="marcphc in userdatamarcacoesfuncionariophc" :key="marcphc.mxid">
                    <div class="myTabela">
                      <div>
                        <span>Marcação do phc</span>
                      </div>
                      <div>
                        <span class="titulo">Funcionario</span><br>
                        <span>{{ marcphc.tecnnm }}</span>
                      </div>
                      <div>
                        <span class="titulo">Cliente</span><br>
                        <span>{{ marcphc.clnome }}</span>
                      </div>
                      <div>
                        <span class="titulo">Marcação</span><br>
                        <span v-html="marcphc.texto.substring(0,60)"></span>
                      </div>
                      <div>
                        <span class="titulo">Início</span><br>
                        <span>{{ marcphc.hinicio }}</span>
                      </div>
                      <div>
                        <span class="titulo">Fim</span><br>
                        <span>{{ marcphc.hfim }}</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
          <!-- Dialog observações -->
          <v-dialog v-model="dialogObs">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Notas</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>          
                <v-toolbar-title><v-list-item @click="dialogObs = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-container>
                    <v-row class="mb" v-for=" obs in marcacoesfuncionarioobs" :key="obs.Marcacoes_Funcionario_Obs_ID">
                      <v-col cols="12">
                        <span>{{ obs.Funcionario.funcionario_nome }} - {{ formatDate(obs.Marcacoes_Funcionario_Obs_data) }}</span>
                        <wysiwyg v-model="obs.Marcacoes_Funcionario_Obs_Descricao"/>
                        <v-list-item-action class="ml-0">
                          <v-icon color="green" @click="editarObs(obs)">mdi-arrow-up-bold-box-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-action class="ml-0 pl-3">
                          <v-icon color="red" @click="apagarObs(obs.Marcacoes_Funcionario_Obs_ID)">mdi-delete</v-icon>
                        </v-list-item-action>
                      </v-col>
                      <v-col cols="12">
                        <v-alert v-model="alertObsEditada" dismissible type="success">Nota editada com sucesso</v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="criarObsModal">Criar Nota</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog criar observações -->
          <v-dialog v-model="dialogCriarObs">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Criar Nota</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogCriarObs = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-container>
                    <v-row class="mb">
                      <v-col cols="12">
                        <label>Interveniente</label>
                        <v-autocomplete v-model="createObservacoes.Marcacoes_Funcionario_Obs_Quem_Enviou" :items="funcionarios" item-text="funcionario_nome" item-value="funcionario_id" clearable filled outlined></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <label>Data de Registo</label>
                        <MYdatetime type="date" :value="new Date(Date.now()).toISOString()" disabled="disabled"></MYdatetime>
                      </v-col>
                      <v-col cols="12">
                        <label>Descrição</label>
                        <wysiwyg v-model="createObservacoes.Marcacoes_Funcionario_Obs_Descricao"/>
                      </v-col>
                      <v-col cols="12">
                        <v-alert v-model="alertObsCriada" dismissible type="success">Nota criada com sucesso</v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="criarObs">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog apagar obs -->
          <v-dialog v-model="dialogApagarObs">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarObs = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <span>Quer apagar a Nota?</span>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertObsApagada" dismissible type="success">Nota apagada com sucesso</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarApagarObs">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog apagar interv -->
          <v-dialog v-model="dialogApagarInterv">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarInterv = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <span>Quer apagar o Interveniente da Marcação?</span>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertintervApagado" dismissible type="success">Interveniente apagado com sucesso</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarApagarIntervTarefas">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog apagar interv phc -->
          <v-dialog v-model="dialogApagarIntervPhc">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarIntervPhc = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <span>Quer apagar a marcação do phc?</span>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertMarcacaoApagadoPhc" dismissible type="success">Marcação apagada com sucesso do phc</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarApagarIntervPhc">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog Todos Interv Terminados -->
          <v-dialog v-model="dialogTodosIntervTerminados">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogTodosIntervTerminados = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <span>Marcação concluida por todos os intervenientes. Quer dá-la como terminada?</span>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertMarcacaoTerminada" dismissible type="success">Marcação terminada com sucesso</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarMarcacaoTerminada">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog Projetos deste cliente abertos -->
          <v-dialog v-model="dialogProjetosClienteAbertos">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Projetos</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogProjetosClienteAbertos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersProjetos" :items="projetosporconcluircliente">
                    <template v-slot:[`item.projeto`]="{ item }">
                      <span>{{ item.Projetos_ano }}/{{ item.Projetos_numero }}</span>
                    </template>
                    <template v-slot:[`item.datainicio`]="{ item }">
                      <span v-if="item.Projetos_data_inicio != null">{{ formatDate(item.Projetos_data_inicio) }}</span>
                    </template>
                    <template v-slot:[`item.datafim`]="{ item }">
                      <span v-if="item.Projetos_data_fim != null">{{ formatDate(item.Projetos_data_fim) }}</span>
                    </template>
                    <template v-slot:[`item.estado`]="{ item }">
                      <div v-for="estados in estados" :key="estados.Estado_id">
                        <span v-if="estados.Estado_id == item.Projetos_estado">{{ estados.Estado_descricao }}</span>
                      </div>
                    </template>
                    <template v-slot:[`item.descricao`]="{ item }">
                      <span v-if="item.Projetos_descricao != null" v-html="item.Projetos_descricao.substring(0,100)"></span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon color="green" @click="abriarmodalconfirmaradicionarprojeto(item)">mdi-arrow-right-box</v-icon>
                    </template>
                  </v-data-table>
              </v-col>
            </v-card>
          </v-dialog>
          <!-- Dialog confirmar que é este projeto -->
          <v-dialog v-model="dialogConfirmarProjetoMarcacao">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogConfirmarProjetoMarcacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                <span>Que mesmo adicionar a marcação a este projeto?</span>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertMarcacaoProjetoAdicionada" dismissible type="success">Marcação adicionada com sucesso</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarMarcacaoProjetoAdicionada">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog confirmar enviar email -->
          <v-dialog v-model="dialogConfirmarEnviarEmail">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogConfirmarEnviarEmail = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                <span>Que mesmo enviar email?</span>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="dadosEmail.checkedEmailFuncionario" :label="mfglobal.Funcionario?.funcionario_email"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="dadosEmail.checkedEmailCliente" :label="cliente.email"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertEmailEnviado" dismissible type="success">Email enviado com sucesso</v-alert>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmarEmailEnviado">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog Ver para quem enviamos email -->
          <v-dialog v-model="dialogQuemEnviamosEmail">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogQuemEnviamosEmail = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12" v-if="editarMarcacaoFuncionarioEmail.Marcacoes_funcionario_enviado_mail != null">
                <span>Email enviado em {{ formatDate(editarMarcacaoFuncionarioEmail.Marcacoes_funcionario_enviado_mail) }} para o funcionário</span>
              </v-col>
              <v-col cols="12" v-if="editarMarcacaoFuncionarioEmailCliente.Marcacoes_funcionario_enviado_cliente_mail != null">
                <span>Email enviado em {{ formatDate(editarMarcacaoFuncionarioEmailCliente.Marcacoes_funcionario_enviado_cliente_mail_data) }} para o cliente</span>
              </v-col>
            </v-card>
          </v-dialog>
          <v-card-actions>
            <v-btn v-if="editarMarcacao.Marcacoes_Projeto == 0 || editarMarcacao.Marcacoes_Projeto == null" class="classbotaoadicionarprojeto" @click="AdicionarProjeto">Adicionar Projeto</v-btn>
            <v-btn v-else class="classbotaoeditarprojeto" @click="navigateTo({name: 'editarprojetos', params:{idEnviado10: editarMarcacao.Marcacoes_Projeto}})">{{ editarMarcacao.Marcacoes_Projeto_numero }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="EditarMarcacao">Guardar</v-btn>
          </v-card-actions>
        </v-card>
    </v-flex>
  </v-layout>
</template> 
<script>
//  Importar o service necessário
import MarcacoesFuncionarioService from '@/services/MarcacoesFuncionarioService'
import MarcacoesFuncionarioObsService from '@/services/MarcacoesFuncionarioObsService'
import MarcacoesService from '@/services/MarcacoesService'
import FuncionarioService from '@/services/FuncionarioService'
import ClientesService from '@/services/ClientesService'
import TblUsuariosService from '@/services/TblUsuariosService'
import MarcacoesPhcService from '@/services/MarcacoesPhcService'
import UtilizadoresPhcService from '@/services/UtilizadoresPhcService'
import LogService from '@/services/LogService'
import ProjetosService from '@/services/ProjetosService'
import EstadosService from '@/services/EstadosService'
import ServiceEmails from '@/services/ServiceEmails'
import store from '@/store/store'
function addZero (i) {
  if (i < 10) { i = '0' + i }
  return i
}
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.editarMarcacao = (await MarcacoesService.show(this.$store.state.marcacao.idmarcacao)).data
    this.cliente = (await ClientesService.show(this.editarMarcacao.Marcacoes_cliente)).data
    this.funcionarios = (await FuncionarioService.index()).data
    this.estados = (await EstadosService.index()).data
    if (this.editarMarcacao.Marcacoes_data_inicio == '') {
      this.editarMarcacao.Marcacoes_data_inicio = null
    }
    if (this.editarMarcacao.Marcacoes_data_fim == '') {
      this.editarMarcacao.Marcacoes_data_fim = null
    }
    // se os intervenientes tiverem todos concluidos vai colocar a tarefa como terminada
    var elemento = document.getElementsByClassName('intElementos')
    var contador = 0
    for (var i = 0; i < elemento.length; i++) {
      if (elemento[i].innerHTML == 'false') {
        contador = contador + 1
      }
    }
    if (contador == 0 && this.editarMarcacao.Marcacoes_concluida != true) {
      this.dialogTodosIntervTerminados = true
    }
  },
  data () {
    return {
      clientesid: [],
      clientes: [],
      createLog: {
        Log_Tabela: null,
        Log_Tipo: null,
        Log_Numero: null,
        Log_Data: new Date(Date.now()).toISOString(),
        Log_Funcionario: this.$store.state.user.funcionario,
        Log_Descricao: null,
      },
      editarMarcacao: {
        Marcacoes_descricao: null,
        Marcacoes_data_inicio: null,
        Marcacoes_data_fim: null,
        Marcacoes_Topico: null,
        Marcacoes_estado: null,
        Marcacoes_concluida: null,
        Marcacoes_concluida_data: null,
        Marcacoes_Localizacao: null,
        Marcacoes_Quem_Pediu: null,
        Marcacoes_Como_Pediu: null,
        Marcacoes_Projeto: null,
        Marcacoes_Projeto_numero: null
      },
      createMarcacoesFuncionario: {
        Marcacoes_funcionario_funcionario: null,
        Marcacoes_funcionario_tarefa: this.$store.state.marcacao.idmarcacao,
        Marcacoes_funcionario_data_inicio: new Date(Date.now()).toISOString(),
        Marcacoes_funcionario_data_fim: new Date(Date.now()).toISOString(),
        Marcacoes_funcionario_hora_inicio: '09',
        Marcacoes_funcionario_hora_fim: '11',
        Marcacoes_funcionario_data_inicio_total: null,
        Marcacoes_funcionario_data_fim_total: null,
        Marcacoes_funcionario_atribuido_data: new Date(Date.now()),
        Marcacoes_funcionario_atribuido_por: this.$store.state.user.funcionario,
      },
      editarMarcacaoFuncionario: {
        Marcacoes_funcionario_terminado: null,
        Marcacoes_funcionario_terminado_data: null
      },
      editarMarcacaoFuncionarioEmail: {
        Marcacoes_funcionario_enviado_mail: null,
        Marcacoes_funcionario_enviado_mail_data: null
      },
      editarMarcacaoFuncionarioEmailCliente: {
        Marcacoes_funcionario_enviado_cliente_mail: null,
        Marcacoes_funcionario_enviado_cliente_mail_data: null
      },
      createObservacoes: {
        Marcacoes_Funcionario_Obs_Marcacao: null,
        Marcacoes_Funcionario_Obs_data: new Date(Date.now()),
        Marcacoes_Funcionario_Obs_Descricao: null,
        Marcacoes_Funcionario_Obs_Quem_Enviou: this.$store.state.user.funcionario
      },
      editarMarcacaoFuncionarioobs: {
        Marcacoes_Funcionario_Obs_Descricao: null
      },
      dadosEmail: {
        cliente: null,
        clienteemail: null,
        quemenviou: null,
        texto: null,
        paraquemenviar: null,
        tipo: null,
        data: null,
        hora: null,
        checkedEmailFuncionario: true,
        checkedEmailCliente: false,
      },
      createMarcacoesPhc: {
        mxstamp: null,
        data: null,
        hinicio: null,
        clnome: null,
        inicio: null,
        fim: null,
        hfim: null,
        texto: null,
        nkeyid: null,
        ckeyid: null,
        cor: 16711680,
        coddiv: 0,
        div: null,
        tecnico: null,
        clno: null,
        vendedor: null,
        tecnnm: null,
        username: null,
        userno: null,
        clestab: 0,
        morada: null,
        local: null,
        codpost: null,
        origem: 'CL',
        ind: 0,
        vendnm: null,   
        eorigem: 'InfordioTarefas',
        tusername: '',
        tuserno: 0,
        vusername: '',
        vuserno: 0,
        ousrinis: 'EXT',
        ousrhora: null,
        usrinis: 'EXT',
        usrhora: null,
        marcada: 0,
        iscliente: 0,
        descorigem: '',
        userinis: '',
        apriv: 0,
        gcaleventid: '',
        mseriesregstamp: '',
        u_telefone: null,
        agaberta: 0,
        agocupad: 0
      },
      funcionarios: [],
      cliente: {},
      numerosSemRepeticao: [],
      checkedInterv: [],
      datafuncselecionadatarefas: [],
      datafuncselecionadaphc: [],
      userdatamarcacoesfuncionariotarefas: [],
      userdatamarcacoesfuncionariophc: [],
      marcacoesfuncionarioobs: [],
      ultimaMarcacaoFuncionario: {
        Marcacoes_PHC: null
      },
      ultimaMarcacaoPhc: [],
      idintervglobal: '',
      idmxidglobal: '',
      idmarcfunc: '',
      idobsglobal: '',
      dialogApagarInterv: false,
      dialogTodosIntervTerminados: false,
      alertObsCriada: false,
      alertObsEditada: false,
      dialogObs: false,
      dialogCriarObs: false,
      dialogApagarObs: false,
      dialogApagarIntervPhc: false,
      dialogQuemEnviamosEmail: false,
      alertMarcacaoTerminada: false,
      alertMarcacaoApagadoPhc: false,
      alertObsApagada: false,
      alertEditada: false,
      alertEmailEnviado: false,
      alertintervApagado: false,
      alertIntervAdd: false,
      alertIntervenienteSelecionado: false,
      alertHoraInicioMaior: false,
      dialogMarcacoesDia: false,
      dialogAdicionarInterveniente: false,
      dialogConfirmarEnviarEmail: false,
      headersProjetos: [
        { text: "Projeto", value: "projeto", sortable: false },
        { text: "Data Inicio", value: "datainicio", sortable: false },
        { text: "Data Fim", value: "datafim", sortable: false },
        { text: "Estado Projeto", value: "estado", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      dialogProjetosClienteAbertos: false,
      dialogConfirmarProjetoMarcacao: false,
      alertMarcacaoProjetoAdicionada: false,
      projetosporconcluircliente: [],
      idprojetoescolhido: '',
      numeroprojetoescolhido: '',
      mfglobal: ''
    }
  },
  methods: {
    // formatar data
    formatDate (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    // formatar data2
    formatDate2 (date) {
      const options = {hour: 'numeric', minute: 'numeric'}
      return new Date(date).toLocaleTimeString('pt-PT', options)
    },
    navigateTo (route) {
      this.$store.dispatch('setProjeto', {  
        idprojeto: route.params.idEnviado10
      })
      this.$router.push(route)
    },
    //-------------------------------------------marcação------------------------------//
    //-------------------------------------------marcação------------------------------//
    //-------------------------------------------marcação------------------------------//
    //se colocarmos data na marcacao inicio a de fim fica igual
    async datafimigual () {
      this.editarMarcacao.Marcacoes_data_fim = this.editarMarcacao.Marcacoes_data_inicio
    },
    async EditarMarcacao () {
      this.editarMarcacao.Marcacoes_data_fim = this.editarMarcacao.Marcacoes_data_inicio
      await MarcacoesService.put(this.editarMarcacao)
      //adicionar log
      this.createLog.Log_Tabela = 'Marcacoes',
      this.createLog.Log_Tipo = 'Editar',
      this.createLog.Log_Numero = this.$store.state.marcacao.idmarcacao
      this.createLog.Log_Descricao = 'Marcação alterada por ' + this.$store.state.user.Usuario
      await LogService.post(this.createLog)
      this.alertEditada = true
      document.location.reload(true)
    },
    //confirmar marcação terminada
    async confirmarMarcacaoTerminada () {
      this.editarMarcacao.Marcacoes_data_fim = this.editarMarcacao.Marcacoes_data_inicio
      this.editarMarcacao.Marcacoes_concluida = true
      this.editarMarcacao.Marcacoes_concluida_data = new Date(Date.now())
      await MarcacoesService.put(this.editarMarcacao)
      this.alertMarcacaoTerminada = true
      document.location.reload(true)
    },
    //-------------------------------------------marcaçãofuncionario------------------------------//
    //-------------------------------------------marcaçãofuncionario------------------------------//
    //-------------------------------------------marcaçãofuncionario------------------------------//
    //abrir modal adicionar interveniente
    async intervModal () {
      var data = this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio
      var sodata = new Date(data).toJSON().slice(0, 10)
      var datatempo = (sodata+'T00:00:00.000Z')
      var datatempophc = (sodata+' 00:00:00.000')
      if (this.editarMarcacao.Marcacoes_data_inicio != null) {
        this.datafuncselecionadatarefas = (await MarcacoesFuncionarioService.datamarcacoesfuncionariotarefas(this.editarMarcacao.Marcacoes_data_inicio)).data
        this.datafuncselecionadaphc = (await MarcacoesPhcService.datamarcacoesfuncionariophc(this.editarMarcacao.Marcacoes_data_inicio)).data
        this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio = this.editarMarcacao.Marcacoes_data_inicio
        this.createMarcacoesFuncionario.Marcacoes_funcionario_data_fim = this.editarMarcacao.Marcacoes_data_inicio
      }else {
        this.datafuncselecionadatarefas = (await MarcacoesFuncionarioService.datamarcacoesfuncionariotarefas(datatempo)).data
        this.datafuncselecionadaphc = (await MarcacoesPhcService.datamarcacoesfuncionariophc(datatempophc)).data
      }
      var ola = []
      this.usuarios = (await TblUsuariosService.index()).data
      for (var oo = 0; oo < this.datafuncselecionadatarefas.length; oo++) { 
        ola.push(this.datafuncselecionadatarefas[oo].Marcacoes_funcionario_funcionario)
      }
      for (var of = 0; of < this.datafuncselecionadaphc.length; of++) { 
        for (var off = 0; off < this.usuarios.length; off++) { 
          if (this.usuarios[off].tecnico_PHC == this.datafuncselecionadaphc[of].tecnico) {
            ola.push(this.usuarios[off].funcionario)
          }
        }
      } 
      this.numerosSemRepeticao = [...new Set(ola)]
      this.dialogAdicionarInterveniente = true
    },
    //se selecionarmos uma data inicio faz isto
    async gettareffuncdata () {
      this.datafuncselecionadatarefas = (await MarcacoesFuncionarioService.datamarcacoesfuncionariotarefas(this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio)).data
      this.datafuncselecionadaphc = (await MarcacoesPhcService.datamarcacoesfuncionariophc(this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio)).data
      this.createMarcacoesFuncionario.Marcacoes_funcionario_data_fim = this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio
      var ola = []
      this.usuarios = (await TblUsuariosService.index()).data
      for (var o = 0; o < this.datafuncselecionadatarefas.length; o++) { 
        ola.push(this.datafuncselecionadatarefas[o].Marcacoes_funcionario_funcionario)
      }
      for (var offf = 0; offf < this.datafuncselecionadaphc.length; offf++) { 
        for (var offp = 0; offp < this.usuarios.length; offp++) { 
          if (this.usuarios[offp].tecnico_PHC == this.datafuncselecionadaphc[offf].tecnico) {
            ola.push(this.usuarios[offp].funcionario)
          }
        }
      }
      this.numerosSemRepeticao = [...new Set(ola)]
    },
    //criar interveniente
    async addInterveniente () {
      var datainicioparse = Date.parse(this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_inicio)
      var datafimparse = Date.parse(this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_fim)
      // se não houver intervenientes selecionados
      if (this.checkedInterv == '') {
        this.alertIntervenienteSelecionado = true
      // se a data de inicio for maior que a de fim
      } else if (datainicioparse > datafimparse) {
        this.alertHoraInicioMaior = true
      } else {
        // criação marcação funcionario //
        // criação marcação funcionario //
        // criação marcação funcionario //
        // preencher data fim e data inicio total
        var datainicio = this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio
        var tempoinicio = this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_inicio
        var sodatainicio = new Date(datainicio).toJSON().slice(0, 10)
        var sotempoinicio = new Date(tempoinicio).toJSON().slice(11, 23)
        var datatempoinicio = (sodatainicio+'T'+sotempoinicio+'Z')
        //-----//
        var datafim = this.createMarcacoesFuncionario.Marcacoes_funcionario_data_fim
        var tempofim = this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_fim
        var sodatafim = new Date(datafim).toJSON().slice(0, 10)
        var sotempofim = new Date(tempofim).toJSON().slice(11, 23)
        var datatempofim = (sodatafim+'T'+sotempofim+'Z')

        // datainicio total
        this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio_total = datatempoinicio
        // datafim total
        this.createMarcacoesFuncionario.Marcacoes_funcionario_data_fim_total = datatempofim

        // criação marcação no phc //
        // criação marcação no phc //
        // criação marcação no phc //
        // data
        var datafunini = this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio
        var sodatafimm = new Date(datafunini).toJSON().slice(0, 10)
        this.createMarcacoesPhc.data = sodatafimm + ' ' + '00:00:00.000'
        // hinicio
        var horahinicio = this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_inicio
        var horainicio = new Date(horahinicio).toJSON().slice(11, 16)
        this.createMarcacoesPhc.hinicio = horainicio
        // hfim
        var horahfim = this.createMarcacoesFuncionario.Marcacoes_funcionario_hora_fim
        var horafim = new Date(horahfim).toJSON().slice(11, 16)
        this.createMarcacoesPhc.hfim = horafim
        // inicio
        var sohorainicio = new Date(horahinicio).toJSON().slice(11, 13)
        var sominutosinicio = new Date(horahinicio).toJSON().slice(14, 16)
        this.createMarcacoesPhc.inicio = (sohorainicio * 60) + (sominutosinicio - 0)
        // fim
        var sohorafim = new Date(horahfim).toJSON().slice(11, 13)
        var sominutosfim = new Date(horahfim).toJSON().slice(14, 16)
        this.createMarcacoesPhc.fim = (sohorafim * 60) + (sominutosfim - 0)
        // no-clnome-texto-morada-local-codpost-vendnm
        this.cliente = (await ClientesService.show(this.editarMarcacao.Marcacoes_cliente)).data
        this.createMarcacoesPhc.clno = this.cliente.no
        this.createMarcacoesPhc.clnome = this.cliente.nome
        this.createMarcacoesPhc.texto = this.cliente.nome + ' - ' + this.editarMarcacao.Marcacoes_descricao
        this.createMarcacoesPhc.morada = this.cliente.morada
        this.createMarcacoesPhc.local = this.cliente.local
        this.createMarcacoesPhc.codpost = this.cliente.codpost
        this.createMarcacoesPhc.u_telefone = this.cliente.telefone
        // usrhora-ousrhora
        var usrousr = new Date().toJSON().slice(11, 19)
        this.createMarcacoesPhc.usrhora = usrousr
        this.createMarcacoesPhc.ousrhora = usrousr
        for (var t = 0; t < this.checkedInterv.length; t++) {
          // marcacoesfuncionario Marcacoes_funcionario_funcionario
          this.createMarcacoesFuncionario.Marcacoes_funcionario_funcionario = this.checkedInterv[t].funcionario_id
          // mxstamp
          var datehoje = new Date()
          var dataext = 'EXT' + addZero(datehoje.getDate()) +''+ addZero((datehoje.getMonth() + 1)) +''+ datehoje.getFullYear() +''+ addZero(datehoje.getHours()) +''+ addZero(datehoje.getMinutes()) +''+ addZero(datehoje.getSeconds())
          this.createMarcacoesPhc.mxstamp = dataext + this.checkedInterv[t].funcionario_id
          // nkeyid
          //ir buscar o nkeyid maior
          this.marcacaophcmaiornkeyid = (await MarcacoesPhcService.marcacaophcmaiornkeyid()).data
          this.createMarcacoesPhc.nkeyid = this.marcacaophcmaiornkeyid.nkeyid + 1
          // ckeyid
          //ir buscar o mxid maior
          this.marcacaophcmaiormxid = (await MarcacoesPhcService.marcacaophcmaiormxid()).data
          this.createMarcacoesPhc.ckeyid = ('EXT' + (this.marcacaophcmaiormxid.mxid + 1))
          this.usuario = (await TblUsuariosService.usuariofunc(this.checkedInterv[t].funcionario_id)).data
          this.utilizadorphc = (await UtilizadoresPhcService.usuariophc(this.usuario.tecnico_PHC)).data
          // vendnm-div-tecnico-vendedor-tecnnm-username-userno
          this.createMarcacoesPhc.vendnm = this.utilizadorphc.vendedor + '-' + this.utilizadorphc.iniciais
          this.createMarcacoesPhc.div = this.utilizadorphc.dpt
          this.createMarcacoesPhc.tecnico = this.utilizadorphc.tecnico
          this.createMarcacoesPhc.vendedor = this.utilizadorphc.vendedor
          this.createMarcacoesPhc.tecnnm = this.utilizadorphc.tecnnm
          this.createMarcacoesPhc.username = this.utilizadorphc.username
          this.createMarcacoesPhc.userno = this.utilizadorphc.userno
          await MarcacoesFuncionarioService.post(this.createMarcacoesFuncionario)
          await MarcacoesPhcService.post(this.createMarcacoesPhc)
          // ir bucar a ultima marcacao phc e ultima marcacao funcionario
          this.ultimaMarcacaoPhc = (await MarcacoesPhcService.ultimamarcacaophc()).data
          this.ultimaMarcacaoFuncionario = (await MarcacoesFuncionarioService.ultimamarcacaofuncionario()).data
          // marcacoes funcionario marcacao_phc
          this.ultimaMarcacaoFuncionario.Marcacoes_PHC = (this.ultimaMarcacaoPhc.mxid)
          await MarcacoesFuncionarioService.put(this.ultimaMarcacaoFuncionario)
          //adicionar log
          this.createLog.Log_Tabela = 'Marcacoes',
          this.createLog.Log_Tipo = 'Adicionar',
          this.createLog.Log_Numero = this.$store.state.marcacao.idmarcacao
          this.createLog.Log_Descricao = 'Funcionário ' + this.checkedInterv[t].funcionario_nome + ' adicionado por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)
        }

        this.alertIntervAdd = true
        document.location.reload(true)
      }
    },
    //apagar marcação funcionario
    async apagarInterv (id) {
      this.dialogApagarInterv = true
      this.idintervglobal = id
      this.idmxidglobal = id
    },
    //confirmar marcação funcionario tarefas
    async confirmarApagarIntervTarefas () {
      this.funcionarionome = (await FuncionarioService.show(this.idintervglobal.Marcacoes_funcionario_funcionario)).data
      //adicionar log
      this.createLog.Log_Tabela = 'Marcaces',
      this.createLog.Log_Tipo = 'Eliminar',
      this.createLog.Log_Numero = this.$store.state.marcacao.idmarcacao
      this.createLog.Log_Descricao = 'Funcionário ' + this.funcionarionome.funcionario_nome + ' eliminado por ' + this.$store.state.user.Usuario
      await LogService.post(this.createLog)
      this.alertintervApagado = true
      this.dialogApagarIntervPhc = true
      await MarcacoesFuncionarioService.delete(this.idintervglobal.Marcacoes_funcionario_id)
    },
    //confirmar marcação phc
    async confirmarApagarIntervPhc () {
      this.alertMarcacaoApagadoPhc = true
      document.location.reload(true)
      await MarcacoesPhcService.delete(this.idmxidglobal.Marcacoes_PHC)
    },
    //passar marcação funcionario de false para true
    async falseparatrue (id) {
      this.editarMarcacaoFuncionario = (await MarcacoesFuncionarioService.show(id)).data
      this.editarMarcacaoFuncionario.Marcacoes_funcionario_terminado = true
      this.editarMarcacaoFuncionario.Marcacoes_funcionario_terminado_data = new Date(Date.now())
      await MarcacoesFuncionarioService.put(this.editarMarcacaoFuncionario)
      document.location.reload(true)
    },
    //passar marcação funcionario de true para false
    async trueparafalse (id) {
      this.editarMarcacaoFuncionario = (await MarcacoesFuncionarioService.show(id)).data
      this.editarMarcacaoFuncionario.Marcacoes_funcionario_terminado = false
      this.editarMarcacaoFuncionario.Marcacoes_funcionario_terminado_data = null
      await MarcacoesFuncionarioService.put(this.editarMarcacaoFuncionario)
      document.location.reload(true)
    },
    //abrir modal marcações do dia
    async marcacoesDiaModal (func) {
      var elemprece = {idfunc: func.funcionario_id, dataselc: this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio}
      this.userdatamarcacoesfuncionariotarefas = (await MarcacoesFuncionarioService.userdatamarcacoesfuncionariotarefas(elemprece)).data
      this.usuarios = (await TblUsuariosService.index()).data
      for (var off = 0; off < this.usuarios.length; off++) { 
        if (this.usuarios[off].funcionario == func.funcionario_id) {
          var tecnicophcselecionado = this.usuarios[off].tecnico_PHC
        }
      }
      var elemprecephc = {tecnicophcslec: tecnicophcselecionado, dataselc: this.createMarcacoesFuncionario.Marcacoes_funcionario_data_inicio}
      this.userdatamarcacoesfuncionariophc = (await MarcacoesPhcService.userdatamarcacoesfuncionariophc(elemprecephc)).data
      //nomes clientes
      var ola = []
      //nome clientes marcações
      for (var m = 0; m < this.userdatamarcacoesfuncionariotarefas.length; m++) {
        ola.push(this.userdatamarcacoesfuncionariotarefas[m].Marcaco.Marcacoes_cliente)
      }
      this.clientesid = [...new Set(ola)]
      this.clientes = (await ClientesService.clientesids(this.clientesid)).data
      this.dialogMarcacoesDia = true
    },
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    //abrir modal dialogObs
    async obsModal (marcacaofuncid) {
      this.idmarcfunc = marcacaofuncid
      this.dialogObs = true
      this.marcacoesfuncionarioobs = (await MarcacoesFuncionarioObsService.marcacaofuncobs(marcacaofuncid)).data
    },
    //abrir modal dialogCriarObs
    async criarObsModal () {
      this.dialogCriarObs = true
    },
    //criar obs
    async criarObs () {
      this.createObservacoes.Marcacoes_Funcionario_Obs_Marcacao = this.idmarcfunc
      await MarcacoesFuncionarioObsService.post(this.createObservacoes)
      //adicionar log
      this.createLog.Log_Tabela = 'Marcacoes',
      this.createLog.Log_Tipo = 'Adicionar',
      this.createLog.Log_Numero = this.$store.state.marcacao.idmarcacao
      this.createLog.Log_Descricao = 'Nova nota adicionada por ' + this.$store.state.user.Usuario
      await LogService.post(this.createLog)
      this.alertObsCriada = true
      document.location.reload(true)
    },
    //editar obs
    async editarObs (obs) {
      this.editarMarcacaoFuncionarioobs = (await MarcacoesFuncionarioObsService.show(obs.Marcacoes_Funcionario_Obs_ID)).data
      this.editarMarcacaoFuncionarioobs.Marcacoes_Funcionario_Obs_Descricao = obs.Marcacoes_Funcionario_Obs_Descricao
      await MarcacoesFuncionarioObsService.put(this.editarMarcacaoFuncionarioobs)
      //adicionar log
      this.createLog.Log_Tabela = 'Marcacoes',
      this.createLog.Log_Tipo = 'Editar',
      this.createLog.Log_Numero = this.$store.state.marcacao.idmarcacao
      this.createLog.Log_Descricao = 'Nota editada por ' + this.$store.state.user.Usuario
      await LogService.post(this.createLog)
      this.alertObsEditada = true
      document.location.reload(true)
    },
    //apagar obs
    async apagarObs (id) {
      this.dialogApagarObs = true
      this.idobsglobal = id
    },
    //confirmar apagar obs
    async confirmarApagarObs () {
      //adicionar log
      this.createLog.Log_Tabela = 'Marcacoes',
      this.createLog.Log_Tipo = 'Eliminar',
      this.createLog.Log_Numero = this.$store.state.marcacao.idmarcacao
      this.createLog.Log_Descricao = 'Nota eliminada por ' + this.$store.state.user.Usuario
      await LogService.post(this.createLog)
      this.alertObsApagada = true
      document.location.reload(true)
      await MarcacoesFuncionarioObsService.delete(this.idobsglobal)
    },
    //-------------------------------------------Adicionar a projeto------------------------------//
    //-------------------------------------------Adicionar a projeto------------------------------//
    //-------------------------------------------Adicionar a projeto------------------------------//
    async AdicionarProjeto () {
      this.editarMarcacao.Marcacoes_data_fim = this.editarMarcacao.Marcacoes_data_inicio
      await MarcacoesService.put(this.editarMarcacao)
      //adicionar log
      this.createLog.Log_Tabela = 'Marcacoes',
      this.createLog.Log_Tipo = 'Editar',
      this.createLog.Log_Numero = this.$store.state.marcacao.idmarcacao
      this.createLog.Log_Descricao = 'Marcação alterada por ' + this.$store.state.user.Usuario
      await LogService.post(this.createLog)
      this.alertEditada = true
      this.projetosporconcluircliente = (await ProjetosService.projetosporconcluircliente(this.cliente.no)).data
      this.dialogProjetosClienteAbertos = true
    },
    async abriarmodalconfirmaradicionarprojeto (item) {
      this.dialogConfirmarProjetoMarcacao = true
      this.idprojetoescolhido = item.Projetos_id
      this.numeroprojetoescolhido = item.Projetos_ano + '/' + item.Projetos_numero
    },
    async confirmarMarcacaoProjetoAdicionada () {
      this.editarMarcacao.Marcacoes_Projeto = this.idprojetoescolhido,
      this.editarMarcacao.Marcacoes_Projeto_numero = this.numeroprojetoescolhido
      await MarcacoesService.put(this.editarMarcacao)
      this.alertMarcacaoProjetoAdicionada = true
      document.location.reload(true)
    },
    //-------------------------------------------Enviar Email------------------------------//
    //-------------------------------------------Enviar Email------------------------------//
    //-------------------------------------------Enviar Email------------------------------//
    async enviarEmail(mf) {
      this.dialogConfirmarEnviarEmail = true
      this.mfglobal = mf
    },
    async confirmarEmailEnviado() {
        try {
          this.dadosEmail.cliente = this.cliente.nome
          this.dadosEmail.clienteemail = this.cliente.email
          this.dadosEmail.texto = this.editarMarcacao.Marcacoes_descricao
          this.dadosEmail.tecnicoescolhidoemail = this.mfglobal.Funcionario.funcionario_email
          this.dadosEmail.tecnicoescolhidonome = this.mfglobal.Funcionario.funcionario_nome
          this.dadosEmail.quemenviou = this.$store.state.user.Usuario
          this.dadosEmail.data = new Date(this.mfglobal.Marcacoes_funcionario_data_inicio).toLocaleDateString('pt-PT');
          this.dadosEmail.hora = new Date(this.mfglobal.Marcacoes_funcionario_hora_inicio).toJSON().slice(11, 16)
          this.dadosEmail.tipo = 'Marcação'

          if (this.dadosEmail.checkedEmailFuncionario == true) {
            this.editarMarcacaoFuncionarioEmail = (await MarcacoesFuncionarioService.show(this.mfglobal.Marcacoes_funcionario_id)).data
            this.editarMarcacaoFuncionarioEmail.Marcacoes_funcionario_enviado_mail = true
            this.editarMarcacaoFuncionarioEmail.Marcacoes_funcionario_enviado_mail_data = new Date(Date.now())
            await MarcacoesFuncionarioService.put(this.editarMarcacaoFuncionarioEmail)
          }

          if (this.dadosEmail.checkedEmailCliente == true) {
            this.editarMarcacaoFuncionarioEmailCliente = (await MarcacoesFuncionarioService.show(this.mfglobal.Marcacoes_funcionario_id)).data
            this.editarMarcacaoFuncionarioEmailCliente.Marcacoes_funcionario_enviado_cliente_mail = true
            this.editarMarcacaoFuncionarioEmailCliente.Marcacoes_funcionario_enviado_cliente_mail_data = new Date(Date.now())
            await MarcacoesFuncionarioService.put(this.editarMarcacaoFuncionarioEmailCliente)
          }
          
          this.alertEmailEnviado = true
          document.location.reload(true)
          await ServiceEmails.sendEmail(this.dadosEmail)
          
        } catch (error) {
            this.alertEmailEnviado = false
        }
    },
    async QuemEnviamosEmail(id) {
      this.editarMarcacaoFuncionarioEmail = (await MarcacoesFuncionarioService.show(id)).data
      this.editarMarcacaoFuncionarioEmailCliente = (await MarcacoesFuncionarioService.show(id)).data
      this.dialogQuemEnviamosEmail = true
    },
  }
}
</script>
<style>
.interve .v-list-item {
  padding: 0 !important;
}
.editr--toolbar{
  display: none !important;
}
</style>