import Api from '@/services/Api'

export default {
  // todos os anexosoutros
  index () {
    return Api().get('anexosoutros')
  },
  // criar anexooutro
  post (elementos) {
    return Api().post('anexooutro', elementos)
  },
  // enviar anexooutro para servidor para a pasta das tarefas
  postAnexoOutroTarefas (elementos) {
    return Api().post('anexooutrotarefas', elementos)
  }
  
}
