import Api from '@/services/Api'

export default {
  // ir buscar todos os tempos agora
  index () {
    return Api().get('tempos_agora')
  },
  // ir buscar o tempo agora por este id
  show (id) {
    return Api().get(`tempo_agora/${id}`)
  },
  // criar tempos agora
  post (elementos) {
    return Api().post('tempos_agora', elementos)
  },
  // dar update no tempos agora com este id
  put (id) {
    return Api().put(`tempo_agora/${id.Tempos_Agora_ID}`, id)
  },
  // apagar tempo
  delete (id) {
    return Api().delete(`tempo_agora/${id}`, id)
  },
  // ir buscar os tempos agora não terminado
  temposagoranaoterminado () {
    return Api().get('temposagoranaoterminado')
  },
  // tempos agora por esta data e este funcionario
  tempoagorafuncdata (elemfundata) {
    return Api().get('tempoagorafuncdataR',{ 
      params: {
        funcionarioselecionado: elemfundata.funcionarioslec,
        dataselecionadainicio: elemfundata.dataselcinicio,
        dataselecionadafim: elemfundata.dataselcfim
      }
    })
  },
  // tempos agora por este funcionario
  tempoagorafunc (elemfunc) {
    return Api().get('tempoagorafunc',{ 
      params: {
        funcionarionome: elemfunc.nomefunc,
      }
    })
  },
  // tempos agora por este cliente
  temposagoracliente (idcliente) {
    return Api().get('temposagoracliente',{ 
      params: {
        idcliente
      }
    })
  },
  // tempos agora por esta tarefa
  temposagoratarefas (idtarefa) {
    return Api().get('temposagoratarefas',{ 
      params: {
        idtarefa
      }
    })
  },
  // ultimo tempo agora
  ultimotempoagora () {
    return Api().get('ultimotempoagora')
  }
}
