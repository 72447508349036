//  Outros
import Vue from 'vue'
import Router from 'vue-router'
//  Login
import MyLogin from '@/components/MyLogin'
//  Home
import MyHome from '@/components/MyHome'
//  Tarefas
import MyTarefasPorConcluir from '@/components/Tarefas/MyTarefasPorConcluir'
import MyCriarTarefas from '@/components/Tarefas/MyCriarTarefas'
import MyEditarTarefas from '@/components/Tarefas/MyEditarTarefas'
//  Marcações
import MyMarcacoesPorConcluir from '@/components/Marcacoes/MyMarcacoesPorConcluir'
import MyCriarMarcacoes from '@/components/Marcacoes/MyCriarMarcacoes'
import MyEditarMarcacoes from '@/components/Marcacoes/MyEditarMarcacoes'
// Projetos
import MyProjetosPorConcluir from '@/components/Projetos/MyProjetosPorConcluir'
import MyCriarProjetos from '@/components/Projetos/MyCriarProjetos'
import MyEditarProjetos from '@/components/Projetos/MyEditarProjetos'
import MyResumosProjetos from '@/components/Projetos/MyResumosProjetos'
// Funcionarios
import MyFuncionariosEstado from '@/components/Funcionarios/MyFuncionariosEstado'
// Tempos
import MyCriarTempos from '@/components/Tempos/MyCriarTempos'
import MyEditarTempos from '@/components/Tempos/MyEditarTempos'
// Clientes
import MyClientes from '@/components/Clientes/MyClientes'
// Contas Corrente
import MyContasCorrente from '@/components/ContasCorrente/MyContasCorrente'
// Calendario
import MyCalendario from '@/components/Calendario/MyCalendario'
// Tickets
import MyTickets from '@/components/Tickets/MyTickets'
Vue.use(Router)

export default new Router({
  mode: 'history',
  //  path: link que vamos usar
  //  name: nome que vai ser chamado no componets*
  //  component: nome que damos la em cima para chamar o componets
  routes: [
    //Login
    {
      path: '/',
      name: 'mylogin',
      component: MyLogin
    },
    //  Home
    {
      path: '/home',
      name: 'myhome',
      component: MyHome
    },
    //  Tarefas Por Concluir
    {
      path: '/tarefasporconcluir',
      name: 'tarefasporconcluir',
      component: MyTarefasPorConcluir
    },
    //  Criar Tarefas
    {
      path: '/criartarefas',
      name: 'criartarefas',
      component: MyCriarTarefas
    },
    //  Editar Tarefas
    {
      path: '/editartarefas',
      name: 'editartarefas',
      component: MyEditarTarefas
    },
    //  Marcações Por Concluir
    {
      path: '/marcacoesporconcluir',
      name: 'marcacoesporconcluir',
      component: MyMarcacoesPorConcluir
    },
    //  Criar Marcações
    {
      path: '/criarmarcacoes',
      name: 'criarmarcacoes',
      component: MyCriarMarcacoes
    },
    //  Editar Marcações
    {
      path: '/editarmarcacoes',
      name: 'editarmarcacoes',
      component: MyEditarMarcacoes
    },
    //  Projetos Por Concluir
    {
      path: '/projetosporconcluir',
      name: 'projetosporconcluir',
      component: MyProjetosPorConcluir
    },
    //  Resumos Projetos
    {
      path: '/resumosprojetos',
      name: 'resumosprojetos',
      component: MyResumosProjetos
    },
    //  Criar Projetos
    {
      path: '/criarprojetos',
      name: 'criarprojetos',
      component: MyCriarProjetos
    },
    //  Editar Marcações
    {
      path: '/editarprojetos',
      name: 'editarprojetos',
      component: MyEditarProjetos
    },
    //  Funcionario Estados
    {
      path: '/funcionariosestado',
      name: 'funcionariosestado',
      component: MyFuncionariosEstado
    },
    //CriarTempos
    {
      path: '/criartempos',
      name: 'criartempos',
      component: MyCriarTempos
    },
    //Editar Tempos
    {
      path: '/editartempos',
      name: 'editartempos',
      component: MyEditarTempos
    },
    //Clientes
    {
      path: '/clientes',
      name: 'clientes',
      component: MyClientes
    },
    //Contas Corrente
    {
      path: '/contascorrente',
      name: 'contascorrente',
      component: MyContasCorrente
    },
    //Calendario
    {
      path: '/calendario',
      name: 'calendario',
      component: MyCalendario
    },
    //Tickets
    {
      path: '/tickets',
      name: 'tickets',
      component: MyTickets
    }
  ]
})
