import { render, staticRenderFns } from "./MyCalendario.vue?vue&type=template&id=35a76fe1&"
import script from "./MyCalendario.vue?vue&type=script&lang=js&"
export * from "./MyCalendario.vue?vue&type=script&lang=js&"
import style0 from "./MyCalendario.vue?vue&type=style&index=0&id=35a76fe1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports