<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn">
      <v-flex>
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Editar Projeto</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <label>Projeto</label>
                      <v-text-field :value="editarProjeto.Projetos_ano + '/' + editarProjeto.Projetos_numero " disabled filled outlined></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <label>Clientes</label>
                      <v-text-field v-model="cliente.nome" disabled filled outlined></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <label>Data de Registo</label>
                      <MYdatetime type="date" :value="editarProjeto.Projetos_data_registo" disabled="disabled"></MYdatetime>
                    </v-col>
                    <v-col cols="12">
                      <label>Quem Registou</label>
                      <v-text-field v-model="editarProjeto.Funcionario.funcionario_nome" disabled filled outlined></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <label>Descrição</label>
                      <wysiwyg v-model="editarProjeto.Projetos_descricao"/>
                    </v-col>
                    <v-col cols="12">
                      <label>Data de Inicio</label>
                      <MYdatetime hidden-name type="date" v-model="editarProjeto.Projetos_data_inicio"></MYdatetime>
                    </v-col>
                    <v-col cols="12">
                      <label>Data de fim</label>
                      <MYdatetime hidden-name type="date" v-model="editarProjeto.Projetos_data_fim"></MYdatetime>
                    </v-col>
                    <v-col cols="12">
                      <label>Tópico</label>
                      <v-select v-model="editarProjeto.Projetos_Topico" :items="['Atualização aplicação', 'Atualização contrato', 'Em desenvolvimento', 'Formação', 'Instalação', 'Orçamentos']" filled outlined></v-select>
                    </v-col>
                    <v-col cols="12">
                      <label>Estado</label>
                      <v-select v-model="editarProjeto.Projetos_estado" :items="estados" item-text="Estado_descricao" item-value="Estado_id" filled outlined></v-select>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <label>Intervenientes</label>
                      <v-icon large color="black" @click="intervModal">mdi-plus</v-icon>
                    </v-col>
                    <v-col cols="12" v-for="(pj) in editarProjeto.Projetos_Funcionarios" :key="pj.Projetos_funcionario_id">
                      <v-list class="interve">
                        <v-list-item>
                          <v-list-item-action class="pr-3 mr-0">
                            <v-icon color="blue" @click="obsModal(pj.Projetos_funcionario_id)">mdi-note</v-icon>
                          </v-list-item-action>
                          <v-list-item-content v-if="pj.Projetos_Funcionario_Obs != 0" class="back-obs">
                            <v-list-item-title>{{pj.Funcionario.funcionario_nome}}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-content v-else>
                            <v-list-item-title>{{pj.Funcionario.funcionario_nome}}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action class="pl-3 ml-0">
                            <v-icon color="blue" @click="enviarEmail(pj)">mdi-email-arrow-right</v-icon>
                          </v-list-item-action>
                          <span class="d-none intElementos">{{ pj.Projetos_funcionario_terminado }}</span>
                          <template v-if="pj.Projetos_funcionario_terminado == false">
                            <v-list-item-action class="pl-3">
                              <v-icon color="red" @click="falseparatrue(pj.Projetos_funcionario_id)">mdi-close</v-icon>
                            </v-list-item-action>
                          </template>
                          <template v-else>
                            <v-list-item-action class="pl-3">
                              <v-icon color="green" @click="trueparafalse(pj.Projetos_funcionario_id)">mdi-check</v-icon>
                            </v-list-item-action>
                          </template>
                          <template v-if="pj.Projetos_funcionario_enviado_mail == true">
                            <v-list-item-action class="pl-3">
                              <v-icon color="green" @click="QuemEnviamosEmail(pj.Projetos_funcionario_id)">mdi-email</v-icon>
                            </v-list-item-action>
                          </template>
                          <template v-else>
                            <v-list-item-action class="pl-3">
                              <v-icon color="white">mdi-email</v-icon>
                            </v-list-item-action>
                          </template>
                          <v-list-item-action class="pl-3">
                            <v-icon color="red" @click="apagarInterv(pj)">mdi-delete</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                      <v-alert v-model="alertDataFimMaior" dismissible type="error">Data de Fim menor que a de Inicio</v-alert>
                      <v-alert v-model="alertEditada" dismissible type="success">Projeto editada com sucesso</v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <!-- Dialog Adicionar Interveniente -->
            <v-dialog v-model="dialogAdicionarInterveniente">
              <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                  <v-toolbar-title>Adicionar Interveniente</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogAdicionarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list>
                        <v-list-item-group color="primary">
                          <div v-for="func in funcionarios" :key="func.funcionario_id">
                            <v-divider></v-divider>
                            <v-list-item>
                              <template>
                                <v-list-item-action>
                                  <v-checkbox v-model="checkedInterv" :value="func"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>{{func.funcionario_nome}}</v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-list-item>
                          </div>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                    <v-col cols="12">
                      <v-alert v-model="alertIntervAdd" dismissible type="success">Interveninete adicionado com sucesso</v-alert>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="addInterveniente">Guardar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Dialog observações -->
            <v-dialog v-model="dialogObs">
              <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                  <v-toolbar-title>Notas</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>          
                  <v-toolbar-title><v-list-item @click="dialogObs = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <v-row class="mb" v-for=" obs in projetosfuncionarioobs" :key="obs.Projetos_Funcionario_Obs_ID">
                        <v-col cols="12">
                          <span>{{ obs.Funcionario.funcionario_nome }} - {{ formatDate(obs.Projetos_Funcionario_Obs_data) }}</span>
                          <wysiwyg v-model="obs.Projetos_Funcionario_Obs_Descricao"/>
                          <v-list-item-action class="ml-0">
                            <v-icon color="green" @click="editarObs(obs)">mdi-arrow-up-bold-box-outline</v-icon>
                          </v-list-item-action>
                          <v-list-item-action class="ml-0 pl-3">
                            <v-icon color="red" @click="apagarObs(obs.Projetos_Funcionario_Obs_ID)">mdi-delete</v-icon>
                          </v-list-item-action>
                        </v-col>
                        <v-col cols="12">
                          <v-alert v-model="alertObsEditada" dismissible type="success">Nota editada com sucesso</v-alert>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="criarObsModal">Criar Nota</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Dialog criar observações -->
            <v-dialog v-model="dialogCriarObs">
              <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                  <v-toolbar-title>Criar Nota</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogCriarObs = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <v-row class="mb">
                        <v-col cols="12">
                          <label>Interveniente</label>
                          <v-autocomplete v-model="createObservacoes.Projetos_Funcionario_Obs_Quem_Enviou" :items="funcionarios" item-text="funcionario_nome" item-value="funcionario_id" clearable filled outlined></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <label>Data de Registo</label>
                          <MYdatetime type="date" :value="new Date(Date.now()).toISOString()" disabled="disabled"></MYdatetime>
                        </v-col>
                        <v-col cols="12">
                          <label>Descrição</label>
                          <wysiwyg v-model="createObservacoes.Projetos_Funcionario_Obs_Descricao"/>
                        </v-col>
                        <v-col cols="12">
                          <v-alert v-model="alertObsCriada" dismissible type="success">Nota criada com sucesso</v-alert>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="criarObs">Guardar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Dialog apagar obs -->
            <v-dialog v-model="dialogApagarObs">
              <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                  <v-toolbar-title></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogApagarObs = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <span>Quer apagar a Nota?</span>
                </v-col>
                <v-col cols="12">
                  <v-alert v-model="alertObsApagada" dismissible type="success">Nota apagada com sucesso</v-alert>
                </v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="confirmarApagarObs">Confirmar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Dialog apagar interv -->
            <v-dialog v-model="dialogApagarInterv">
              <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                  <v-toolbar-title></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogApagarInterv = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <span>Quer apagar o Interveniente?</span>
                </v-col>
                <v-col cols="12">
                  <v-alert v-model="alertintervApagado" dismissible type="success">Interveniente apagado com sucesso</v-alert>
                </v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="confirmarApagarInterv">Confirmar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Dialog Todos Interv Terminados -->
            <v-dialog v-model="dialogTodosIntervTerminados">
              <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                  <v-toolbar-title></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogTodosIntervTerminados = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <span>Projeto concluido por todos os intervenientes. Quer dá-lo como terminado?</span>
                </v-col>
                <v-col cols="12">
                  <v-alert v-model="alertProjetoTerminada" dismissible type="success">Projeto terminado com sucesso</v-alert>
                </v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="confirmarProjetoTerminada">Confirmar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Dialog confirmar enviar email -->
            <v-dialog v-model="dialogConfirmarEnviarEmail">
              <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                  <v-toolbar-title></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogConfirmarEnviarEmail = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                <span>Que mesmo enviar email?</span>
                </v-col>
                <v-col cols="12">
                  <v-checkbox v-model="dadosEmail.checkedEmailFuncionario" :label="pjglobal.Funcionario?.funcionario_email"></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox v-model="dadosEmail.checkedEmailCliente" :label="cliente.email"></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-alert v-model="alertEmailEnviado" dismissible type="success">Email enviado com sucesso</v-alert>
                </v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="confirmarEmailEnviado">Confirmar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Dialog Ver para quem enviamos email -->
            <v-dialog v-model="dialogQuemEnviamosEmail">
              <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                  <v-toolbar-title></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogQuemEnviamosEmail = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12" v-if="editarProjetoFuncionarioEmail.Projetos_funcionario_enviado_mail != null">
                  <span>Email enviado em {{ formatDate(editarProjetoFuncionarioEmail.Projetos_funcionario_enviado_mail_data) }} para o funcionário {{ editarProjetoFuncionarioEmail.Projetos_funcionario_enviado_mail_quem }}</span>
                </v-col>
                <v-col cols="12" v-if="editarProjetoFuncionarioEmailCliente.Projetos_funcionario_enviado_mail_cliente != null">
                  <span>Email enviado em {{ formatDate(editarProjetoFuncionarioEmailCliente.Projetos_funcionario_enviado_mail_cliente_data) }} para o cliente {{ editarProjetoFuncionarioEmailCliente.Projetos_funcionario_enviado_mail_cliente_quem }}</span>
                </v-col>
              </v-card>
            </v-dialog>
            <v-card-actions>
              <v-btn class="botaodetalhesprojeto" @click="navigateTo({name: 'resumosprojetos', params:{idEnviado: editarProjeto.Projetos_id}})">Detalhes do Projeto</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="EditarProjeto">Guardar</v-btn>
            </v-card-actions>
          </v-card>
      </v-flex>
    </v-layout>
  </template> 
  <script>
  //  Importar o service necessário
  import ProjetosService from '@/services/ProjetosService'
  import EstadosService from '@/services/EstadosService'
  import FuncionarioService from '@/services/FuncionarioService'
  import ClientesService from '@/services/ClientesService'
  import ProjetosFuncionarioService from '@/services/ProjetosFuncionarioService'
  import ProjetosFuncionarioObsService from '@/services/ProjetosFuncionarioObsService'
  import LogService from '@/services/LogService'
  import ServiceEmails from '@/services/ServiceEmails'
  import store from '@/store/store'
  export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      }
      this.editarProjeto = (await ProjetosService.show(this.$store.state.projeto.idprojeto)).data
      this.cliente = (await ClientesService.show(this.editarProjeto.Projetos_cliente)).data
      this.estados = (await EstadosService.index()).data
      this.funcionarios = (await FuncionarioService.index()).data
      if (this.editarProjeto.Projetos_data_inicio == '') {
        this.editarProjeto.Projetos_data_inicio = null
      }
      if (this.editarProjeto.Projetos_data_fim == '') {
        this.editarProjeto.Projetos_data_fim = null
      }
      // se os intervenientes tiverem todos concluidos vai colocar o projeto como terminado
      var elemento = document.getElementsByClassName('intElementos')
      var contador = 0
      for (var i = 0; i < elemento.length; i++) {
        if (elemento[i].innerHTML == 'false') {
          contador = contador + 1
        }
      }
      if (contador == 0 && this.editarProjeto.Projetos_concluida != true) {
        this.dialogTodosIntervTerminados = true
      }
    },
    data () {
      return {
        createLog: {
          Log_Tabela: null,
          Log_Tipo: null,
          Log_Numero: null,
          Log_Data: new Date(Date.now()).toISOString(),
          Log_Funcionario: this.$store.state.user.funcionario,
          Log_Descricao: null,
        },
        editarProjeto: {
          Projetos_descricao: null,
          Projetos_data_inicio: null,
          Projetos_data_fim: null,
          Projetos_concluida: null,
          Projetos_concluida_data: null,
          Projetos_Topico: null,
          Projetos_estado: null
        },
        createProjetosFuncionario: {
          Projetos_funcionario_Projeto: this.$store.state.projeto.idprojeto,
          Projetos_funcionario_funcionario: null,
          Projetos_funcionario_atribuido_data: new Date(Date.now()),
          Projetos_funcionario_atribuido_por: this.$store.state.user.funcionario,
        },
        editarProjetoFuncionario: {
          Projetos_funcionario_terminado: null,
          Projetos_funcionario_terminado_data: null
        },
        editarProjetoFuncionarioEmail: {
          Projetos_funcionario_enviado_mail: null,
          Projetos_funcionario_enviado_mail_data: null,
          Projetos_funcionario_enviado_mail_quem: null
        },
        editarProjetoFuncionarioEmailCliente: {
          Projetos_funcionario_enviado_mail_cliente: null,
          Projetos_funcionario_enviado_mail_cliente_data: null,
          Projetos_funcionario_enviado_mail_cliente_quem: null,
        },
        createObservacoes: {
          Projetos_Funcionario_Obs_Projeto: null,
          Projetos_Funcionario_Obs_data: new Date(Date.now()),
          Projetos_Funcionario_Obs_Descricao: null,
          Projetos_Id: this.$store.state.projeto.idprojeto,
          Projetos_Funcionario_Obs_Quem_Enviou: this.$store.state.user.funcionario
        },
        editarProjetoFuncionarioobs: {
          Projetos_Funcionario_Obs_Descricao: null
        },
        dadosEmail: {
          cliente: null,
          clienteemail: null,
          quemenviou: null,
          texto: null,
          paraquemenviar: null,
          tipo: null,
          checkedEmailFuncionario: true,
          checkedEmailCliente: false,
        },
        estados: [],
        funcionarios: [],
        cliente: {},
        checkedInterv: [],
        projetosfuncionarioobs: [],
        dialogObs: false,
        dialogCriarObs: false,
        idprojecfunc: '',
        alertObsCriada: false,
        alertObsEditada: false,
        alertObsApagada: false,
        dialogApagarObs: false,
        idobsglobal: '',
        alertDataFimMaior: false,
        alertEditada: false,
        alertintervApagado: false,
        dialogApagarInterv: false,
        idintervglobal: '',
        dialogTodosIntervTerminados: false,
        dialogQuemEnviamosEmail: false,
        alertProjetoTerminada: false,
        alertIntervAdd: false,
        dialogAdicionarInterveniente: false,
        alertEmailEnviado: false,
        dialogConfirmarEnviarEmail: false,
        marcacoestodas: [],
        pjglobal: ''
      }
    },
    methods: {
      // formatar data
      formatDate (date) {
        const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return new Date(date).toLocaleDateString('pt-PT', options)
      },
      navigateTo (route) {
        this.$store.dispatch('setProjeto', {
          idprojeto: route.params.idEnviado
        })
        this.$router.push(route)
      },
      //-------------------------------------------projeto------------------------------//
      //-------------------------------------------projeto------------------------------//
      //-------------------------------------------projeto------------------------------//
      async EditarProjeto () {
        // se ambas as datas tiverem preenchidas vai verificar se a data de fim é maior que a data de inicio
        if (this.editarProjeto.Projetos_data_inicio != null && this.editarProjeto.Projetos_data_fim != null) {
          const datainiciopc = this.editarProjeto.Projetos_data_inicio
          const datafimpc = this.editarProjeto.Projetos_data_fim
          const datainiciocc = new Date(datainiciopc)
          const datafimcc = new Date(datafimpc)
          if (datainiciocc.getTime() > datafimcc.getTime()) {
            this.alertDataFimMaior = true
          } else {
            await ProjetosService.put(this.editarProjeto)
            //adicionar log
            this.createLog.Log_Tabela = 'Projetos',
            this.createLog.Log_Tipo = 'Editar',
            this.createLog.Log_Numero = this.$store.state.projeto.idprojeto
            this.createLog.Log_Descricao = 'Projeto alterado por ' + this.$store.state.user.Usuario
            await LogService.post(this.createLog)
            this.alertEditada = true
            document.location.reload(true)
          }
        } else {
          await ProjetosService.put(this.editarProjeto)
          //adicionar log
          this.createLog.Log_Tabela = 'Projetos',
          this.createLog.Log_Tipo = 'Editar',
          this.createLog.Log_Numero = this.$store.state.projeto.idprojeto
          this.createLog.Log_Descricao = 'Projeto alterado por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)
          this.alertEditada = true
          document.location.reload(true)
        }
      },
      //confirmar projeto terminado
      async confirmarProjetoTerminada () {
        // se ambas tiverem preenchidas vai verificar se a de fim é maior que a de inicio
        if (this.editarProjeto.Projetos_data_inicio != null && this.editarProjeto.Projetos_data_fim != null) {
          const datainiciopc = this.editarProjeto.Projetos_data_inicio
          const datafimpc = this.editarProjeto.Projetos_data_fim
          const datainiciocc = new Date(datainiciopc)
          const datafimcc = new Date(datafimpc)
          if (datainiciocc.getTime() > datafimcc.getTime()) {
            this.alertDataFimMaior = true
          } else {
            this.editarProjeto.Projetos_concluida_data = new Date(Date.now())
            this.editarProjeto.Projetos_concluida = true
            await ProjetosService.put(this.editarProjeto)
            this.alertProjetoTerminada = true
            document.location.reload(true)
          }
        } else {
          this.editarProjeto.Projetos_concluida_data = new Date(Date.now())
          this.editarProjeto.Projetos_concluida = true
          await ProjetosService.put(this.editarProjeto)
          this.alertProjetoTerminada = true
          document.location.reload(true)
        }
      },
      //-------------------------------------------projeto funcionario------------------------------//
      //-------------------------------------------projeto funcionario------------------------------//
      //-------------------------------------------projeto funcionario------------------------------//
      //abrir modal adicionar interveniente
      async intervModal () {
        this.dialogAdicionarInterveniente = true
      },
      // adicionar interveniente
      async addInterveniente () {
        for (var iiiii = 0; iiiii < this.checkedInterv.length; iiiii++) {
          this.createProjetosFuncionario.Projetos_funcionario_funcionario = this.checkedInterv[iiiii].funcionario_id
          await ProjetosFuncionarioService.post(this.createProjetosFuncionario)
          //adicionar log
          this.createLog.Log_Tabela = 'Projetos',
          this.createLog.Log_Tipo = 'Adicionar',
          this.createLog.Log_Numero = this.$store.state.projeto.idprojeto
          this.createLog.Log_Descricao = 'Funcionário ' + this.checkedInterv[iiiii].funcionario_nome + ' adicionado por ' + this.$store.state.user.Usuario
          await LogService.post(this.createLog)
        }
        this.alertIntervAdd = true
        document.location.reload(true)
      },
      //apagar projeto funcionario
      async apagarInterv (id) {
        this.dialogApagarInterv = true
        this.idintervglobal = id
      },
      //confirmar projeto funcionario
      async confirmarApagarInterv () {
        this.funcionarionome = (await FuncionarioService.show(this.idintervglobal.Projetos_funcionario_funcionario)).data
        //adicionar log
        this.createLog.Log_Tabela = 'Projetos',
        this.createLog.Log_Tipo = 'Eliminar',
        this.createLog.Log_Numero = this.$store.state.projeto.idprojeto
        this.createLog.Log_Descricao = 'Funcionário ' + this.funcionarionome.funcionario_nome + ' eliminado por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
        this.alertintervApagado = true
        document.location.reload(true)
        await ProjetosFuncionarioService.delete(this.idintervglobal.Projetos_funcionario_id)
      },
      //passar projeto funcionario de false para true
      async falseparatrue (id) {
        this.editarProjetoFuncionario = (await ProjetosFuncionarioService.show(id)).data
        this.editarProjetoFuncionario.Projetos_funcionario_terminado = true
        this.editarProjetoFuncionario.Projetos_funcionario_terminado_data = new Date(Date.now())
        await ProjetosFuncionarioService.put(this.editarProjetoFuncionario)
        document.location.reload(true)
      },
      //passar projeto funcionario de true para false
      async trueparafalse (id) {
        this.editarProjetoFuncionario = (await ProjetosFuncionarioService.show(id)).data
        this.editarProjetoFuncionario.Projetos_funcionario_terminado = false
        this.editarProjetoFuncionario.Projetos_funcionario_terminado_data = null
        await ProjetosFuncionarioService.put(this.editarProjetoFuncionario)
        document.location.reload(true)
      },
      //-------------------------------------------observações------------------------------//
      //-------------------------------------------observações------------------------------//
      //-------------------------------------------observações------------------------------//
      //abrir modal dialogObs
      async obsModal (projetofuncid) {
        this.idprojecfunc = projetofuncid
        this.dialogObs = true
        this.projetosfuncionarioobs = (await ProjetosFuncionarioObsService.projetofuncobs(projetofuncid)).data
      },
      //abrir modal dialogCriarObs
      async criarObsModal () {
        this.dialogCriarObs = true
      },
      //criar obs
      async criarObs () {
        this.createObservacoes.Projetos_Funcionario_Obs_Projeto = this.idprojecfunc
        await ProjetosFuncionarioObsService.post(this.createObservacoes)
        //adicionar log
        this.createLog.Log_Tabela = 'Projetos',
        this.createLog.Log_Tipo = 'Adicionar',
        this.createLog.Log_Numero = this.$store.state.projeto.idprojeto
        this.createLog.Log_Descricao = 'Nova nota adicionada por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
        this.alertObsCriada = true
        document.location.reload(true)
      },
      //editar obs
      async editarObs (obs) {
        this.editarProjetoFuncionarioobs = (await ProjetosFuncionarioObsService.show(obs.Projetos_Funcionario_Obs_ID)).data
        this.editarProjetoFuncionarioobs.Projetos_Funcionario_Obs_Descricao = obs.Projetos_Funcionario_Obs_Descricao
        await ProjetosFuncionarioObsService.put(this.editarProjetoFuncionarioobs)
        //adicionar log
        this.createLog.Log_Tabela = 'Projetos',
        this.createLog.Log_Tipo = 'Editar',
        this.createLog.Log_Numero = this.$store.state.projeto.idprojeto
        this.createLog.Log_Descricao = 'Nota editada por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
        this.alertObsEditada = true
        document.location.reload(true)
      },
      //apagar obs
      async apagarObs (id) {
        this.dialogApagarObs = true
        this.idobsglobal = id
      },
      //confirmar apagar obs
      async confirmarApagarObs () {
        //adicionar log
        this.createLog.Log_Tabela = 'Projetos',
        this.createLog.Log_Tipo = 'Eliminar',
        this.createLog.Log_Numero = this.$store.state.projeto.idprojeto
        this.createLog.Log_Descricao = 'Nota eliminada por ' + this.$store.state.user.Usuario
        await LogService.post(this.createLog)
        this.alertObsApagada = true
        document.location.reload(true)
        await ProjetosFuncionarioObsService.delete(this.idobsglobal)
      },
      //-------------------------------------------Enviar Email------------------------------//
      //-------------------------------------------Enviar Email------------------------------//
      //-------------------------------------------Enviar Email------------------------------//
      async enviarEmail(pj) {
        this.dialogConfirmarEnviarEmail = true
        this.pjglobal = pj
      },
      async confirmarEmailEnviado() {
          try {
            this.dadosEmail.cliente = this.cliente.nome
            this.dadosEmail.clienteemail = this.cliente.email
            this.dadosEmail.texto = this.editarProjeto.Projetos_descricao
            this.dadosEmail.tecnicoescolhidoemail = this.pjglobal.Funcionario.funcionario_email
            this.dadosEmail.tecnicoescolhidonome = this.pjglobal.Funcionario.funcionario_nome
            this.dadosEmail.quemenviou = this.$store.state.user.Usuario
            this.dadosEmail.tipo = 'Projeto'    

            if (this.dadosEmail.checkedEmailFuncionario == true) {
              this.editarProjetoFuncionarioEmail = (await ProjetosFuncionarioService.show(this.pjglobal.Projetos_funcionario_id)).data
              this.editarProjetoFuncionarioEmail.Projetos_funcionario_enviado_mail = true
              this.editarProjetoFuncionarioEmail.Projetos_funcionario_enviado_mail_data = new Date(Date.now())
              this.editarProjetoFuncionarioEmail.Projetos_funcionario_enviado_mail_quem = this.pjglobal.Funcionario.funcionario_email
              await ProjetosFuncionarioService.put(this.editarProjetoFuncionarioEmail)
            }

            if (this.dadosEmail.checkedEmailCliente == true) {
              this.editarProjetoFuncionarioEmailCliente = (await ProjetosFuncionarioService.show(this.pjglobal.Projetos_funcionario_id)).data
              this.editarProjetoFuncionarioEmailCliente.Projetos_funcionario_enviado_mail_cliente = true
              this.editarProjetoFuncionarioEmailCliente.Projetos_funcionario_enviado_mail_cliente_data = new Date(Date.now())
              this.editarProjetoFuncionarioEmailCliente.Projetos_funcionario_enviado_mail_cliente_quem = this.cliente.email
              await ProjetosFuncionarioService.put(this.editarProjetoFuncionarioEmailCliente)
            }    
            
            this.alertEmailEnviado = true
            document.location.reload(true)
            await ServiceEmails.sendEmail(this.dadosEmail)

          } catch (error) {
              this.alertEmailEnviado = false
          }
      },
      async QuemEnviamosEmail(id) {
        this.editarProjetoFuncionarioEmail = (await ProjetosFuncionarioService.show(id)).data
        this.editarProjetoFuncionarioEmailCliente = (await ProjetosFuncionarioService.show(id)).data
        this.dialogQuemEnviamosEmail = true
      },
    }
  }
  </script>
  <style>
  .interve .v-list-item {
    padding: 0 !important;
  }
  .editr--toolbar{
    display: none !important;
  }
  .classbotaocriareditarmarcaçãored {
    background-color: red !important;
    border-color: red !important;
    color: white !important;
  }
  .classbotaocriareditarmarcaçãoazul {
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
    color: white !important;
  }
  .botaodetalhesprojeto {
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
    color: white !important;
  }
  </style>