<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Projetos Por Concluir</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-toolbar-title><v-list-item link to="criarprojetos" class="v-list-item--link"><v-icon x-large >mdi-plus</v-icon></v-list-item></v-toolbar-title>
        </v-toolbar>
        <v-col cols="12 text-right" id="botaoup">
          <v-btn color="blue" class="ma-2 white--text" @click="projetosparadown">
            Data<v-icon right dark>mdi-chevron-up</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12 text-right d-none" id="botaodown">
          <v-btn color="blue" class="ma-2 white--text" @click="projetosparaup">
            Data<v-icon right dark>mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-select @change="getUserProjetos" v-model="select" :items="funcionarios" item-text="funcionario_nome" item-value="funcionario_id" filled outlined label="Interveniente">
            <template v-slot:prepend-item><v-list-item ripple @click="getProjetos" class="v-list-item--link">
              <v-list-item-content><v-list-item-title>Todas</v-list-item-title></v-list-item-content></v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersProjetos" :items="userprojetos">
            <template v-slot:[`item.projeto`]="{ item }">
              <span>{{ item.Projetos_ano }}/{{ item.Projetos_numero }}</span>
            </template>
            <template v-slot:[`item.cliente`]="{ item }">
              <div v-for="cln in clientes" :key="cln.no">
                <span v-if="cln.no == item.Projetos_cliente">{{ cln.nome }}</span>
              </div>
            </template>
            <template v-slot:[`item.descricao`]="{ item }">
              <span v-if="item.Projetos_descricao != null" v-html="item.Projetos_descricao.substring(0,60)"></span>
            </template>
            <template v-slot:[`item.dataregisto`]="{ item }">
              {{ formatDate(item.Projetos_data_registo) }}
            </template>
            <template v-slot:[`item.intervenientes`]="{ item }">
              <div v-for="func in item.Projetos_Funcionarios" :key="func.Projetos_funcionario_id">
                <template v-if="func.Projetos_funcionario_terminado == 0">
                  {{ func.Funcionario.funcionario_nome }}
                </template>
              </div>
            </template>
            <template v-slot:[`item.estado`]="{ item }">
              <div v-for="estados in estados" :key="estados.Estado_id">
                <span v-if="estados.Estado_id == item.Projetos_estado">{{ estados.Estado_descricao }}</span>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="blue" class="m-2" @click="navigateTo({name: 'resumosprojetos', params:{idEnviado: item.Projetos_id}})">mdi-file-find</v-icon>
              <v-icon color="green" class="m-2" @click="navigateTo({name: 'editarprojetos', params:{idEnviado: item.Projetos_id}})">mdi-pencil</v-icon>
              <v-icon color="red" class="m-2" @click="apagarProjeto(item.Projetos_id)">mdi-delete</v-icon>
              <!--
              <v-icon v-if="item.Projetos_concluida != 1" color="red">mdi-close</v-icon>
              <v-icon v-else color="green">mdi-check</v-icon>
              -->
            </template>
          </v-data-table>
        </v-col>
      </v-card>
      <!-- Dialog apagar projeto -->
      <v-dialog v-model="dialogApagarProjeto">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogApagarProjeto = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <span>Quer apagar o Projeto?</span>
          </v-col>
          <v-col cols="12">
            <v-alert v-model="alertProjetoApagada" dismissible type="success">Projeto apagado com sucesso</v-alert>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="confirmarApagarProjeto">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
  
<script>
//  Importar o service necessário
import ProjetosService from '@/services/ProjetosService'
import FuncionarioService from '@/services/FuncionarioService'
import ClientesService from '@/services/ClientesService'
import EstadosService from '@/services/EstadosService'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.userprojetos = (await ProjetosService.projetosporconcluirasc()).data
    this.funcionarios = (await FuncionarioService.index()).data
    this.estados = (await EstadosService.index()).data
    if (this.userprojetos.Projetos_descricao == null) {
      this.userprojetos.Projetos_descricao = ''
    }
    //nomes clientes
    var ola = []
    //nome clientes projetos
    for (var t = 0; t < this.userprojetos.length; t++) {
        ola.push(this.userprojetos[t].Projetos_cliente)
    }
    this.clientesid = [...new Set(ola)]
    this.clientes = (await ClientesService.clientesids(this.clientesid)).data
  },
  data () {
    return {
      clientesid: [],
      clientes: [],
      estados: [],
      funcionarios: [],
      userprojetos: [],
      headersProjetos: [
        { text: "Projeto", value: "projeto", sortable: false },
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Data Registo", value: "dataregisto", sortable: false },
        { text: "Intervenientes", value: "intervenientes", sortable: false },
        { text: "Estado", value: "estado", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      select:'',
      dialogApagarProjeto: false,
      idprojetoglobal: '',
      alertProjetoApagada: false
    }
  },
  methods: {
    // formatar data
    formatDate (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    navigateTo (route) {
      this.$store.dispatch('setProjeto', {  
        idprojeto: route.params.idEnviado
      })
      this.$router.push(route)
    },
    // ir buscar todos os projetos
    async getProjetos () {
      this.userprojetos = (await ProjetosService.projetosporconcluirasc()).data
      this.select = ''
    },
    // ir buscar os projetos por este id funcionario
    async getUserProjetos (idfunc) {
      this.userprojetos = (await ProjetosService.userprojetos(idfunc)).data
    },
    // ir buscar projetos por ordem descendente
    async projetosparadown () {
      if (this.select == '') {
        this.userprojetos = (await ProjetosService.projetosporconcluirdesc()).data
      } else {
        this.userprojetos = (await ProjetosService.userprojetosdesc(this.select)).data
      }
      var element = document.getElementById("botaoup")
      element.classList.add("d-none")
      var element2 = document.getElementById("botaodown")
      element2.classList.remove("d-none")
    },
    // ir buscar projetos por ordem crecente
    async projetosparaup () {
      if (this.select == '') {
        this.userprojetos = (await ProjetosService.projetosporconcluirasc()).data
      } else {
        this.userprojetos = (await ProjetosService.userprojetosasc(this.select)).data
      }
      var element3 = document.getElementById("botaoup")
      element3.classList.remove("d-none")
      var element4 = document.getElementById("botaodown")
      element4.classList.add("d-none")
    },
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    //apagar projeto
    async apagarProjeto (id) {
      this.dialogApagarProjeto = true
      this.idprojetoglobal = id
    },
    //confirmar apagar projeto
    async confirmarApagarProjeto () {
      this.alertProjetoApagada = true
      document.location.reload(true)
      await ProjetosService.delete(this.idprojetoglobal)
    }
  }
}
</script>
<style>
.v-text-field{
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}
</style>