import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import sharedMutations from 'vuex-shared-mutations'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, {
          secure: false
        }),
        removeItem: key => Cookies.remove(key)
      }
    }),
    sharedMutations({
      predicate: ['setToken', 'setUser', 'setTeste', 'setTarefa', 'setMarcacao', 'setProjeto', 'setTicket']
    })
  ],
  strict: true,
  state: {
    token: null,
    user: null,
    isUserLoggedIn: false,
    teste: null,
    tarefa:null,
    marcacao: null,
    projeto: null,
    ticket: null
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      if (token) {
        state.isUserLoggedIn = true
      } else {
        state.isUserLoggedIn = false
      }
    },
    setUser (state, user) {
      state.user = user
    },
    setTeste (state, teste) {
      state.teste = teste
    },
    setTarefa (state, tarefa) {
      state.tarefa = tarefa
    },
    setMarcacao (state, marcacao) {
      state.marcacao = marcacao
    },
    setProjeto (state, projeto) {
      state.projeto = projeto
    },
    setTicket (state, ticket) {
      state.ticket = ticket
    }
  },
  actions: {
    setToken ({commit}, token) {
      commit('setToken', token)
    },
    setUser ({commit}, user) {
      commit('setUser', user)
    },
    setTeste ({commit}, teste) {
      commit('setTeste', teste)
    },
    setTarefa ({commit}, tarefa) {
      commit('setTarefa', tarefa)
    },
    setMarcacao ({commit}, marcacao) {
      commit('setMarcacao', marcacao)
    },
    setProjeto ({commit}, projeto) {
      commit('setProjeto', projeto)
    },
    setTicket ({commit}, ticket) {
      commit('setTicket', ticket)
    }
  }
})
