import Api from '@/services/Api'

export default {
  index () {
    return Api().get('tarefasfuncionarioobs')
  },
  // criar obs
  post (elementos) {
    return Api().post('tarefasfuncionarioobs', elementos)
  },
  // apagar obs
  delete (id) {
    return Api().delete(`tarefasfuncionarioobs/${id}`, id)
  },
  // guardar obs por este id
  put (id) {
    return Api().put(`tarefasfuncionarioobs/${id.tarefas_Funcionario_Obs_ID}`, id)
  },
  // obs com este id
  show (id) {
    return Api().get(`tarefasfuncionarioobs/${id}`)
  },
  // tarefa funcionario obs com este id
  tarefafuncobs (id) {
    return Api().get(`tarefasfuncionarioobsp/${id}`)
  }
}
