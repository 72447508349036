<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Calendário</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-toolbar-title id="eventosindividualrefresh"><v-list-item class="v-list-item--link" @change="pesquisaeventos" @click="eventosindividual"><v-icon x-large >mdi-refresh</v-icon></v-list-item></v-toolbar-title>
          <v-toolbar-title id="eventostodosrefresh" class="d-none"><v-list-item class="v-list-item--link" @change="pesquisaeventos" @click="eventostodos"><v-icon x-large >mdi-refresh</v-icon></v-list-item></v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-col cols="12">
            <label>Data</label>
            <span @click="pesquisaeventos"><MYdatetime type="date" v-model="dataselecionada.data" ></MYdatetime></span>
            <span id="selectfuncionario"><v-select class="pt-5" @change="pesquisaeventos" v-model="select" :items="funcionarios" item-text="funcionario_nome" item-value="funcionario_id" filled outlined label="Interveniente"></v-select></span>
          </v-col>
          <v-col cols="12">
            <v-calendar ref="calendar" :first-interval="8" :interval-count="13" :short-intervals="false" :locale="'pt-PT'" v-model="focus" color="primary" :type="type" category-show-all :categories="funcionariosnome" :events="events" @change="pesquisaeventos">
              <template v-slot:event="{ event }">
                <div @click="eventsModal(event)">
                  <div >{{ event.cliente }}</div>
                  <div @click="eventsModal">Horário : {{ formatDate2(event.start) }}h - {{ formatDate2(event.end) }}h</div>
                </div>
              </template>
            </v-calendar>
          </v-col>
         </v-card>
         <!-- Dialog Eventos Descricao -->
         <v-dialog v-model="dialogEventosDescricao">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Evento</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogEventosDescricao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12 eventomodal" v-for="evento in eventoescolhido" :key="evento.id">
                <div><b>Cliente:</b> <span>{{ evento.cliente }}</span></div>
                <div><b>Data:</b> <span>{{ formatDate(evento.start) }}</span></div>
                <div><b>Horário:</b> <span>{{ formatDate2(evento.start) }}h - </span><span>{{ formatDate2(evento.end) }}h</span></div>
                <div><b>Descrição:</b> <span v-html="evento.descricao"></span></div>
              </v-col>
            </v-card>
          </v-dialog>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
//  Importar o service necessário
import MarcacoesFuncionarioService from '@/services/MarcacoesFuncionarioService'
import MarcacoesPhcService from '@/services/MarcacoesPhcService'
import MarcacoesService from '@/services/MarcacoesService'
import FuncionarioService from '@/services/FuncionarioService'
import TblUsuariosService from '@/services/TblUsuariosService'
import ClientesService from '@/services/ClientesService'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.$refs.calendar.checkChange()
    this.funcionarios = (await FuncionarioService.index()).data
    this.usermarcacoes = (await MarcacoesService.marcacoesporconcluirasc()).data
    for (var i = 0; i < this.funcionarios.length; i++) {
      this.funcionariosnome.push(this.funcionarios[i].funcionario_nome)
    }
  },
  data () {
    return {
      focus: '',
      select: this.$store.state.user.funcionario,
      dataselecionada: {
        data: new Date(Date.now()).toISOString()
      },
      events: [],
      type: 'day',
      funcionarios: [],
      funcionariosnome: [],
      datafuncselecionadatarefas: [],
      datafuncselecionadaphc: [],
      userdatamarcacoesfuncionariophc: [],
      userdatamarcacoesfuncionariotarefas: [],
      eventoescolhido: [],
      clientesid: [],
      clientes: [],
      dialogEventosDescricao: false,
      contnum: 0
    }
  },
  methods: {
    // formatar data
    formatDate (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    // formatar data2
    formatDate2 (date) {
      const options = {hour: 'numeric', minute: 'numeric'}
      return new Date(date).toLocaleTimeString('pt-PT', options)
    },
    //abrir modal eventos descrição
    async eventsModal (eventoesco) {
      this.dialogEventosDescricao = true
      const ola = []
      ola.push(eventoesco)
      this.eventoescolhido = [...new Set(ola)]
    },
    async pesquisaeventos () {
      if (this.contnum == 0) {
        // marcações com funcionario
        const events = []
        const dataselecionadamarcacoes = (this.dataselecionada.data)
        const dataselecionadamarcacoesphc = (this.dataselecionada.data)
        var datafocus = (new Date(this.dataselecionada.data).toJSON().slice(0, 10))
        this.focus = datafocus
        var elemprece = {idfunc: this.select, dataselc: dataselecionadamarcacoes}
        this.userdatamarcacoesfuncionariotarefas = (await MarcacoesFuncionarioService.userdatamarcacoesfuncionariotarefas(elemprece)).data
        this.usuarios = (await TblUsuariosService.index()).data
        for (var off = 0; off < this.usuarios.length; off++) { 
          if (this.usuarios[off].funcionario == this.select) {
            var tecnicophcselecionado = this.usuarios[off].tecnico_PHC
          }
        }
        var elemprecephc = {tecnicophcslec: tecnicophcselecionado, dataselc: dataselecionadamarcacoesphc}
        this.userdatamarcacoesfuncionariophc = (await MarcacoesPhcService.userdatamarcacoesfuncionariophc(elemprecephc)).data
        //nomes clientes
        var olaaa = []
        //nome clientes marcações
        for (var mm = 0; mm < this.datafuncselecionadatarefas.length; mm++) {
          olaaa.push(this.datafuncselecionadatarefas[mm].Marcaco.Marcacoes_cliente)
        }
        this.clientesid = [...new Set(olaaa)]
        this.clientes = (await ClientesService.clientesids(this.clientesid)).data
        // marcações tarefas
        for (var jj = 0; jj < this.userdatamarcacoesfuncionariotarefas.length; jj++) {
          for (var cl = 0; cl < this.clientes.length; cl++) {
            if (this.clientes[cl].no == this.userdatamarcacoesfuncionariotarefas[jj].Marcaco.Marcacoes_cliente) {
              var nomecliente = this.clientes[cl].nome
              var datainiciotarefas = (new Date(this.userdatamarcacoesfuncionariotarefas[jj].Marcacoes_funcionario_data_inicio).toJSON().slice(0, 10) + ' ' + new Date(this.datafuncselecionadatarefas[jj].Marcacoes_funcionario_hora_inicio).toJSON().slice(11, 16))
              var datafimtarefas = (new Date(this.userdatamarcacoesfuncionariotarefas[jj].Marcacoes_funcionario_data_fim).toJSON().slice(0, 10) + ' ' + new Date(this.datafuncselecionadatarefas[jj].Marcacoes_funcionario_hora_fim).toJSON().slice(11, 16))
              events.push({
                id: this.userdatamarcacoesfuncionariotarefas[jj].Marcaco.Marcacoes_id,
                cliente: nomecliente,
                descricao: this.userdatamarcacoesfuncionariotarefas[jj].Marcaco.Marcacoes_descricao,
                start: datainiciotarefas,
                end: datafimtarefas,
                color: 'yellow'
              })
            }
          }
        }
        // marcações phc
        for (var aa = 0; aa < this.userdatamarcacoesfuncionariophc.length; aa++) {
          var datainiciophc =  (new Date(this.userdatamarcacoesfuncionariophc[aa].data).toJSON().slice(0, 10) + ' ' + this.userdatamarcacoesfuncionariophc[aa].hinicio)
          var datafimphc =  (new Date(this.userdatamarcacoesfuncionariophc[aa].data).toJSON().slice(0, 10) + ' ' + this.userdatamarcacoesfuncionariophc[aa].hfim)
          events.push({
            id: this.userdatamarcacoesfuncionariophc[aa].mxid,
            cliente: this.userdatamarcacoesfuncionariophc[aa].clnome,
            descricao: this.userdatamarcacoesfuncionariophc[aa].texto,
            start: datainiciophc,
            end: datafimphc,
            color: 'red'
          })
        }
        this.events = events
      } else {
        // marcações todas
        const events = []
        const dataselecionadamarcacoes = (this.dataselecionada.data)
        const dataselecionadamarcacoesphc = (this.dataselecionada.data)
        var datafocus2 = (new Date(this.dataselecionada.data).toJSON().slice(0, 10))
        this.focus = datafocus2
        this.datafuncselecionadatarefas = (await MarcacoesFuncionarioService.datamarcacoesfuncionariotarefas(dataselecionadamarcacoes)).data
        this.datafuncselecionadaphc = (await MarcacoesPhcService.datamarcacoesfuncionariophc(dataselecionadamarcacoesphc)).data
        this.usuarios = (await TblUsuariosService.index()).data
        //nomes clientes
        var olaa = []
        //nome clientes marcações
        for (var m = 0; m < this.datafuncselecionadatarefas.length; m++) {
          olaa.push(this.datafuncselecionadatarefas[m].Marcaco.Marcacoes_cliente)
        }
        this.clientesid = [...new Set(olaa)]
        this.clientes = (await ClientesService.clientesids(this.clientesid)).data
        // marcações tarefas
        for (var iii = 0; iii < this.datafuncselecionadatarefas.length; iii++) {
          for (var cl2 = 0; cl2 < this.clientes.length; cl2++) {
            if (this.clientes[cl2].no == this.datafuncselecionadatarefas[iii].Marcaco.Marcacoes_cliente) {
              var nomecliente2 = this.clientes[cl2].nome
              for (var tt = 0; tt < this.usuarios.length; tt++) {
                if (this.usuarios[tt].funcionario == this.datafuncselecionadatarefas[iii].Marcacoes_funcionario_funcionario) {
                  var datainiciotarefas2 = (new Date(this.datafuncselecionadatarefas[iii].Marcacoes_funcionario_data_inicio).toJSON().slice(0, 10) + ' ' + new Date(this.datafuncselecionadatarefas[iii].Marcacoes_funcionario_hora_inicio).toJSON().slice(11, 16))
                  var datafimtarefas2 = (new Date(this.datafuncselecionadatarefas[iii].Marcacoes_funcionario_data_fim).toJSON().slice(0, 10) + ' ' + new Date(this.datafuncselecionadatarefas[iii].Marcacoes_funcionario_hora_fim).toJSON().slice(11, 16))
                  events.push({
                    id: this.datafuncselecionadatarefas[iii].Marcaco.Marcacoes_id,
                    cliente: nomecliente2,
                    descricao: this.datafuncselecionadatarefas[iii].Marcaco.Marcacoes_descricao,
                    start: datainiciotarefas2,
                    end: datafimtarefas2,
                    color: 'yellow',
                    category: this.usuarios[tt].Usuario,
                  })
                }
              }
            }
          }
        }
        // marcações phc
        for (var of = 0; of < this.datafuncselecionadaphc.length; of++) {
          for (var offff = 0; offff < this.usuarios.length; offff++) { 
            if (this.usuarios[offff].tecnico_PHC == this.datafuncselecionadaphc[of].tecnico) {
              var datainiciophc2 =  (new Date(this.datafuncselecionadaphc[of].data).toJSON().slice(0, 10) + ' ' + this.datafuncselecionadaphc[of].hinicio)
              var datafimphc2 =  (new Date(this.datafuncselecionadaphc[of].data).toJSON().slice(0, 10) + ' ' + this.datafuncselecionadaphc[of].hfim)
              events.push({
                id: this.datafuncselecionadaphc[of].mxid,
                cliente: this.datafuncselecionadaphc[of].clnome,
                descricao: this.datafuncselecionadaphc[of].texto,
                start: datainiciophc2,
                end: datafimphc2,
                color: 'red',
                category: this.usuarios[offff].Usuario,
              })
            }
          }
        }
        this.events = events
      }
    },
    async eventosindividual () {
      this.type = 'category'
      this.contnum = 1
      var element = document.getElementById("selectfuncionario")
      element.classList.add("d-none")
      var element2 = document.getElementById("eventosindividualrefresh")
      element2.classList.add("d-none")
      var element3 = document.getElementById("eventostodosrefresh")
      element3.classList.remove("d-none")
    },
    async eventostodos () {
      this.type = 'day'
      this.contnum = 0
      var element = document.getElementById("selectfuncionario")
      element.classList.remove("d-none")
      var element2 = document.getElementById("eventosindividualrefresh")
      element2.classList.remove("d-none")
      var element3 = document.getElementById("eventostodosrefresh")
      element3.classList.add("d-none")
    }
  }
}
</script>
<style>
.v-event-timed-container{
  margin: 0 !important;
}
.v-calendar .v-event-timed{
  padding: 5px !important;
}
.eventomodal span {
  font-size: 13px !important;
}
.v-application .transparent {
  background-color: #a8aba6 !important;
  color: white;
}
.theme--light.v-calendar-daily .v-calendar-daily_head-day{
  color: #a8aba6 !important;
}
</style>