<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn">
      <v-flex>
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Resumo do projeto</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-col cols="12">
            <label>Cliente</label>
            <v-text-field :value="clientes.nome" disabled filled outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <label>Projeto</label>
            <v-text-field :value="resumoprojeto.Projetos_ano + '/' + resumoprojeto.Projetos_numero" disabled filled outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-toolbar-title class="text-right">Total tempo: <b><span>{{ valortotaldetempo }}</span></b></v-toolbar-title>
          </v-col>
          <v-col cols="12">
            <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersTarefasMarcacoes" :items="arraytarefasmarcacoes">
              <template v-slot:[`item.descricao`]="{ item }">
                <span v-if="item.descricao != null" v-html="item.descricao.substring(0,60)"></span>
              </template>
              <template v-slot:[`item.trabalhorealizado`]="{ item }">
                <span v-if="item.trabalhorealizado != null" v-html="item.trabalhorealizado.substring(0,200)"></span>
              </template>
              <template v-slot:[`item.inicio`]="{ item }">
                <span>{{ formatDate3(item.inicio) }}</span>
              </template>
              <template v-slot:[`item.fim`]="{ item }">
                <span>{{ formatDate3(item.fim) }}</span>
              </template>
              <template v-slot:[`item.tempo`]="{ item }">
                <span>{{ formatDate4(item.tempo) }}</span>
              </template>
              <template v-slot:[`item.pat`]="{ item }">
                <span>{{ item.pat }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-card>
      </v-flex>
    </v-layout>
  </template>
    
<script>
//  Importar o service necessário
import ProjetosService from '@/services/ProjetosService'
import TarefasService from '@/services/TarefasService'
import ClientesService from '@/services/ClientesService'
import TemposAgoraService from '@/services/TemposAgoraService'
import store from '@/store/store'
export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      }
        this.resumoprojeto = (await ProjetosService.show(this.$store.state.projeto.idprojeto)).data
        this.tarefasprojeto = (await TarefasService.tarefasprojeto(this.$store.state.projeto.idprojeto)).data
        this.clientes = (await ClientesService.show(this.resumoprojeto.Projetos_cliente)).data
        for (var i = 0; i < this.tarefasprojeto.length; i++) {

          this.temposagoratarefas = (await TemposAgoraService.temposagoratarefas(this.tarefasprojeto[i].tarefa_id)).data

          for (var o = 0; o < this.temposagoratarefas.length; o++) {
            this.arraytarefasmarcacoes.push({
                funcionario: this.temposagoratarefas[o].Tempos_Agora_Funcionario,
                descricao: this.temposagoratarefas[o].Tempos_Agora_Descricao,
                trabalhorealizado: this.temposagoratarefas[o].Tempos_Agora_Trabalho_Realizado,
                inicio: this.temposagoratarefas[o].Tempos_Agora_Inicio,
                fim: this.temposagoratarefas[o].Tempos_Agora_fim,
                tempo: this.temposagoratarefas[o].Tempos_Agora_Tempo_Efetivo,
                pat: this.temposagoratarefas[o].Tempos_Agora_PAT
            });
          }
            
        }
        //tempo total
        var totalSegundos = 0; // Inicializa o total de segundos
        for (var s = 0; s < this.arraytarefasmarcacoes.length; s++) {
          var tempototal = this.formatDate4(this.arraytarefasmarcacoes[s].tempo);
          var partesTempototal = tempototal.split(':'); // Divide o tempo em partes (horas, minutos, segundos)
          var horas = parseInt(partesTempototal[0], 10);
          var minutos = parseInt(partesTempototal[1], 10);
          var segundos = parseInt(partesTempototal[2], 10);
          // Converte tudo para segundos e soma
          totalSegundos += horas * 3600 + minutos * 60 + segundos;
        }
          // Converte o total de segundos de volta para o formato de tempo desejado
          var totalTempoFormatado = this.formatarTempo(totalSegundos);
          // caixa de tempo total
          this.valortotaldetempo = totalTempoFormatado
    },
    data () {
        return {
            arraytarefasmarcacoes: [],
            resumoprojeto: [],
            clientes: [],
            tarefasprojeto: [],
            temposagoratarefas: [],
            valortotaldetempo: '',
            headersTarefasMarcacoes: [
                { text: "Funcionario", value: "funcionario", sortable: false },
                { text: "Descrição", value: "descricao", sortable: false },
                { text: "Trabalho Realizado", value: "trabalhorealizado", sortable: false },
                { text: "Início", value: "inicio", sortable: false },
                { text: "Fim", value: "fim", sortable: false },
                { text: "Tempo", value: "tempo", sortable: false },
                { text: "P.A.T", value: "pat", sortable: false },
            ],
        }
    },
    methods: {
      // formatar data3
      formatDate3 (date) {
        const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}
        return new Date(date).toLocaleDateString('pt-PT', options)
      },
      // formatar data4
      formatDate4 (date) {
        return new Date(date).toJSON().slice(11, 19)
      },
      // Função para formatar o tempo no formato HH:mm:ss
      formatarTempo(totalSegundos) {
        var horas = Math.floor(totalSegundos / 3600);
        var minutos = Math.floor((totalSegundos % 3600) / 60);
        var segundos = totalSegundos % 60;

        return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + segundos.toString().padStart(2, '0');
      }
    }
}
</script>