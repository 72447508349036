import ApiTickets from '@/services/ApiTickets'
const path = 'tickets'

export default {
    list(page, name, company, nif, sDate, eDate,converted) {
        return ApiTickets().get(path + '/list', {
            params: {
                name, company, nif, sDate, eDate, page,converted
            }
        })
    },
    create(data) {
        return ApiTickets().post(
            path,
            data
        )
    },
    convert(ticketId, userId) {
        return ApiTickets().post(
            path + '/convert',
            {
                ticketId,
                userId
            }
        )
    }
}
