<template>
  <v-layout align-center justify-center v-if="!$store.state.isUserLoggedIn">
     <v-flex xs12 sm12 md8>
        <v-card>
           <v-toolbar dark color="primary">
              <v-toolbar-title>Login</v-toolbar-title>
           </v-toolbar>
           <v-card-text>
              <v-form>
                <v-col cols="12">
                  <v-alert v-model="alertLoginInvalido" dismissible type="error">Login inválido</v-alert>
                </v-col>
                <v-col cols="12">
                 <label>Login</label>
                 <v-text-field name="Usuario" clearable filled outlined type="text" v-model="Usuario"></v-text-field>
                </v-col>
                <v-col cols="12">
                 <label>Password</label>
                 <v-text-field name="Senha_Desenc" clearable filled outlined type="password" v-model="Senha_Desenc"></v-text-field>
                </v-col> 
                </v-form>
           </v-card-text>
           <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="login" color="primary">Login</v-btn>
           </v-card-actions>
        </v-card>
     </v-flex>
  </v-layout>
 </template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data () {
    return {
      Usuario: '',
      Senha_Desenc: '',
      alertLoginInvalido: false
    }
  },
  methods: {
    async login () {
      try {
        const response = await AuthenticationService.login({
          Usuario: this.Usuario,
          Senha_Desenc: this.Senha_Desenc
        })
        this.$store.dispatch('setToken', response.data.token)
        this.$store.dispatch('setUser', response.data.user)
        this.$router.push({
          name: 'myhome'
        })
      } catch (error) {
        this.alertLoginInvalido = true
      }
    }
  }
}
</script>
<style>
</style>