import Api from '@/services/Api'

export default {
  // todas as marcações
  index () {
    return Api().get('marcacoes')
  },
  // marcações por concluir ordem crescente
  marcacoesporconcluirasc () {
    return Api().get('marcacoesporconcluirasc')
  },
  // marcações por concluir ordem decrescente
  marcacoesporconcluirdesc () {
    return Api().get('marcacoesporconcluirdesc')
  },
  // ultima marcacao da bd
  ultimamarcacao () {
    return Api().get('ultimamarcacao')
  },
  // marcações deste utilizador logado
  usermarcacoes (idutilizador) {
    return Api().get('usermarcacoes',{ 
      params: {
        idutilizador
      }
    })
  },
  // marcações asc deste utilizador logado
  usermarcacoesasc (idutilizador) {
    return Api().get('usermarcacoesasc',{ 
      params: {
        idutilizador
      }
    })
  },
  // marcações desc deste utilizador logado
  usermarcacoesdesc (idutilizador) {
    return Api().get('usermarcacoesdesc',{ 
      params: {
        idutilizador
      }
    })
  },
  // marcacoes por cliente
  marcacoescliente (idcliente) {
    return Api().get('marcacoescliente',{ 
      params: {
        idcliente
      }
    })
  },
  // marcacoes por tarefa
  marcacoestarefa (idtarefa) {
    return Api().get('marcacoestarefa',{ 
      params: {
        idtarefa
      }
    })
  },
  // marcacoes por projeto
  marcacoesprojeto (idprojeto) {
    return Api().get('marcacoesprojeto',{ 
      params: {
        idprojeto
      }
    })
  },
  // update marcações
  put (id) {
    return Api().put(`marcacao/${id.Marcacoes_id}`, id)
  },
  // criar marcacao
  post (elementos) {
    return Api().post('marcacao', elementos)
  },
  // marcação com este id
  show (id) {
    return Api().get(`marcacao/${id}`)
  },
  // apagar marcação
  delete (id) {
    return Api().delete(`marcacao/${id}`, id)
  }
}
