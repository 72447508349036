<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Funcionarios Estado</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-col cols="12" v-for="funcocup in funcionariosOcupados" :key="funcocup.funcionario_id" class="mt-5">
          <div v-if="funcocup.funcionario_estado == 'Ocupado'" class="d-flex flex-no-wrap justify-space-between Ocupado">
            <div class="w-100">
              <v-card-title class="text-h5">{{ funcocup.funcionario_nome }}</v-card-title>
              <div v-for="tempoagorant in temposagoraNaoTerminado" :key="tempoagorant.Tempos_Agora_ID">
                <template v-if="funcocup.funcionario_nome == tempoagorant.Tempos_Agora_Funcionario">
                  <v-card-subtitle class="pt-0 pb-0">{{ tempoagorant.Tempos_Agora_Cliente }}</v-card-subtitle>
                  <v-card-subtitle class="pt-0 pb-0"><span class="textwhite" v-html="tempoagorant.Tempos_Agora_Descricao"></span></v-card-subtitle>
                </template>
              </div>
              <v-card-actions class="text-center botoesestado">
                <v-btn v-if="funcocup.funcionario_Deslocacao != 0" class="ma-2" color="blue" dark><v-icon dark>mdi-car</v-icon></v-btn>
                <v-btn v-if="funcocup.funcionario_Remoto != 0" class="ma-2" color="blue" dark><v-icon dark>mdi-laptop</v-icon></v-btn>
                <v-btn v-if="funcocup.funcionario_telefone != 0" class="ma-2" color="blue" dark><v-icon dark>mdi-phone</v-icon></v-btn>
              </v-card-actions>
              <v-card-actions class="text-rigth botoesvisualizar">
                <v-icon color="white" title="Ver tempos do dia" @click="temposDiaModal(funcocup)">mdi-eye</v-icon>
              </v-card-actions>
            </div>
          </div>
        </v-col>
        <v-col cols="12" v-for="funclivr in funcionariosLivres" :key="funclivr.funcionario_id" class="mt-5">
          <div v-if="funclivr.funcionario_estado == 'Livre'" class="d-flex flex-no-wrap justify-space-between Livre">
            <div class="w-100">
              <v-card-title class="text-h5">{{ funclivr.funcionario_nome }}</v-card-title>
              <v-card-actions class="text-rigth botoesvisualizar">
                <v-icon color="white" title="Ver tempos do dia" @click="temposDiaModal(funclivr)">mdi-eye</v-icon>
              </v-card-actions>
            </div>
          </div>
        </v-col>
      </v-card>
      <!-- Dialog Tempos do dia -->
      <v-dialog v-model="dialogTemposDia">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Tempos do dia</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogTemposDia = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select @change="pesquisaTemposAgora" v-model="pesquisatemposagora.temposagorafuncionario" :items="funcionarios" item-text="funcionario_nome" item-value="funcionario_nome" filled outlined label="Interveniente"></v-select>
              </v-col> 
              <v-col cols="6">
                <span @click="pesquisaTemposAgora">
                  <label>Data Início</label>
                  <MYdatetime type="date" v-model="pesquisatemposagora.temposagorainicio"></MYdatetime>
                </span>
              </v-col> 
              <v-col cols="6">
                <span @click="pesquisaTemposAgora">
                  <label>Data Fim</label>
                  <MYdatetime type="date" v-model="pesquisatemposagora.temposagorafim"></MYdatetime>
                </span>
              </v-col>
              <v-col cols="12">
                <v-alert v-model="alertHoraInicioMaior" dismissible type="error">A hora de inicio é maior que a de fim</v-alert>
              </v-col>
              <v-list-item-content class="col-12">
                <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersTemposAgora" :items="tempoAgoraFuncionarioData">
                  <template v-slot:[`item.cliente`]="{ item }">
                    {{ item.Tempos_Agora_Cliente }}
                  </template>
                  <template v-slot:[`item.descricao`]="{ item }">
                    <span v-if="item.Tempos_Agora_Descricao != null" v-html="item.Tempos_Agora_Descricao.substring(0,60)"></span>
                  </template>
                  <template v-slot:[`item.trabalho`]="{ item }">
                    <span v-if="item.Tempos_Agora_Trabalho_Realizado != null" v-html="item.Tempos_Agora_Trabalho_Realizado.substring(0,60)"></span>
                  </template>
                  <template v-slot:[`item.inicio`]="{ item }">
                    {{ formatDate3(item.Tempos_Agora_Inicio) }}
                  </template>
                  <template v-slot:[`item.fim`]="{ item }">
                    <span v-if="item.Tempos_Agora_fim != null">{{ formatDate3(item.Tempos_Agora_fim) }}</span>
                  </template>
                  <template v-slot:[`item.tempo`]="{ item }">
                    <span v-if="item.Tempos_Agora_Tempo_Efetivo != null">{{ formatDate4(item.Tempos_Agora_Tempo_Efetivo) }}</span>
                  </template>
                  <template v-slot:[`item.pat`]="{ item }">
                    {{ item.Tempos_Agora_PAT }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon class="m-2" v-if="item.Tempos_Agora_Terminado != 1" color="red">mdi-close</v-icon>
                    <v-icon class="m-2" v-else color="green">mdi-check</v-icon>
                    <v-icon class="m-2" color="blue" @click="temposAgoraView(item.Tempos_Agora_ID)">mdi-eye</v-icon>
                  </template>
                </v-data-table>
              </v-list-item-content>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Dialog Ver os tempos agora -->
      <v-dialog v-model="dialogViewTemposAgora">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Visualizar tempo</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogViewTemposAgora = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Início</label>
                  <v-text-field v-if="temposagoraview.Tempos_Agora_Inicio != null" :value="formatDate3(temposagoraview.Tempos_Agora_Inicio)" disabled filled outlined></v-text-field>
                  <v-text-field v-else disabled filled outlined></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Fim</label>
                  <v-text-field v-if="temposagoraview.Tempos_Agora_fim != null" :value="formatDate3(temposagoraview.Tempos_Agora_fim)" disabled filled outlined></v-text-field>
                  <v-text-field v-else disabled filled outlined></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Tempo total</label>
                  <v-text-field v-if="temposagoraview.Tempos_Agora_Tempo_Efetivo != null" :value="formatDate4(temposagoraview.Tempos_Agora_Tempo_Efetivo)" disabled filled outlined></v-text-field>
                  <v-text-field v-else disabled filled outlined></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Deslocação/Remoto/Telefone</label><br>
                  <v-icon v-if="temposagoraview.Tempos_Agora_Deslocacao == 1" color="green" class="pr-2">mdi-car</v-icon>
                  <v-icon v-if="temposagoraview.Tempos_Agora_Remoto == 1" color="green" class="pr-2">mdi-laptop</v-icon>
                  <v-icon v-if="temposagoraview.Tempos_Agora_Telefone == 1" color="green" class="pr-2">mdi-phone</v-icon>
                </v-col>
                <v-col cols="12">
                  <label>Cliente</label>
                  <v-text-field v-model="temposagoraview.Tempos_Agora_Cliente" disabled filled outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label>Descrição</label>
                  <wysiwyg v-model="temposagoraview.Tempos_Agora_Descricao"/>
                </v-col>
                <v-col cols="12">
                  <label>Trabalho Realizado</label>
                  <wysiwyg v-model="temposagoraview.Tempos_Agora_Trabalho_Realizado"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
  
<script>
//  Importar o service necessário
import FuncionarioService from '@/services/FuncionarioService'
import TemposAgoraService from '@/services/TemposAgoraService'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.funcionarios = (await FuncionarioService.index()).data
    this.funcionariosOcupados = (await FuncionarioService.funcionariosocupados()).data
    this.funcionariosLivres = (await FuncionarioService.funcionarioslivres()).data
    this.temposagoraNaoTerminado = (await TemposAgoraService.temposagoranaoterminado()).data
  },
  data () {
    return {
      funcionarios: [],
      funcionariosOcupados: [],
      funcionariosLivres: [],
      temposagoraNaoTerminado: [],
      tempoAgoraFuncionarioData: [],
      pesquisatemposagora: {
        temposagorafuncionario: '',
        temposagorainicio: new Date(Date.now()).toISOString(),
        temposagorafim: new Date(Date.now()).toISOString()
      },
      dialogTemposDia: false,
      alertHoraInicioMaior: false,
      dialogViewTemposAgora: false,
      temposagoraview: '',
      headersTemposAgora: [
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Trabalho Realizado", value: "trabalho", sortable: false },
        { text: "Inicio", value: "inicio", sortable: false },
        { text: "Fim", value: "fim", sortable: false },
        { text: "Tempo", value: "tempo", sortable: false },
        { text: "P.A.T", value: "pat", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  methods: {
    // formatar data3
    formatDate3 (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    // formatar data4
    formatDate4 (date) {
      return new Date(date).toJSON().slice(11, 19)
    },
    //abrir modal Tempos do dia
    async temposDiaModal (funcionarionome) {
      this.pesquisatemposagora.temposagorafuncionario = funcionarionome.funcionario_nome
      var sodatainicio = new Date(this.pesquisatemposagora.temposagorainicio).toJSON().slice(0, 10) + 'T00:00:00.000Z'
      var sodatafim = new Date(this.pesquisatemposagora.temposagorafim).toJSON().slice(0, 10) + 'T23:59:59.000Z'
      var elemfundata = {funcionarioslec: this.pesquisatemposagora.temposagorafuncionario, dataselcinicio: sodatainicio, dataselcfim: sodatafim}
      this.tempoAgoraFuncionarioData = (await TemposAgoraService.tempoagorafuncdata(elemfundata)).data
      this.dialogTemposDia = true
    },
    // pesquisa Tempos Agora
    async pesquisaTemposAgora () {
      var datainicioparse = Date.parse(this.pesquisatemposagora.temposagorainicio)
      var datafimparse = Date.parse(this.pesquisatemposagora.temposagorafim)
      // se a data de inicio for maior que a de fim
      if (datainicioparse > datafimparse) {
          this.alertHoraInicioMaior = true
      } else {
        this.alertHoraInicioMaior = false
        var sodatainicio = new Date(this.pesquisatemposagora.temposagorainicio).toJSON().slice(0, 10) + 'T00:00:00.000Z'
        var sodatafim = new Date(this.pesquisatemposagora.temposagorafim).toJSON().slice(0, 10) + 'T23:59:59.000Z'
        var elemfundata = {funcionarioslec: this.pesquisatemposagora.temposagorafuncionario, dataselcinicio: sodatainicio, dataselcfim: sodatafim}
        this.tempoAgoraFuncionarioData = (await TemposAgoraService.tempoagorafuncdata(elemfundata)).data
      }
    },
    async temposAgoraView (temposagoraid) {
      this.dialogViewTemposAgora = true
      this.temposagoraview = (await TemposAgoraService.show(temposagoraid)).data
    },
  }
}
</script>
<style>
.Ocupado {
  background-color: #c34242;
  color: white;
}

.Livre {
  background-color: #7ea73e;
  color: white;
}

.botoesestado {
  display: block;
}
.botoesvisualizar {
  display: block;
  text-align: right;
}
</style>