<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Clientes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-col cols="12">
          <v-autocomplete @change="getClientes" :items="clientes" item-text="nome" item-value="no" clearable filled outlined label="Cliente"></v-autocomplete>
        </v-col>
        <v-col cols="12" id="tabs-clientes">
          <v-tabs background-color="primary accent-4">
            <v-tab><v-icon left>mdi-account</v-icon>Geral</v-tab>
            <v-tab><v-icon left>mdi-note</v-icon>Tarefas</v-tab>
            <v-tab><v-icon left>mdi-post</v-icon>Marcações</v-tab>
            <v-tab><v-icon left>mdi-file-star</v-icon>Projetos</v-tab>
            <!--<v-tab><v-icon left>mdi-access-point</v-icon>Notas</v-tab>-->
            <!--<v-tab><v-icon left>mdi-access-point</v-icon>Anexos</v-tab>-->
            <!--<v-tab><v-icon left>mdi-access-point</v-icon>Dossier</v-tab>-->
            <!--<v-tab><v-icon left>mdi-access-point</v-icon>Cobranças</v-tab>-->
            <!--<v-tab><v-icon left>mdi-access-point</v-icon>Passes</v-tab>-->
            <!--<v-tab><v-icon left>mdi-access-point</v-icon>Orçamentos</v-tab>-->
            <v-tab><v-icon left>mdi-timer</v-icon>Tempos</v-tab>
            <!--Geral-->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-tabs background-color="primary accent-4" color="white">
                    <v-tab><v-icon left>mdi-account</v-icon>Dados empresa</v-tab>
                    <v-tab><v-icon left>mdi-phone</v-icon>Contactos</v-tab>
                    <v-tab><v-icon left>mdi-comment-multiple</v-icon>Observações</v-tab>
                    <v-tab><v-icon left>mdi-list-box-outline</v-icon>Pendentes</v-tab>
                    <!--<v-tab><v-icon left>mdi-lock</v-icon>Contratos</v-tab>-->
                    <!--<v-tab><v-icon left>mdi-lock</v-icon>Software/Equipamento</v-tab>-->
                    <!--<v-tab><v-icon left>mdi-lock</v-icon>Software</v-tab>-->
                    <!--<v-tab><v-icon left>mdi-lock</v-icon>CRM</v-tab>-->
                    <!--Dados empresa-->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-list>
                            <v-list-item class="row">
                                <v-list-item-content class="col-12 col-md-6">
                                  <span class="pb-3"><b>Cliente nº:</b> {{ clienteSelect.no }}</span>
                                  <span class="pb-3"><b>Contribuinte:</b> {{ clienteSelect.ncont }}</span>
                                  <span class="pb-3"><b>Nome 2:</b> {{ clienteSelect.nome2 }}</span>
                                  <span class="pb-3"><b>Morada:</b> {{ clienteSelect.morada }}</span>
                                  <span class="pb-3"><b>Localidade:</b> {{ clienteSelect.local }}</span>
                                  <span class="pb-3"><b>Código Postal:</b> {{ clienteSelect.codpost }}</span>
                                  <span class="pb-3"><b>Telefone:</b> <a :href="`tel:${clienteSelect.telefone}`"> {{ clienteSelect.telefone }}</a></span>
                                  <span class="pb-3"><b>Telemóvel:</b> <a :href="`tel:${clienteSelect.tlmvl}`"> {{ clienteSelect.tlmvl }}</a></span>
                                  <span class="pb-3">&nbsp;</span>
                                </v-list-item-content>
                                <v-list-item-content class="col-12 col-md-6">
                                  <span class="pb-3"><b>Email:</b> <a :href="`mailto:${clienteSelect.email}`"> {{ clienteSelect.email }}</a></span>
                                  <span class="pb-3"><b>WWW:</b> <a :href="`//${clienteSelect.url}`" target="_blank"> {{ clienteSelect.url }}</a></span>
                                  <span class="pb-3"><b>Vendedor:</b> {{ clienteSelect.vendedor }}nadaAccounts</span>
                                  <span class="pb-3"><b>Vencimento:</b> {{ clienteSelect.vencimento }} dias</span>
                                  <span class="pb-3"><b>Limite dias:</b> {{ clienteSelect.alimite }} dias</span>
                                  <span class="pb-3"><b>Desconto:</b> {{ clienteSelect.desconto }} €</span>
                                  <span class="pb-3"><b>Saldo:</b> {{ parseFloat(clienteSelect.esaldo).toFixed(2) }} €</span>
                                  <span class="pb-3"><b>Sede:</b> {{ clienteSelect.a }}nadaAccounts</span>
                                  <span class="pb-3"><b>Técnico:</b> {{ clienteSelect.a }}nadaAccounts</span>
                                </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!--Contactos-->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-list>
                            <v-list-item class="row">
                              <v-list-item-content class="col-12 col-md-3">
                                <span class="pb-3"><b>Contacto 1</b></span><br>
                                <span class="pb-3"><b>Nome: </b>{{ clienteSelect.contacto }}</span>
                                <span class="pb-3"><b>Função: </b>{{ clienteSelect.c1func }}</span>
                                <span class="pb-3"><b>Telemóvel: </b><a :href="`tel:${clienteSelect.c1tele}`">{{ clienteSelect.c1tele }}</a></span>
                                <span class="pb-3"><b>Fax: </b>{{ clienteSelect.c1fax }}</span>
                                <span class="pb-3"><b>Email: </b><a :href="`mailto:${clienteSelect.c1email}`">{{ clienteSelect.c1email }}</a></span>
                              </v-list-item-content>
                              <v-list-item-content class="col-12 col-md-3">
                                <span class="pb-3"><b>Contacto 2</b></span><br>
                                <span class="pb-3"><b>Nome: </b>{{ clienteSelect.c2tacto }}</span>
                                <span class="pb-3"><b>Função: </b>{{ clienteSelect.c2func }}</span>
                                <span class="pb-3"><b>Telemóvel: </b><a :href="`tel:${clienteSelect.c2tele}`">{{ clienteSelect.c2tele }}</a></span>
                                <span class="pb-3"><b>Fax: </b>{{ clienteSelect.c2fax }}</span>
                                <span class="pb-3"><b>Email: </b><a :href="`mailto:${clienteSelect.c2email}`">{{ clienteSelect.c2email }}</a></span>
                              </v-list-item-content>
                              <v-list-item-content class="col-12 col-md-3">
                                <span class="pb-3"><b>Contacto 3</b></span><br>
                                <span class="pb-3"><b>Nome: </b>{{ clienteSelect.c3tacto }}</span>
                                <span class="pb-3"><b>Função: </b>{{ clienteSelect.c3func }}</span>
                                <span class="pb-3"><b>Telemóvel: </b><a :href="`tel:${clienteSelect.c3tele}`">{{ clienteSelect.c3tele }}</a></span>
                                <span class="pb-3"><b>Fax: </b>{{ clienteSelect.c3fax }}</span>
                                <span class="pb-3"><b>Email: </b><a :href="`mailto:${clienteSelect.c3email}`">{{ clienteSelect.c3email }}</a></span>
                              </v-list-item-content>
                              <v-list-item-content class="col-12 col-md-3">
                                <span class="pb-3"><b>Contacto 4</b></span><br>
                                <span class="pb-3"><b>Nome: </b>{{ clienteSelect.cobtacto }}</span>
                                <span class="pb-3"><b>Função: </b>{{ clienteSelect.cobfunc }}</span>
                                <span class="pb-3"><b>Telemóvel: </b><a :href="`tel:${clienteSelect.cobtele}`">{{ clienteSelect.cobtele }}</a></span>
                                <span class="pb-3"><b>Fax: </b>{{ clienteSelect.cobfax }}</span>
                                <span class="pb-3"><b>Email: </b><a :href="`mailto:${clienteSelect.cobemail}`">{{ clienteSelect.cobemail }}</a></span>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!--Observações-->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-list>
                            <v-list-item class="row">
                              <v-list-item-content class="col-12">
                                <textarea rows="18" v-model="clienteSelect.obs" ></textarea>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!--Pendentes-->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-list>
                            <v-list-item class="row">
                                <v-list-item-content class="col-12">
                                  <v-toolbar-title class="pb-2 text-right">Total: <b><span>{{ parseFloat(clienteSelect.esaldo).toFixed(2) }} €</span></b></v-toolbar-title>
                                  <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersContasCorrente" :items="contascorrente">
                                    <template v-slot:[`item.documento`]="{ item }">
                                        {{ item.cmdesc }} {{item.nrdoc}}
                                    </template>
                                    <template v-slot:[`item.datadoc`]="{ item }">
                                        {{ formatDate(item.datalc) }}
                                    </template>
                                    <template v-slot:[`item.debitovalordoc`]="{ item }">
                                        {{ parseFloat(item.edeb).toFixed(2) }}€
                                    </template>
                                    <template v-slot:[`item.debitovalorliq`]="{ item }">
                                        {{ parseFloat(item.edebf).toFixed(2) }}€
                                    </template>
                                    <template v-slot:[`item.creditovalordoc`]="{ item }">
                                        {{ parseFloat(item.ecred).toFixed(2) }}€
                                    </template>
                                    <template v-slot:[`item.creditovalorliq`]="{ item }">
                                        {{ parseFloat(item.ecredf).toFixed(2) }}€
                                    </template>
                                    <template v-slot:[`item.total`]="{ item }">
                                        <span v-if="((item.edeb) - (item.edebf))-((item.ecred) - (item.ecredf)) != 0" class="corvermelho2">{{ parseFloat(((item.edeb) - (item.edebf))-((item.ecred) - (item.ecredf))).toFixed(2) }}€</span>
                                        <span v-else>{{ parseFloat(((item.edeb) - (item.edebf))-((item.ecred) - (item.ecredf))).toFixed(2) }}€</span>
                                    </template>
                                </v-data-table>
                                </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!--Tarefas-->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-list>
                    <v-list-item class="row">
                      <v-list-item-content class="col-12">
                        <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersTarefas" :items="tarefas">
                          <template v-slot:[`item.dataregisto`]="{ item }">
                            {{ formatDate(item.tarefa_data_registo) }}
                          </template>
                          <template v-slot:[`item.descricao`]="{ item }">
                            <span v-if="item.tarefa_descricao != null" v-html="item.tarefa_descricao.substring(0,60)"></span>
                          </template>
                          <template v-slot:[`item.urgencia`]="{ item }">
                            <v-chip :color="getColor(item.Tarefa_Grau_Urgencia)" dark>{{ item.Tarefa_Grau_Urgencia }}</v-chip>
                          </template>
                          <template v-slot:[`item.inicio`]="{ item }">
                            <span v-if="item.tarefa_data_inicio != null">{{ formatDate(item.tarefa_data_inicio) }}</span>
                          </template>
                          <template v-slot:[`item.fim`]="{ item }">
                            <span v-if="item.tarefa_data_fim != null">{{ formatDate(item.tarefa_data_fim) }}</span>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="red" class="mr-2" @click="temposTotalTarefa(item.tarefa_id)">mdi-timer</v-icon>
                            <v-icon color="green" class="mr-2" @click="navigateTo({name: 'editartarefas', params:{idEnviado: item.tarefa_id}})">mdi-pencil</v-icon>
                            <v-icon color="red" class="mr-2" @click="apagarTarefa(item.tarefa_id)">mdi-delete</v-icon>
                            <v-icon v-if="item.tarefa_concluida != 1" color="red">mdi-close</v-icon>
                            <v-icon v-else color="green">mdi-check</v-icon>
                          </template>
                        </v-data-table>
                        <!-- Dialog apagar tarefa -->
                        <v-dialog v-model="dialogApagarTarefa">
                          <v-card>
                            <v-toolbar class="mb-2 primary" dark flat>
                              <v-toolbar-title></v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-spacer></v-spacer>
                              <v-toolbar-title><v-list-item @click="dialogApagarTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                            </v-toolbar>
                            <v-col cols="12">
                              <span>Quer apagar a tarefa?</span>
                            </v-col>
                            <v-col cols="12">
                              <v-alert v-model="alertTarefaApagada" dismissible type="success">Tarefa apagada com sucesso</v-alert>
                            </v-col>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" @click="confirmarApagarTarefa">Confirmar</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <!-- Dialog total tarefa -->
                        <v-dialog v-model="dialogTemposAgoraTarefa">
                          <v-card>
                            <v-toolbar class="mb-2 primary" dark flat>
                              <v-toolbar-title>Tempos total tarefa</v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-spacer></v-spacer>
                              <v-toolbar-title><v-list-item @click="dialogTemposAgoraTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                            </v-toolbar>
                            <v-container>
                              <v-list>
                                  <v-list-item class="row">
                                    <v-col cols="12">
                                      <v-toolbar-title class="text-right">Total tempo: <b><span>{{ totaltemposagoratarefa }}</span></b></v-toolbar-title>
                                    </v-col>
                                    <v-list-item-content class="col-12">
                                      <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersTemposAgora" :items="temposAgoraTarefa">
                                        <template v-slot:[`item.funcionario`]="{ item }">
                                          {{ item.Tempos_Agora_Funcionario }}
                                        </template>
                                        <template v-slot:[`item.descricao`]="{ item }">
                                          <span v-if="item.Tempos_Agora_Descricao != null" v-html="item.Tempos_Agora_Descricao.substring(0,60)"></span>
                                        </template>
                                        <template v-slot:[`item.trabalho`]="{ item }">
                                          <span v-if="item.Tempos_Agora_Trabalho_Realizado != null" v-html="item.Tempos_Agora_Trabalho_Realizado.substring(0,60)"></span>
                                        </template>
                                        <template v-slot:[`item.inicio`]="{ item }">
                                          {{ formatDate3(item.Tempos_Agora_Inicio) }}
                                        </template>
                                        <template v-slot:[`item.fim`]="{ item }">
                                          <span v-if="item.Tempos_Agora_fim != null">{{ formatDate3(item.Tempos_Agora_fim) }}</span>
                                        </template>
                                        <template v-slot:[`item.tempo`]="{ item }">
                                          <span v-if="item.Tempos_Agora_Tempo_Efetivo != null">{{ formatDate4(item.Tempos_Agora_Tempo_Efetivo) }}</span>
                                        </template>
                                        <template v-slot:[`item.pat`]="{ item }">
                                          {{ item.Tempos_Agora_PAT }}
                                        </template>
                                        <template v-slot:[`item.actions`]="{ item }">
                                          <v-icon class="m-2" v-if="item.Tempos_Agora_Terminado != 1" color="red">mdi-close</v-icon>
                                          <v-icon class="m-2" v-else color="green">mdi-check</v-icon>
                                          <v-icon class="m-2" color="blue" @click="temposAgoraView(item.Tempos_Agora_ID)">mdi-eye</v-icon>
                                        </template>
                                      </v-data-table>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                            </v-container>
                          </v-card>
                        </v-dialog>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!--Marcações-->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-list>
                    <v-list-item class="row">
                      <v-list-item-content class="col-12">
                        <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersMarcacoes" :items="marcacoes">
                          <template v-slot:[`item.dataregisto`]="{ item }">
                            {{ formatDate(item.Marcacoes_data_registo) }}
                          </template>
                          <template v-slot:[`item.marcacao`]="{ item }">
                            <span v-if="item.Marcacoes_descricao != null" v-html="item.Marcacoes_descricao.substring(0,60)"></span>
                          </template>
                          <template v-slot:[`item.inicio`]="{ item }">
                            <span v-if="item.Marcacoes_data_inicio != null">{{ formatDate(item.Marcacoes_data_inicio) }}</span>
                          </template>
                          <template v-slot:[`item.fim`]="{ item }">
                            <span v-if="item.Marcacoes_data_fim != null">{{ formatDate(item.Marcacoes_data_fim) }}</span>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="green" class="mr-2" @click="navigateTo({name: 'editarmarcacoes', params:{idEnviado2: item.Marcacoes_id}})">mdi-pencil</v-icon>
                            <v-icon color="red" class="mr-2" @click="apagarMarcacao(item.Marcacoes_id)">mdi-delete</v-icon>
                            <v-icon v-if="item.Marcacoes_concluida != 1" color="red">mdi-close</v-icon>
                            <v-icon v-else color="green">mdi-check</v-icon>
                          </template>
                        </v-data-table>
                        <!-- Dialog apagar marcacao -->
                        <v-dialog v-model="dialogApagarMarcacao">
                          <v-card>
                            <v-toolbar class="mb-2 primary" dark flat>
                              <v-toolbar-title></v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-spacer></v-spacer>
                              <v-toolbar-title><v-list-item @click="dialogApagarMarcacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                            </v-toolbar>
                            <v-col cols="12">
                                <span>Quer apagar a marcação?</span>
                            </v-col>
                            <v-col cols="12">
                              <v-alert v-model="alertMarcacaoApagada" dismissible type="success">Marcação apagada com sucesso</v-alert>
                            </v-col>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" @click="confirmarApagarMarcacao">Confirmar</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!--Projetos-->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-list>
                    <v-list-item class="row">
                      <v-list-item-content class="col-12">
                        <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersProjetos" :items="projetos">
                          <template v-slot:[`item.dataregisto`]="{ item }">
                            {{ formatDate(item.Projetos_data_registo) }}
                          </template>
                          <template v-slot:[`item.projeto`]="{ item }">
                            <span>{{ item.Projetos_ano }}/{{ item.Projetos_numero }}</span>
                          </template>
                          <template v-slot:[`item.descricao`]="{ item }">
                            <span v-html="item.Projetos_descricao.substring(0,60)"></span>
                          </template>
                          <template v-slot:[`item.inicio`]="{ item }">
                            {{ formatDate(item.Projetos_data_inicio) }}
                          </template>
                          <template v-slot:[`item.fim`]="{ item }">
                            {{ formatDate(item.Projetos_data_fim) }}
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="blue" class="mr-2" @click="navigateTo({name: 'resumosprojetos', params:{idEnviado3: item.Projetos_id}})">mdi-file-find</v-icon>
                            <v-icon color="green" class="mr-2" @click="navigateTo({name: 'editarprojetos', params:{idEnviado3: item.Projetos_id}})">mdi-pencil</v-icon>
                            <v-icon color="red" class="mr-2" @click="apagarProjeto(item.Projetos_id)">mdi-delete</v-icon>
                            <v-icon v-if="item.Projetos_concluida != 1" color="red">mdi-close</v-icon>
                            <v-icon v-else color="green">mdi-check</v-icon>
                          </template>
                        </v-data-table>
                        <!-- Dialog apagar projeto -->
                        <v-dialog v-model="dialogApagarProjeto">
                          <v-card>
                            <v-toolbar class="mb-2 primary" dark flat>
                              <v-toolbar-title></v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-spacer></v-spacer>
                              <v-toolbar-title><v-list-item @click="dialogApagarProjeto = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                            </v-toolbar>
                            <v-col cols="12">
                                <span>Quer apagar o projeto?</span>
                            </v-col>
                            <v-col cols="12">
                              <v-alert v-model="alertProjetoApagada" dismissible type="success">Projeto apagado com sucesso</v-alert>
                            </v-col>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" @click="confirmarApagarProjeto">Confirmar</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!--Notas
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-list>
                    <v-list-item class="row">
                      <v-list-item-content class="col-12">
                        Notas
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>-->
            <!--Anexos
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-list>
                    <v-list-item class="row">
                      <v-list-item-content class="col-12">
                        Anexos
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>-->
            <!--Dossier
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-list>
                    <v-list-item class="row">
                      <v-list-item-content class="col-12">
                        Dossier
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>-->
            <!--Cobranças
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-list>
                    <v-list-item class="row">
                      <v-list-item-content class="col-12">
                        Cobranças
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>-->
            <!--Passes
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-list>
                    <v-list-item class="row">
                      <v-list-item-content class="col-12">
                        Passes
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>-->
            <!--Orçamentos
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-list>
                    <v-list-item class="row">
                      <v-list-item-content class="col-12">
                        Orçamentos
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>-->
            <!--Tempos-->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-list>
                    <v-list-item class="row">
                      <v-col cols="12">
                        <v-toolbar-title class="text-right">Total tempo: <b><span>{{ valortotaldetempo }}</span></b></v-toolbar-title>
                      </v-col>
                      <v-list-item-content class="col-12">
                        <v-data-table hide-default-footer class="elevation-1" :items-per-page="200" :headers="headersTemposAgora" :items="temposagora">
                          <template v-slot:[`item.funcionario`]="{ item }">
                            {{ item.Tempos_Agora_Funcionario }}
                          </template>
                          <template v-slot:[`item.descricao`]="{ item }">
                            <span v-if="item.Tempos_Agora_Descricao != null" v-html="item.Tempos_Agora_Descricao.substring(0,60)"></span>
                          </template>
                          <template v-slot:[`item.trabalho`]="{ item }">
                            <span v-if="item.Tempos_Agora_Trabalho_Realizado != null" v-html="item.Tempos_Agora_Trabalho_Realizado.substring(0,100)"></span>
                          </template>
                          <template v-slot:[`item.inicio`]="{ item }">
                            {{ formatDate3(item.Tempos_Agora_Inicio) }}
                          </template>
                          <template v-slot:[`item.fim`]="{ item }">
                            <span v-if="item.Tempos_Agora_fim != null">{{ formatDate3(item.Tempos_Agora_fim) }}</span>
                          </template>
                          <template v-slot:[`item.tempo`]="{ item }">
                            <span v-if="item.Tempos_Agora_Tempo_Efetivo != null">{{ formatDate4(item.Tempos_Agora_Tempo_Efetivo) }}</span>
                          </template>
                          <template v-slot:[`item.pat`]="{ item }">
                            {{ item.Tempos_Agora_PAT }}
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon v-if="item.Tempos_Agora_Terminado != 1" color="red">mdi-close</v-icon>
                            <v-icon v-else color="green">mdi-check</v-icon>
                            <v-icon class="m-2" color="blue" @click="temposAgoraView(item.Tempos_Agora_ID)">mdi-eye</v-icon>
                          </template>
                        </v-data-table>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>
        <!-- Dialog Ver os tempos agora -->
        <v-dialog v-model="dialogViewTemposAgora">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Visualizar tempo</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogViewTemposAgora = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Início</label>
                    <v-text-field v-if="temposagoraview.Tempos_Agora_Inicio != null" :value="formatDate3(temposagoraview.Tempos_Agora_Inicio)" disabled filled outlined></v-text-field>
                    <v-text-field v-else disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Fim</label>
                    <v-text-field v-if="temposagoraview.Tempos_Agora_fim != null" :value="formatDate3(temposagoraview.Tempos_Agora_fim)" disabled filled outlined></v-text-field>
                    <v-text-field v-else disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Tempo total</label>
                    <v-text-field v-if="temposagoraview.Tempos_Agora_Tempo_Efetivo != null" :value="formatDate4(temposagoraview.Tempos_Agora_Tempo_Efetivo)" disabled filled outlined></v-text-field>
                    <v-text-field v-else disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Deslocação/Remoto/Telefone</label><br>
                    <v-icon v-if="temposagoraview.Tempos_Agora_Deslocacao == 1" color="green" class="pr-2">mdi-car</v-icon>
                    <v-icon v-if="temposagoraview.Tempos_Agora_Remoto == 1" color="green" class="pr-2">mdi-laptop</v-icon>
                    <v-icon v-if="temposagoraview.Tempos_Agora_Telefone == 1" color="green" class="pr-2">mdi-phone</v-icon>
                  </v-col>
                  <v-col cols="12">
                    <label>Funcionario</label>
                    <v-text-field v-model="temposagoraview.Tempos_Agora_Funcionario" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Descrição</label>
                    <wysiwyg v-model="temposagoraview.Tempos_Agora_Descricao"/>
                  </v-col>
                  <v-col cols="12">
                    <label>Trabalho Realizado</label>
                    <wysiwyg v-model="temposagoraview.Tempos_Agora_Trabalho_Realizado"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </v-flex>
  </v-layout>
</template>
  
<script>
//  Importar o service necessário
import ClientesService from '@/services/ClientesService'
import ContasCorrenteService from '@/services/ContasCorrenteService'
import TarefasService from '@/services/TarefasService'
import MarcacoesService from '@/services/MarcacoesService'
import ProjetosService from '@/services/ProjetosService'
import TemposAgoraService from '@/services/TemposAgoraService'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.clientes = (await ClientesService.index()).data
  },
  data () {
    return {
      clientes: [],
      clienteSelect: [],
      contascorrente: [],
      tarefas: [],
      marcacoes: [],
      projetos: [],
      temposAgoraTarefa: [],
      temposagora: [],
      estado: [],
      temposagoraview: '',
      dialogApagarTarefa: false,
      dialogApagarMarcacao: false,
      dialogApagarProjeto: false,
      dialogTemposAgoraTarefa: false,
      dialogViewTemposAgora: false,
      idtarefaglobal: '',
      idmarcacaoglobal: '',
      idprojetoglobal: '',
      alertTarefaApagada: false,
      alertMarcacaoApagada: false,
      alertProjetoApagada: false,
      valortotaldetempo: '',
      totaltemposagoratarefa: '',
      headersTarefas: [
        { text: "Data Registo", value: "dataregisto", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },   
        { text: "Urgência", value: "urgencia", sortable: false },
        { text: "Inicio", value: "inicio", sortable: false },   
        { text: "Fim", value: "fim", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersMarcacoes: [
        { text: "Cliente", value: "dataregisto", sortable: false },
        { text: "Marcação", value: "marcacao", sortable: false },
        { text: "Inicio", value: "inicio", sortable: false },   
        { text: "Fim", value: "fim", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      headersProjetos: [
        { text: "Data Registo", value: "dataregisto", sortable: false },
        { text: "Projeto", value: "projeto", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Inicio", value: "inicio", sortable: false },   
        { text: "Fim", value: "fim", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersContasCorrente: [
        { text: "Documento", value: "documento", sortable: false },
        { text: "Data doc.", value: "datadoc", sortable: false },
        { text: "Débito Valor doc.", value: "debitovalordoc", sortable: false },
        { text: "Débito Valor liq.", value: "debitovalorliq", sortable: false },
        { text: "Crédito Valor doc.", value: "creditovalordoc", sortable: false },
        { text: "Crédito Valor doc.", value: "creditovalorliq", sortable: false },
        { text: 'Total', value: 'total', sortable: false },
      ],
      headersTemposAgora: [
        { text: "Funcionario", value: "funcionario", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Trabalho Realizado", value: "trabalho", sortable: false },
        { text: "Inicio", value: "inicio", sortable: false },
        { text: "Fim", value: "fim", sortable: false },
        { text: "Tempo", value: "tempo", sortable: false },
        { text: "P.A.T", value: "pat", sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  methods: {
    // ir buscar os clients por este id
    async getClientes (idcliente) {
      this.clienteSelect = (await ClientesService.show(idcliente)).data
      this.contascorrente = (await ContasCorrenteService.show(idcliente)).data
      this.tarefas = (await TarefasService.tarefascliente(idcliente)).data
      this.marcacoes = (await MarcacoesService.marcacoescliente(idcliente)).data
      this.projetos = (await ProjetosService.projetoscliente(idcliente)).data
      this.temposagora = (await TemposAgoraService.temposagoracliente(idcliente)).data
      var totalSegundos2 = 0; // Inicializa o total de segundos

      for (var ii = 0; ii < this.temposagora.length; ii++) {
        var tempoEfetivo2 = this.formatDate4(this.temposagora[ii].Tempos_Agora_Tempo_Efetivo);
        var partesTempo2 = tempoEfetivo2.split(':'); // Divide o tempo em partes (horas, minutos, segundos)
        var horas2 = parseInt(partesTempo2[0], 10);
        var minutos2 = parseInt(partesTempo2[1], 10);
        var segundos2 = parseInt(partesTempo2[2], 10);

        // Converte tudo para segundos e soma
        totalSegundos2 += horas2 * 3600 + minutos2 * 60 + segundos2;
      }

      // Converte o total de segundos de volta para o formato de tempo desejado
      var totalFormatado2 = this.formatarTempo(totalSegundos2);

      // caixa de tempo total
      this.valortotaldetempo = totalFormatado2
    },
    // formatar data
    formatDate (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    // formatar data3
    formatDate3 (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    // formatar data4
    formatDate4 (date) {
      return new Date(date).toJSON().slice(11, 19)
    },
    //cores da urgência
    getColor (urgencia) {
        if (urgencia === 'Média') return 'yellow'
        else if (urgencia === 'Baixa') return 'green'
        else if (urgencia === 'Alta') return 'red'
        else return 'white'
    },
    navigateTo (route) {
      this.$store.dispatch('setTarefa', {  
        idtarefa: route.params.idEnviado,
      })
      this.$store.dispatch('setMarcacao', {  
        idmarcacao: route.params.idEnviado2,
      })
      this.$store.dispatch('setProjeto', {  
        idprojeto: route.params.idEnviado3
      })
      this.$router.push(route)
    },
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    //apagar tarefa
    async apagarTarefa (id) {
      this.dialogApagarTarefa = true
      this.idtarefaglobal = id
    },
    //confirmar apagar tarefa
    async confirmarApagarTarefa () {
      this.alertTarefaApagada = true
      document.location.reload(true)
      await TarefasService.delete(this.idtarefaglobal)
    },
    //-------------------------------------------marcações------------------------------//
    //-------------------------------------------marcações------------------------------//
    //-------------------------------------------marcações------------------------------//
    //apagar marcação
    async apagarMarcacao (id) {
      this.dialogApagarMarcacao = true
      this.idmarcacaoglobal = id
    },
    //confirmar apagar marcação
    async confirmarApagarMarcacao () {
      this.alertMarcacaoApagada = true
      document.location.reload(true)
      await MarcacoesService.delete(this.idmarcacaoglobal)
    },
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    //apagar projeto
    async apagarProjeto (id) {
      this.dialogApagarProjeto = true
      this.idprojetoglobal = id
    },
    //confirmar apagar projeto
    async confirmarApagarProjeto () {
      this.alertProjetoApagada = true
      document.location.reload(true)
      await ProjetosService.delete(this.idprojetoglobal)
    },
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    
    async temposTotalTarefa (tarefaid) {
      this.dialogTemposAgoraTarefa = true
      this.temposAgoraTarefa = (await TemposAgoraService.temposagoratarefas(tarefaid)).data
      var totalSegundos = 0; // Inicializa o total de segundos

      for (var i = 0; i < this.temposAgoraTarefa.length; i++) {
        var tempoAgoraEfetivo = this.formatDate4(this.temposAgoraTarefa[i].Tempos_Agora_Tempo_Efetivo);
        var partesTempo = tempoAgoraEfetivo.split(':'); // Divide o tempo em partes (horas, minutos, segundos)
        var horas = parseInt(partesTempo[0], 10);
        var minutos = parseInt(partesTempo[1], 10);
        var segundos = parseInt(partesTempo[2], 10);

        // Converte tudo para segundos e soma
        totalSegundos += horas * 3600 + minutos * 60 + segundos;
      }

      // Converte o total de segundos de volta para o formato de tempo desejado
      var totalFormatado = this.formatarTempo(totalSegundos);

      this.totaltemposagoratarefa = totalFormatado;
    },

    // Função para formatar o tempo no formato HH:mm:ss
    formatarTempo(totalSegundos) {
      var horas = Math.floor(totalSegundos / 3600);
      var minutos = Math.floor((totalSegundos % 3600) / 60);
      var segundos = totalSegundos % 60;

      return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + segundos.toString().padStart(2, '0');
    },

    async temposAgoraView (temposagoraid) {
      this.dialogViewTemposAgora = true
      this.temposagoraview = (await TemposAgoraService.show(temposagoraid)).data
    },
  }
}
</script>
<style>
.v-text-field{
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}
#totalcontascorrente2 {
  color: black;
}
.corvermelho2 {
  color: red;
}
#tabs-clientes .v-tab, #tabs-clientes .v-tab i, #tabs-clientes .v-slide-group__wrapper i, #tabs-clientes .v-slide-group__next i{
  color: white;
}

</style>