import Api from '@/services/Api'

export default {
  // ir buscar todos os funcionarios
  index () {
    return Api().get('funcionarios')
  },
  // funcionario com este id
  show (id) {
    return Api().get(`funcionario/${id}`)
  },
  // update funcionario com este id
  put (id) {
    return Api().put(`funcionarioU/${id.funcionario_id}`, id)
  },
  // ir buscar os funcionarios ocupados
  funcionariosocupados () {
    return Api().get('funcionariosocupados')
  },
  // ir buscar os funcionarios livres
  funcionarioslivres () {
    return Api().get('funcionarioslivres')
  },
}
