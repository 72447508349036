import Api from '@/services/Api'

export default {
  // todas as tarefas
  index () {
    return Api().get('tarefas')
  },
  // tarefas por concluir crecente
  tarefasporconcluirasc () {
    return Api().get('tarefasporconcluirasc')
  },
  // tarefas por concluir descendente
  tarefasporconcluirdesc () {
    return Api().get('tarefasporconcluirdesc')
  },
  // ultima tarefa da bd
  ultimatarefa () {
    return Api().get('ultimatarefa')
  },
  // tarefas deste utilizador
  usertarefas (idutilizador) {
    return Api().get('usertarefas',{ 
      params: {
        idutilizador
      }
    })
  },
  // tarefas asc deste utilizador
  usertarefasasc (idutilizador) {
    return Api().get('usertarefasasc',{ 
      params: {
        idutilizador
      }
    })
  },
  // tarefas desc deste utilizador
  usertarefasdesc (idutilizador) {
    return Api().get('usertarefasdesc',{ 
      params: {
        idutilizador
      }
    })
  },
  // tarefas por cliente
  tarefascliente (idcliente) {
    return Api().get('tarefascliente',{ 
      params: {
        idcliente
      }
    })
  },
  // tarefas por projeto
  tarefasprojeto (idprojeto) {
    return Api().get('tarefasprojeto',{ 
      params: {
        idprojeto
      }
    })
  },
  // update tarefa
  put (id) {
    return Api().put(`tarefa/${id.tarefa_id}`, id)
  },
  // criar tarefa
  post (elementos) {
    return Api().post('tarefa', elementos)
  },
  // tarefa com este id
  show (id) {
    return Api().get(`tarefa/${id}`)
  },
  // apagar tarefa
  delete (id) {
    return Api().delete(`tarefa/${id}`, id)
  }
}
