import Api from '@/services/Api'

export default {
  // tempos tarefa 
  tempostarefa (idtarefa) {
    return Api().get('tempostarefa',{ 
      params: {
        idtarefa
      }
    })
  },
  // todos os tempos
  index () {
    return Api().get('tempos')
  },
  // tempo com este id
  show (id) {
    return Api().get(`tempo/${id}`)
  },
  // criar tempos
  post (id) {
    return Api().post('tempos', id)
  },
  // update tempos
  put (id) {
    return Api().put(`tempo/${id.Tempos_ID}`, id)
  },
  // ultimo tempo
  ultimotempo () {
    return Api().get('ultimotempo')
  }
}
